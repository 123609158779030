<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">

        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">OTTHONBIZTOSÍTÁSOK</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <p>
                Élete munkájával megvásárolt, felépített lakás kellő biztonságban van? Minden váratlan
                eseményre biztosítva van ingatlana és ingóságai? Értéktárgyai megfelelő összegre
                vannak biztosítva?
              </p>
              <div>
                <a [routerLink]="['/hazmester']" class="btn btn-lg rounded-pill btn-secondary">Tovább a Házmester Otthonbiztosításhoz&nbsp;&gt;&gt;&nbsp;</a>
                <p>&nbsp;</p>
                <a [routerLink]="['/mfo']" class="btn btn-lg rounded-pill btn-secondary">Tovább a MEGÓV-LAK Minősített Fogyasztóbarát Otthonbiztosításhoz&nbsp;&gt;&gt;&nbsp;</a>
                <p>&nbsp;</p>
                <a [routerLink]="['/panorama']">Tovább a Panoráma Családi Otthonbiztosításhoz&nbsp;&gt;&gt;&nbsp;</a>
              </div>
              <div class="clr"></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
