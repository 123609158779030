import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vezetoseg',
  templateUrl: './vezetoseg.component.html',
  styleUrls: ['./vezetoseg.component.scss']
})
export class VezetosegComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
