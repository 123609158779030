<div class="bg-primary utas-grid-wrapper" style="">
  <section class="text-white mb-0 pt-5 utas-grid-header">
  </section>
  <section class="mb-4 pb-3  text-white utas-grid-body" style="">
    <div class="container pt-5 pb-5">
      <div class="row pt-4">
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 py-4 px-lg-5">
              <h1 class="bold h1 px-lg-4">UTASBIZTOSÍTÁS</h1>
            </div>
            <div class="col-12 py-4 px-lg-5">
              <p>
                A jó utasbiztosítás személyes biztonságának és értékei védelmének alapja. Néha egy
                kicsivel többre van szükség, hiszen a vészhelyzetek mindig váratlanul törnek ránk,
                időpontjuk és helyük előre nem látható. Ne bízza a szerencsére,
              </p>
              <div class="col-12 pb-3">
                <a [href]="utasKalkLink" target="_blank" class="btn btn-lg rounded-pill btn-secondary">Kössön Gránit utasbiztosítást!</a>
              </div>
              <div class="clr"></div>

              <p>
                A Gránit Biztosító Zrt. online megköthető utazási biztosításával rendelkezésére állunk
                és a világ bármely pontján biztonságban érezheti magát és családját. A Gránit Assistance
                munkatársak segítséget nyújtanak a bajban, az év minden napján, 24 órában.
              </p>
              <p>
                A Gránit márkanév garantálja a megbízható minőséget az Ön számára! Ránk biztosan
                számíthat!
              </p>
              <p>
                Biztosítsa magát nyári utazása idején is! Rendkívül kedvező áron, akár napi 230
                Ft-tól megkötheti utasbiztosítását!
              </p>
              <p class="h4 bold">
                24 órás magyar nyelvű segítségnyújtó szolgálatunk a következő számon hívható:<br />+36 1 465 3668
              </p>

            </div>
            <!--
            <div class="col-12 py-4 px-lg-5">
              <div class="row">
                <div class="col-lg-4 col-sd-12 pb-3">
                  <a href="http://konzuliszolgalat.kormany.hu/utazasra-nem-javasolt-tersegek" target="_blank" class="btn rounded-pill btn-secondary">Külügyminisztérium: nem javasolt országok</a>
                </div>
                <div class="col-lg-4 col-sd-12 pb-3">
                  <a [routerLink]="['/utas-dokumentumok']" target="_blank" class="btn rounded-pill btn-secondary">Jogszabályok, letölthető dokumentumok</a>
                </div>
              </div>
            </div>
              -->

            <div class="col-md-12 h2 bold py-5 px-lg-5 text-start">
              Jogszabályok, letölthető dokumentumok
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<section class="bg-info pb-5">
  <div class="container pb-5">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="accordion shadow-lg rounded-4" id="accordionGranit">
          <div class="accordion-item bg-light">
            <h2 class="accordion-header ">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                Utasbiztosítási feltételek
              </button>
            </h2>
            <div id="collapse1" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small">
                  <ul class="ulstyle">
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utasbiztositasi_feltetelek_217.pdf">Hagyományos utazás</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek.pdf">Síelés, snowbordozás</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item bg-light">
            <h2 class="accordion-header ">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                Vonatkozó jogszabályok
              </button>
            </h2>
            <div id="collapse2" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small">
                  <ul class="ulstyle">
                    <li>2014. évi LXXXVIII. törvény a biztosítási tevékenységről</li>
                    <li>2013. évi V. törvény a Polgári Törvénykönyvről</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                Letölthető dokumentumok
              </button>
            </h2>
            <div id="collapse3" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small">
                  <ul>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_utas_216_hogolyo.pdf">Biztosítási termékismertető - Utazási és sí biztosítás Hógolyó csomag</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_utas_216_lavina.pdf">Biztosítási termékismertető - Utazási és sí biztosítás Lavina csomag</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_utas_212_bazis.pdf">Biztosítási termékismertető - Utazási biztosítás Bázis csomag</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_utas_212_premium.pdf">Biztosítási termékismertető - Utazási biztosítás Prémium csomag</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_utas_215_aranyutlevel.pdf">Biztosítási termékismertető - Utazási biztosítás Arany útlevél csomag a CLB alkuszon keresztül szerződést kötő ügyfeleink részére</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_utas_215_ezustutlevel.pdf">Biztosítási termékismertető - Utazási biztosítás Ezüst útlevél csomag a CLB alkuszon keresztül szerződést kötő ügyfeleink részére</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_utas_215_aranyutlevel.pdf">Biztosítási termékismertető - Utazási biztosítás Arany útlevél csomag a Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_utas_215_ezustutlevel.pdf">Biztosítási termékismertető - Utazási biztosítás Ezüst útlevél csomag a Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_utas_217_bazis.pdf">Biztosítási termékismertető - Utazási biztosítás Bázis csomag a Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_utas_217_premium.pdf">Biztosítási termékismertető - Utazási biztosítás Prémium csomag a Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére</a></li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20240215.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2024.02.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20240215.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2024.02.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20240215.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2024.02.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20240215.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2024.02.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20240215.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2024.02.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a><br /></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kedvezmenyezett_jeloles_20240215.docx">Kedvezményezett jelölés</a><br /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                Kárbejelentési dokumentumok
              </button>
            </h2>
            <div id="collapse4" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small">
                  <ul>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}betegseg_es_balesetbiztositasi_karbejelento_20120921.pdf">Betegség és balesetbiztosítási kárbejelentő lap</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}poggyasz_karbejelento_lap_20120921.pdf">Poggyász kárbejelentő lap</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                Archívum
              </button>
            </h2>
            <div id="collapse5" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small">
                  <ul>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20230615.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2023.06.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20230615.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2023.06.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20230615.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2023.06.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20230615.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2023.06.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20230615.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2023.06.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a><br /></li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20211220.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2021.12.20-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20211220.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2021.12.20-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20211220.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2021.12.20-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20211220.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2021.12.20-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20211220.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2021.12.20-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a><br /></li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20210501.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2021.05.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20210501.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2021.05.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20210501.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2021.05.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20210501.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2021.05.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20210501.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2021.05.01-től hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a><br />
                    </li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20200701.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2020.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20200701.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2020.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20200701.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2020.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20200701.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2020.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20200701.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2020.07.01-től hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a><br />
                    </li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20190426.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2019.04.26-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20190426.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2019.04.26-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20190426.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2019.04.26-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20190426.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2019.04.26-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20190426.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2019.04.26-tól hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a><br />
                    </li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20190315.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2019.03.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20190315.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2019.03.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20190315.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2019.03.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20190315.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2019.03.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20190315.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2019.03.15-től hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20181105.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.11.05-től hatályos)</a><br />
                    </li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20190101.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2019.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20190101.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2019.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20190101.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2019.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20190101.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2019.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20190101.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2019.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20180808.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2018.08.08-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20180808.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2018.08.08-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20180808.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2018.08.08-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20180808.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2018.08.08-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20180808.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2018.08.08-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20180701.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2018.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20180701.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2018.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20180701.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2018.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20180701.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2018.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20180701.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2018.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20180525.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2018.05.25-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20180525.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2018.05.25-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20180525.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2018.05.25-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20180525.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2018.05.25-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20180525.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2018.05.25-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20180223.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2018.02.23-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20180223.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2018.02.23-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20180223.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2018.02.23-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20180223.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2018.02.23-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utk217_20180223.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2018.02.23-tól hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20180101.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2018.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20180101.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2018.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20180101.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2018.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20180101.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2018.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utk217_20180101.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2018.01.01-től hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-tól hatályos)</a><br />
                    </li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20170901.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2017.09.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20170901.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2017.09.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20170901.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2017.09.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20170901.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2017.09.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utk217_20170901.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2017.09.01-től hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-tól hatályos)</a><br />
                    </li>

                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20170601.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2017.06.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20170601.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2017.06.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20170601.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2017.06.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20170601.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2017.06.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utk217_20170601.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2017.06.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20170401.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2017.04.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20170401.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2017.04.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20170401.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2017.04.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20170401.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2017.04.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utk217_20170401.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2017.04.01-től hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-tól hatályos)</a><br />
                    </li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20170101.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2017.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20170101.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2017.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20170101.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2017.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20170101.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2017.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utk217_20170101.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2017.01.01-től hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-tól hatályos)</a><br />
                    </li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20160901.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2016.09.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20160901.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2016.09.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20160901.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2016.09.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20160901.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2016.09.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utk217_20160901.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2016.09.01-től hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-tól hatályos)</a><br />
                    </li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20160701.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2016.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20160701.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2016.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20160701.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2016.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20160701.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2016.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utk217_20160701.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2016.07.01-től hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-tól hatályos)</a><br />
                    </li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20160101.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2016.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20160101.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2016.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20160101.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2016.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20160101.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2016.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utk217_20160101.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2016.01.01-től hatályos</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a><br />
                    </li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utk216_20150101.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2015.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utk212_20150101.pdf">Utasbiztosítási feltételcsomag (UTKF212) 2015.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}clb_utasfeltetel_utk215_20150101.pdf">Utasbiztosítási feltételcsomag CLB alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2015.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utk215_20150101.pdf">Utasbiztosítási feltételcsomag Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2015.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utk217_20150101.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2015.01.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20150101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2015.01.01-től hatályos)</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}bizthu_utasfeltetel_utkf217_20140701.pdf">Utasbiztosítási feltételeink Biztosítás.hu alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF217) 2014.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utfk212_20140315.pdf">Utasbiztosítási feltételeink (UTKF212) 2014.03.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utfk215_20140315.pdf">Utasbiztosítási feltételeink Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2014.03.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utkf216_20140315.pdf">Utazási és sí biztosítási feltételeink (UTKF216) 2014.03.15-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20131001.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2013.10.01-től hatályos)</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utfk212_20120921.pdf">Utasbiztosítási feltételeink (UTKF212) 2012.09.21-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utfk215_20130701.pdf">Utasbiztosítási feltételeink Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF215) 2013.07.01-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek_utkf215_20120921.pdf">Utazási és sí biztosítási feltételeink (UTKF215) 2012.09.21-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utfk214_20120921.pdf">Utasbiztosítási feltételeink Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére (UTKF214) 2012.09.21-től hatályos</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130701.pdf">Ügyféltájékoztató 2013.07.01-től</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130301.pdf">Ügyféltájékoztató 2013.03.01-től</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_adatkez_taj_20120120.pdf">Ügyfél- és Adatkezelési tájékoztató 2012. január 20-ától</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}alkuszi_utasfeltetel_utfk212.pdf">Utasbiztosítási feltételeink CLB alkuszon keresztül szerződést kötő ügyfeleink részére</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}netrisk_utasfeltetel_utfk214.pdf">Utasbiztosítási feltételeink Netrisk alkuszon keresztül szerződést kötő ügyfeleink részére</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}dm_lemondo_nyilatkozat.pdf">Marketing célú nyilatkozat lemondása</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_adatkez_taj_20120120.pdf">Ügyfél- és Adatkezelési tájékoztató 2012. január 20-ától</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utas_biztositasi_torles_kerelem.pdf">Utas Biztosítási törlés kérelem</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utazasi_es_si_biztositas_feltetelek.pdf">Utazási és sí biztosítási feltételeink</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20120101.pdf">Ügyféltájékoztató 2012. január 1-jétől</a></li>
                    <li>
                      <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20110701.pdf">Ügyféltájékoztató 2011. július 1-jétől</a><br />
                    </li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}orangeways_utasfeltetel_utfk213.pdf">Utasbiztosítási feltételeink az Orangeways honlapján szerződést kötő ügyfeleink részére</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-info pb-5">
  <div class="container pb-5">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <a href="http://konzuliszolgalat.kormany.hu/utazasra-nem-javasolt-tersegek" target="_blank" class="bg-info text-primary bold">A Külügyminisztérium által nem javasolt utazási célok</a>
      </div>
    </div>
  </div>
</section>
