<form [formGroup]="javitokForm">
  <section class="bg-white intro">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 px-0 termekek">
          <section class="bg-white text-primary mt-5 mb-4 py-4">
            <div class="container pt-5">
              <div class="row pt-4">
                <div class="col-12 col-lg-10">
                  <h1 class="bold">SZERZŐDŐTT JAVÍTÓ PARTNEREK</h1>
                </div>
              </div>
            </div>
          </section>
          <section class="bg-white mb-4 py-4 article-section">

            <ng-container *ngIf="toltoKepernyo">
              <div class="row">
                <div class="col-lg-12 bg-white">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <div class="container">
                <div class="row justify-content-center">

                  <div class="col-lg-12" *ngIf="javitokDataSource.data && javitokDataSource.data.length > 0">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="col-md-2">
                          <label for="field2" class="form-label strong">Név</label>
                        </div>
                        <div class="col-md-10">
                          <select formControlName="nev" class="form-select" (change)="szures()">
                            <option value="0">Kérem válasszon!</option>
                            <option *ngFor="let nev of nevLista" [value]="nev">{{nev}}</option>
                          </select>
                        </div>
                        <div class="col-md-1">

                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="col-md-2">
                          <label for="field2" class="form-label strong">Irszám</label>
                        </div>
                        <div class="col-md-10">
                          <select formControlName="irsz" class="form-select" (change)="szures()">
                            <option value="0">Kérem válasszon!</option>
                            <option *ngFor="let irszam of irszamLista" [value]="irszam">{{irszam}}</option>
                          </select>
                        </div>
                        <div class="col-md-1">

                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="col-md-2">
                          <label for="field2" class="form-label strong">Település</label>
                        </div>
                        <div class="col-md-10">
                          <select formControlName="telepules" class="form-select" (change)="szures()">
                            <option value="0">Kérem válasszon!</option>
                            <option *ngFor="let telepules of telepulesLista" [value]="telepules">{{telepules}}</option>
                          </select>
                        </div>
                        <div class="col-md-1">

                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="col-md-2">
                          <label for="field2" class="form-label strong">Cím</label>
                        </div>
                        <div class="col-md-10">
                          <select formControlName="cim" class="form-select" (change)="szures()">
                            <option value="0">Kérem válasszon!</option>
                            <option *ngFor="let cim of cimLista" [value]="cim">{{cim}}</option>
                          </select>
                        </div>
                        <div class="col-md-1">

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">

                    <table mat-table [dataSource]="javitokDataSource" class="table table-striped table-borderless" [matSortActive]="'f_nev'" [matSortDirection]="matSortDirection" matSort>
                      <ng-container *ngFor="let column of javitokOszlopok" [matColumnDef]="column.columnDef">
                        <mat-header-cell *matHeaderCellDef [class]="column.class" mat-sort-header>{{ column.header }}</mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" [class]="column.class">
                          <span [innerHTML]="element.get([column.columnDef]).value"></span>
                        </mat-cell>
                      </ng-container>
                      <!-- Fejléc -->
                      <mat-header-row *matHeaderRowDef="getDisplayedJavitokOszlopok()"></mat-header-row>
                      <!-- Sorok -->
                      <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedJavitokOszlopok();" (click)="javitoValasztas(row)" [class.selected]="valasztottJavito && valasztottJavito.length > 0 && valasztottJavito.indexOf(row) != -1"></mat-row>
                    </table>
                    <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100, 150]" showFirstLastButtons></mat-paginator>
                  </div>
                  <div class="col-lg-12 pt-5" *ngIf="valasztottJavito && valasztottJavito.length > 0">
                    <div class="row">
                      <div class="col-lg-1">
                        <span>Cégnév:</span>
                      </div>
                      <div class="col-lg-3">
                        <span>{{valasztottJavito[0].controls["f_nev"].value}}</span>
                      </div>
                      <div class="col-lg-4 onallo" *ngIf="valasztottJavito[0].controls['f_onallo'].value == 'I'">
                        <span>önálló kárfelvételi jogosultság</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-1">
                        <span>Cím:</span>
                      </div>
                      <div class="col-lg-4">
                        <span>{{valasztottJavitoCim}}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-1">
                        <span>Telefon:</span>
                      </div>
                      <div class="col-lg-4">
                        <span>{{valasztottJavito[0].controls["f_telefon"].value}}</span>
                      </div>
                    </div>

                    <div class="row" *ngIf="valasztottJavito[0].controls['f_telefax'].value">
                      <div class="col-lg-1">
                        <span>Fax:</span>
                      </div>
                      <div class="col-lg-4">
                        <span>{{valasztottJavito[0].controls["f_telefax"].value}}</span>
                      </div>
                    </div>

                    <div class="row" *ngIf="valasztottJavito[0].controls['f_web'].value">
                      <div class="col-lg-1">
                        <span>Web:</span>
                      </div>
                      <div class="col-lg-4">
                        <a href="{{valasztottJavito[0].controls['f_web'].value}}" target="_blank">{{valasztottJavito[0].controls["f_web"].value}}</a>
                      </div>
                    </div>

                  </div>
                  <div class="col-lg-12 pt-5">
                    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="15">
                      <agm-marker *ngFor="let data of javitokDataSource.data" [latitude]="data.controls['f_latitude'].value" [longitude]="data.controls['f_longitude'].value"></agm-marker>
                    </agm-map>
                  </div>

                </div>
              </div>
            </ng-container>
          </section>
        </div>
      </div>
    </div>
  </section>
</form>
