import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TextTransformPipe, UtilityService } from '@granit/common-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { WeboldalService } from '../../../services/weboldal.service';

@Component({
  selector: 'szerzodott-javito-partnerek',
  templateUrl: './szerzodott-javito-partnerek.component.html',
  styleUrls: ['./szerzodott-javito-partnerek.component.scss']
})
export class SzerzodottJavitoPartnerekComponent implements OnInit {

  javitokOszlopok: any[] = [
    { columnDef: 'f_nev', header: 'Név', class: 'col-4' },
    { columnDef: 'f_irszam', header: 'Irszám', class: 'col-1' },
    { columnDef: 'f_telepules', header: 'Település', class: 'col-3' },
    { columnDef: 'f_cim', header: 'Cím', class: 'col-4' },
    { columnDef: 'f_telefon', header: '', visible: false },
    { columnDef: 'f_telefax', header: '', visible: false },
    { columnDef: 'f_uvegkar', header: '', visible: false },
    { columnDef: 'f_onallo', header: '', visible: false },
    { columnDef: 'f_latitude', header: '', visible: false },
    { columnDef: 'f_longitude', header: '', visible: false },
    { columnDef: 'f_web', header: '', visible: false },
    { columnDef: 'f_sorszam', header: '', visible: false }
  ];
  javitokDataSource: MatTableDataSource<any>;
  javitokTeljesDataSource: MatTableDataSource<any>;
  getDisplayedJavitokOszlopok(): string[] {
    let displayedColumns = this.javitokOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  matPaginator: MatPaginator;
  @ViewChild('paginator', { static: false }) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.matPaginator = paginator;
      this.javitokDataSource.paginator = this.matPaginator;
    }
  }
  matSort: MatSort;
  @ViewChild(MatSort, { static: false }) set sort(sort: MatSort) {
    if (sort) {
      this.matSort = sort;
      this.javitokDataSource.sort = this.matSort;
    }
  }
  matSortDirection = 'asc'

  javitokForm: FormGroup;
  toltoKepernyo: boolean = false;
  valasztottJavito: any = [];
  lat: any = 0;
  lng: any = 0;
  valasztottJavitoCim: string = "";
  nevLista: any[] = [];
  irszamLista: any[] = [];
  telepulesLista: any[] = [];
  cimLista: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private weboldalService: WeboldalService,
    private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit(): void {
    this.javitokDataSource = new MatTableDataSource();
    this.javitokDataSource.sortingDataAccessor = (item: FormGroup, property) => {

      let control = item.get(property);
      let textTransformPipe = new TextTransformPipe();
      return textTransformPipe.transform(control.value, 'YYYY.MM.DD.');
    };
    this.javitokTeljesDataSource = new MatTableDataSource();
    this.javitokFormInit();
    this.javitokListaja();
  }

  javitoValasztas(row) {
    this.valasztottJavito = [];
    this.valasztottJavito.push(row);
    this.lat = this.valasztottJavito[0].controls["f_latitude"].value
    this.lng = this.valasztottJavito[0].controls["f_longitude"].value
    this.valasztottJavitoCim = this.valasztottJavito[0].controls["f_irszam"].value + " " + this.valasztottJavito[0].controls["f_telepules"].value + ", " + this.valasztottJavito[0].controls["f_cim"].value;
  }

  javitokFormInit() {
    this.javitokForm = this.formBuilder.group({
      nev: new FormControl("0"),
      irsz: new FormControl("0"),
      telepules: new FormControl("0"),
      cim: new FormControl("0")
    });
  }

  javitokListaja() {
    this.toltoKepernyo = true;

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('weblap_javitok').toPromise().then(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            adatok: this.javitokForm.value
          }

          this.weboldalService.javitokListaja(model).subscribe(
            data => {
              if (data && data.length > 0) {
                const fgs = data.map(item => {
                  return this.utilityService.formGroupObjektumbol(item);
                });
                this.javitokDataSource.data = this.formBuilder.array(fgs).controls;
                this.javitokTeljesDataSource.data = this.formBuilder.array(fgs).controls;
                this.javitoValasztas(this.javitokDataSource.data[0]);
                this.listaElemek();
              }

              this.toltoKepernyo = false;
            },
            errorResult => {
              this.toltoKepernyo = false;
            });
        });
      });
  }

  listaElemek() {
    this.irszamLista = [];
    this.telepulesLista = [];
    this.cimLista = [];
    this.nevLista = [];

    this.javitokDataSource.data.forEach(adat => {

      if (!this.irszamLista.find(x => x == adat.controls["f_irszam"].value)) {
        this.irszamLista.push(adat.controls["f_irszam"].value)
      }

      if (!this.telepulesLista.find(x => x == adat.controls["f_telepules"].value)) {
        this.telepulesLista.push(adat.controls["f_telepules"].value)
      }

      if (!this.cimLista.find(x => x == adat.controls["f_cim"].value)) {
        this.cimLista.push(adat.controls["f_cim"].value)
      }

      if (!this.nevLista.find(x => x == adat.controls["f_nev"].value)) {
        this.nevLista.push(adat.controls["f_nev"].value)
      }

    });
    this.irszamLista = this.irszamLista.sort((a, b) => (a < b ? -1 : 1));
    this.telepulesLista = this.telepulesLista.sort((a, b) => (a < b ? -1 : 1));
    this.cimLista = this.cimLista.sort((a, b) => (a < b ? -1 : 1));
    this.nevLista = this.nevLista.sort((a, b) => (a < b ? -1 : 1));
  }

  szures() {

    this.javitokDataSource.data = this.javitokTeljesDataSource.data;

    if (this.javitokForm.controls["nev"].value != "0") {
      this.javitokDataSource.data = this.javitokDataSource.data.filter(x => x.controls["f_nev"].value == this.javitokForm.controls["nev"].value);
    }

    if (this.javitokForm.controls["irsz"].value != "0") {
      this.javitokDataSource.data = this.javitokDataSource.data.filter(x => x.controls["f_irszam"].value == this.javitokForm.controls["irsz"].value);
    }

    if (this.javitokForm.controls["telepules"].value != "0") {
      this.javitokDataSource.data = this.javitokDataSource.data.filter(x => x.controls["f_telepules"].value == this.javitokForm.controls["telepules"].value);
    }

    if (this.javitokForm.controls["cim"].value != "0") {
      this.javitokDataSource.data = this.javitokDataSource.data.filter(x => x.controls["cim"].value == this.javitokForm.controls["f_cim"].value);
    }

    this.javitoValasztas(this.javitokDataSource.data[0]);
    this.listaElemek();

  }

}
