<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row justify-content-center pt-4">
              <div class="col-lg-12">
                <h1 class="bold">JOGI NYILATKOZAT</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <p>
                  A honlap struktúrája, képi-grafikai megjelenítése, és tartalma a Gránit Biztosító szellemi alkotása és jogi védelem alatt áll.
                  A honlapon közzétett szöveges és képi információk felhasználása kizárólag a forrás megjelölésével megengedett.
                  Az üzleti célú felhasználás minden formája tilos. A Gránit Biztosító ellenőrzi a honlapon elhelyezett információkat, és minden ésszerű intézkedést megtesz az információk folyamatos aktualizálásáért, és azok hitelességéért.
                  Tájékoztatjuk a felhasználókat, hogy az interneten továbbított adatok sérülékenyek az olyan hálózati fenyegetésekkel szemben, amelyek információk módosítására, illetőleg más tisztességtelen tevékenységre vezetnek.
                  Társaságunk tőle elvárható módon fellép az ilyen fenyegetésekkel szemben, ezért a rendszereket megfigyeli annak érdekében, hogy minden biztonsági eltérést rögzíthessen.
                </p>
                <p>
                  Társaságunk honlapja más internetes lapokra mutató linkeket is tartalmazhat.
                  Ezen internetes oldalak tartalmáért a Gránit Biztosító nem vállal felelősséget.
                  A Gránit Biztosító fenntartja a jogot, hogy a honlap tartalmát bármikor megváltoztassa.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
