import { Component, OnInit, HostListener, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UtilityService } from '@granit/common-library';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  isScrolled = false;
  isActive = false; toggleActive() { this.isActive = !this.isActive; }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const threshold = 50;
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isScrolled = currentScrollPosition > threshold;
  }


  onlineKarbejelentoLink: string = "";
  onlineFizetesLink: string = "";
  dokumentumIgenylesLink: string = "";
  ugyfelPortalLink: string = "";
  idopontUsziLink: string = "";
  url: string = "";
  routerUrl: string = "";

  @ViewChild('menuContent') menuContent: ElementRef;
  @ViewChild('menuToggle') menuToggle: ElementRef;
  @ViewChild('myButton') myButton: ElementRef;

  constructor(private utilityService: UtilityService,
    private renderer: Renderer2,
    private el: ElementRef,
    private router: Router) {
  }


  ngOnInit(): void {
    this.linkek();
  }

  onClick() {     // Your click handling logic here
  }
  emulateClick() { this.myButton.nativeElement.click(); }

  toggleNavbar() {
    this.myButton.nativeElement.click();
    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  async linkek() {

    let model = {
      key: "OnlineKarbejelentoLink"
    }
    this.onlineKarbejelentoLink = await this.utilityService.appsettingsKeyErtekLekerese(model);

    model = {
      key: "OnlineFizetesLink"
    }
    this.onlineFizetesLink = await this.utilityService.appsettingsKeyErtekLekerese(model);

    model = {
      key: "UgyfelPortalLink"
    }
    this.ugyfelPortalLink = await this.utilityService.appsettingsKeyErtekLekerese(model);

    model = {
      key: "IdopontUsziLink"
    }
    this.idopontUsziLink = await this.utilityService.appsettingsKeyErtekLekerese(model);

    model = {
      key: "DokumentumIgenylesLink"
    }
    this.dokumentumIgenylesLink = await this.utilityService.appsettingsKeyErtekLekerese(model);
  }



}
