import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'otthon',
  templateUrl: './otthon.component.html',
  styleUrls: ['./otthon.component.scss']
})
export class OtthonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
