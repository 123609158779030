import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'karrendezo-partnerek',
  templateUrl: './karrendezo-partnerek.component.html',
  styleUrls: ['./karrendezo-partnerek.component.scss']
})
export class KarrendezoPartnerekComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
