<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">

        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">HÁZMESTER OTTHONBIZTOSÍTÁS</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <p>Élete munkájával megvásárolt, felépített lakása kellő biztonságban van? Minden váratlan eseményre biztosította ingatlanát és ingóságait? A kiegészítő lehetőségekre és értéktárgyainak megfelelő biztosítására is gondolt?</p>
              <div>
                <a [href]="hazmesterKalkLink" target="_blank" class="btn btn-lg rounded-pill btn-secondary">Kössön Házmester Otthonbiztosítást!</a>
              </div>
              <p>&nbsp;</p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_szolgaltatasi_tablazat.pdf">Biztosítási csomagunk szolgáltatásait és a térítési összeghatárokat itt tekintheti meg!</a></li>
              </ul>
              <p>Társaságunk széleskörű fedezetet nyújt tűz, vihar, árvíz, jégverés, villám, felhőszakadás, csőtörés, üvegtörés és betöréses lopás károkra 20 alapfedezettel és további 19 választható kiegészítő fedezettel.</p>
              <p>Nem kell olyan csomagot választania, aminek elemeire nincs is szüksége, itt személyre szabhatja biztosítását!</p>
              <p>Kiegészítő biztosításokat kínálunk pl. Balesetbiztosításra, Home Office vagyontárgyaira és autókulcs elvesztéséből, ellopásából eredő károkra egyaránt. De akár az otthon végzett vállalkozása vagyontárgyait is biztosíthatja!</p>
              <p>Gránit Gyorsszolgálat 24 szolgáltatásunk az év minden napján, szakiparost ajánl a váratlan helyzetekre megoldására.</p>
              <p>24 órás segítségnyújtó szolgálatunk a következő számon hívható: +36 1 465 3634</p>
              <p>Kárrendezési szolgáltatásunk során független szakértői irodákkal dolgozunk, garantálva ezzel is ügyfeleink pártatlan, minőségi kiszolgálását!</p>
              <p>A biztosítás kötési folyamata teljesen digitalizált, papírmentes. Ön az adatok kitöltése után véglegesítheti szerződését weboldalunkon, amit azonnal visszaigazolunk email címére és kötvényét is kérheti akár email-fiókjába is.</p>
              <p>A Gránit márkanév garantálja a megbízható minőséget az Ön számára! Ránk biztosan számíthat!</p>

              <div class="clr"></div>
              <h2 class="subtitle">
                <b>Vonatkozó jogszabályok</b>
              </h2>

              <ul class="ulstyle">
                <li>2014. évi LXXXVIII. törvény a biztosítási tevékenységről</li>
                <li>2013. évi V. törvény a Polgári Törvénykönyvről</li>
              </ul>

              <h2 class="subtitle"><strong>A biztosítási módozathoz tartozó számlaszám</strong></h2>
              <table style="width: 100%;">
                <tr>
                  <td><span>10918001-00000068-64440075</span></td>
                  <td>IBAN: <span>HU46 1091 8001 0000 0068 6444 0075</span></td>
                </tr>
              </table>

              <h2 class="subtitle"><b>Letölthető dokumentumok</b></h2>
              <ul>

                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_biztositasi_termekismerteto_ipid.pdf">Házmester Otthonbiztosítás Biztosítási termékismertető</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_ugyfeltajekoztato_akf_kulonos_feltetelek.pdf">Házmester Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2024.02.15-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a></li>
              </ul>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_adatkezelesi_tajekoztato_es_karbejelento_nyomtatvany.pdf">Kárbejelentő nyomtatvány Házmester otthonbiztosítási káreseményhez és adatkezelési tájékoztató Házmester otthonbiztosítás alapján bejelentett káreseménnyel összefüggő adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_balesetbiztositasi_esemeny_bejelento_nyomtatvany.pdf">Kárbejelentő nyomtatvány Házmester otthonbiztosítás kiegészítő balesetbiztosítási káreseményhez és adatkezelési tájékoztató Házmester otthonbiztosítás alapján bejelentett kiegészítő balesetbiztosítási eseménnyel összefüggő adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_adatkezelesi_nyilatkozat_visszavonasa.pdf">Házmester otthonbiztosítás otthonbiztosítási káresemény bejelentőjének nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_egeszsegugyi_adatok_kezelese_nyilatkozat.pdf">Nyilatkozat Házmester otthonbiztosítás kiegészítő balesetbiztosítási eseménnyel összefüggő egészségügyi adat kezeléséhez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}karrendezeshez_szukseges_dokumentumok_listaja.pdf">Kárrendezéshez szükséges dokumentumok listája</a></li>
              </ul>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}mabisz_tuzvedelmi_ajanlat.pdf">A Magyar Elektrotechnikai Egyesület, a Magyar Biztosítók Szövetsége és az Országos Katasztrófavédelmi Főigazgatóság ajánlása a villamos eredetű tűzkárok megelőzéséhez és csökkentéséhez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}betoresvedelmi_tajekoztato.pdf">MABISZ Betörésvédelmi tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}mabisz_lakasbiztositasi_utmutato_20140315.pdf">MABISZ Lakásbiztosítási útmutató (Hatályos: 2014.03.15-től, az új Ptk. alapján  megkötött szerződésekre)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}mabisz_lakasbiztositasi_utmutato.pdf">MABISZ Lakásbiztosítási útmutató (Hatályos: 2014.03.14-ig megkötött lakásbiztosítási szerződésekre)</a></li>
              </ul>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}csop_besz_megbizas_20120921.pdf">Felhatalmazás csoportos beszedési megbízás teljesítésére</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}egyedi_zalogkotelezetti_nyilatkozat.pdf" target="_blank">Egyedi Zálogkötelezetti nyilatkozat</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}egyedi_zalogkotelezetti_nyilatkozat_ceges.pdf" target="_blank">Egyedi Zálogkötelezetti nyilatkozat - céges</a></li>
              </ul>
              <h2 class="subtitle"><b>Kárbejelentési dokumentumok</b></h2>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_otthon_es_kiegeszito_biztositas_karbejelentesi_nyomtatvany.pdf">Kárbejelentő nyomtatvány Házmester otthonbiztosítási káreseményhez</a></li>

                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakvelemeny.pdf">Szakvélemény</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}felelosseg_elismero_nyilatkozat.pdf">Felelősség elismerő nyilatkozat</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ingosagi_karbejelento.pdf">Ingósági kárbejelentő</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_hatosagi_eljarasrol.pdf">Nyilatkozat hatósági eljárásról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
              </ul>
              <h2 class="subtitle"><b>Archívum</b></h2>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_ugyfeltajekoztato_akf_kulonos_feltetelek_20231115.pdf">Házmester Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2023.11.15-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
