<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">GÉPJÁRMŰ FELELŐSSÉGI KÁROK KÁRRENDEZÉSE</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <div>
                <a class="btn btn-lg rounded-pill btn-secondary" href="{{onlineKarbejelentoLink}}" target="_blank">Online kárbejelentés</a>
              </div>
              <div class="clr">
              </div>
              <p>
                &nbsp;
              </p>
              <p>
                <strong>Tisztelt Ügyfelünk!</strong>
              </p>
              <p>
                Oldalunkon szeretnénk segítséget és tájékoztatást nyújtani a gyorsabb kárrendezés
                érdekében.<br />
                Kárbejelentésre az alábbi lehetőségek állnak rendelkezésre:
              </p>
              <table width="60%">
                <tr>
                  <td>
                    <strong>E-mail:</strong>
                  </td>
                  <td>
                    <img class="lev" src="../../../assets/images/email/kar.png" alt="Kárügyintézés" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Telefon:</strong>
                  </td>
                  <td>
                    (+36-1) 6666-200
                  </td>
                </tr>               
              </table>
              <ul>
                <li><a class="scroll" (click)="scrollToelement('A1')">Dokumentumok letöltése</a></li>                
              </ul>
              <table width="60%">
                <tr>
                  <td colspan="2">
                    <strong>Személyes kárügyintézés, nyitva tartás:</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Hétfő:</strong>
                  </td>
                  <td>
                    7:00-19:00
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Kedd:</strong>
                  </td>
                  <td>
                    8:00-16:00
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Szerda:</strong>
                  </td>
                  <td>
                    8:00-16:00
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Csütörtök:</strong>
                  </td>
                  <td>
                    8:00-16:00
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Péntek:</strong>
                  </td>
                  <td>
                    8:00-15:00
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    &nbsp;
                  </td>
                </tr>
              </table>
              <p>
                A kárbejelentéshez a következő iratok szükségesek: teljes körűen kitöltött és mindkét
                fél által aláírt Európai Baleseti Bejelentőlap, ha ez nem áll rendelkezésre, akkor
                az okozó által adott és aláírt elismerése, vagy rendőrségi igazolás/feljelentés
                és a balesetben részes gépjárművek adatai.
              </p>
              <p>
                Amennyiben a kárbejelentést valamilyen oknál fogva nem tudjuk befogadni, úgy erről
                a bejelentést követő 2 munkanapon belül értesítést kap.
              </p>
              <p>
                Kérjük, hogy pontosan adja meg saját (vagy kapcsolattartójának) elérhetőségét, és
                a gépjármű szemléjének helyét.
              </p>
              <p>
                A kárbejelentés feldolgozását követően, szakértőnk 24 órán belül időpontot egyeztet
                a szemle időpontját illetően és a szemlét 3 munkanapon belül elvégzi. A kárszemléhez
                a kárbejelentő adatlap kitöltésére van szükség, melyet letölthet honlapunkról vagy
                a kárszakértő adja át kitöltésre a szemle elvégzésekor. Szükséges a károsodott gépjármű
                tulajdonjogának igazolása (törzskönyv, vagy hitel terhelt, lízingelt gépjármű esetén
                finanszírozói nyilatkozat); a forgalmi engedélye ( vagy adás-vételi szerződés),
                valamint a jármű vezetőjének vezetői engedélyé másolata.
              </p>
              <p>
                Ha a gépjármű a káresetkor parkolt, a vezetői engedély bemutatása nem szükség. A
                szemlézendő jármű és/vagy egyéb vagyontárgyak kárképének megőrzése a szemle megtörténtéig
                indokolt. A szemléről jegyzőkönyv-, és az átvett, valamint a várhatóan szükséges
                iratokról lista készül, amelynek másolatát a szakértő a helyszínen átadja.
              </p>
              <p>
                Pótszemle szükségessége esetén kérjük az eljáró szakértőt vagy a kárügyben eljáró
                megbízott szakértő irodát értesíteni. A pótszemle időkorlátai megegyeznek a kárfelvételnél
                leírtakkal. A pótszemléről a szakértő jegyzőkönyvet készít, amelynek másolatát szintén
                átadja a helyszínen.
              </p>
              <p>
                <strong>Kárkifizetés:</strong><br />
                Az összes szükséges dokumentáció beérkezése után, amennyiben a Biztosító a fedezetet
                és a jogalapot rendben találta a kárkifizetésről 15 napon belül intézkedik. A kárkifizetést
                a Biztosító, a jármű tulajdonosának vagy az általa szabályosan meghatalmazott természetes
                vagy jogi személynek teljesíti.<br />
                Felhívjuk szíves figyelmét, hogy banki utalás esetén,
                ha Revolut bankszámlára kéri a kártérítés kifizetését <b>
                  a 8 számjegyű egyedi azonosítót
                  ne felejtse el megadni!
                </b> A 8 számjegyű egyedi azonosító megadásának elmulasztásából eredő többlet költség Önt terheli.
              </p>
              <p>
                <strong>A kárrendezés történhet:</strong>
              </p>
              <blockquote>
                <p>
                  <strong>Számla alapján:</strong> amennyiben a javítás gazdaságos (erről az eljáró
                  szakértő tud felvilágosítást adni) a kárfelvételi jegyzőkönyvben (és pótszemle jegyzőkönyvben)
                  foglaltakra figyelemmel készült számla tételeit térítjük meg.
                </p>
                <p>
                  <strong>Számla nélküli kárrendezéssel:</strong> amennyiben számlát nem nyújt be,
                  lehetősége van a Biztosító által kiszámolt javítási költség elfogadására. Ehhez
                  írásos nyilatkozatot kell benyújtania, melyet a kárszakértőtől is kérhet.
                </p>
              </blockquote>
              <h2 class="subtitle"><strong>Regressz, megtérülési számlaszámunk:</strong></h2>
              <table style="width: 100%;">
                <tr>
                  <td><span>10918001-00000068-64440329 </span></td>
                  <td>IBAN: <span>HU75 1091 8001 0000 0068 6444 0329</span></td>
                </tr>
              </table>
              <p>&nbsp;</p>
              <div id="A1"></div>
              <h2 class="subtitle"><strong>Letölthető dokumentumok</strong></h2>
              <p>&nbsp;</p>
              <p class="question"><strong>Kötelező gépjármű-felelősségbiztosítás</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_adatkez_tajek_karesemeny.pdf">Adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítás biztosítottjának gépjármű káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_biztositott_nyilatkozat_adatkez_tajek_karesemeny.pdf">Biztosított nyilatkozata a kötelező gépjármű-felelősségbiztosítási gépjármű káreseményhez és adatkezelési tájékozató kötelező gépjármű-felelősségbiztosítás biztosítottjának gépjármű káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_biztositott_nyilatkoz_visszavonas.pdf">Kötelező gépjármű-felelősségbiztosítási szerződés biztosítottjának nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_adatkez_tajek_bergepjarmu_igenybevet_karterit_igeny_eseten.pdf">Adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítási káreseményben megsérült gépjármű esetén a bérgépjármű igénybevételével kapcsolatos kártérítési igény felmérésére vonatkozó adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_adatlap_bergepjarmu_igenybevet_karterit_igeny.pdf">Adatlap bérgépjármű igénybevételével kapcsolatos kártérítési igény felmérésére </a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_tajek_bergepjarmu_koltsegigeny_terit_felt.pdf">Tájékoztató bérgépjármű költségigény térítésének feltételeiről</a><br />
                </li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}ertekcsokkenes_alkalmazasanak_modszertana.pdf">Értékcsökkenés alkalmazásának módszertana</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kgfb_karbejelentohoz.pdf">Adatkezelés tájékoztató a kötelező gépjármű-felelősségbiztosítási gépjármű káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}karbejelento_nyomtatvany_kgfb.pdf">Kárbejelentő nyomtatvány kötelező gépjármű-felelősségbiztosítási gépjármű káreseményhez és adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítási gépjármű káresemény bejelentésével kapcsolatos adatkezeléshez</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_szemelyi_serult_karbejelentesi_nyomtatvany_es_adatkez_tajek.pdf">Személyi sérültek kárbejelentési nyomtatványa a gépjárművek kötelező felelősségbiztosítása terhére történő kárrendezéshez és adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítási személyi sérüléses káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_adatkez_tajek_szemelyi_serult_karesemeny.pdf">Adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítási személyi sérüléses káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_egeszsegugyi_adatok_kezelesehez.pdf">Nyilatkozat egészségügyi adat kezeléséhez</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_szemelyes_es_egeszsegugyi_adatok_kezelesenek_visszavonasarol.pdf">Nyilatkozat személyes és egészségügyi adatok kezeléséhez adott hozzájárulás visszavonásáról</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kerdoiv_kgfb_uvegkarhoz_20120921.pdf">Kérdőív KGFB üvegkárhoz</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_szamla_nelkuli_karrendezeshez_20160101.pdf">Nyilatkozat számla nélküli kárrendezéshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_szemelyes_adatok_kezelesehez_adott_hozzajarulas_visszavonasarol_201810.pdf">Nyilatkozat személyes adatok kezeléséhez adott hozzájárulás visszavonásáról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
              </ul>

              <p class="question"><strong>CASCO biztosítás</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_adatkez_tajek_gj_es_kieg_bizt_adatkez.pdf">Adatkezelési tájékoztató casco biztosítási gépjármű- és kiegészítő biztosítási káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_adatkez_tajek_kieg_bizt_adatkez.pdf">Adatkezelési tájékoztató casco kiegészítő balesetbiztosítási esemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_kieg_bizt_esemeny_bejelento_nyomt_adatkez_tajek.pdf">Balesetbiztosítási esemény bejelentő nyomtatvány casco kiegészítő balesetbiztosítási szerződéshez és adatkezelési tájékoztató casco kiegészítő balesetbiztosítási esemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_gj_es_kieg_bizt_bejelento_nyilatkozat_visszavonas.pdf">Casco biztosítási gépjármű- és kiegészítő biztosítási káresemény bejelentőjének nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_kieg_bizt_bejelento_nyilatkozat_visszavonas.pdf">Casco kiegészítő balesetbiztosítási esemény bejelentőjének nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_karbejelento_nyomt_gj_es_kieg_bizt_adatkez_tajek.pdf">Kárbejelentő nyomtatvány casco biztosítási gépjármű- és kiegészítő biztosítási káreseményhez és adatkezelési tájékoztató casco biztosítási gépjármű- és kiegészítő biztosítási káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
              </ul>

              <p class="question"><strong>Utazási és sí biztosítások</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}betegseg_es_balesetbiztositasi_karbejelento_20120921.pdf">Betegség és balesetbiztosítási kárbejelentő lap</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}poggyasz_karbejelento_lap_20120921.pdf">Poggyász kárbejelentő lap</a></li>
              </ul>

              <p class="question"><strong>Házmester Otthonbiztosítás</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_otthon_es_kiegeszito_biztositas_karbejelentesi_nyomtatvany.pdf">Kárbejelentő nyomtatvány Házmester otthonbiztosítási káreseményhez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakvelemeny.pdf">Szakvélemény</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}felelosseg_elismero_nyilatkozat.pdf">Felelősség elismerő nyilatkozat</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ingosagi_karbejelento.pdf">Ingósági kárbejelentő</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_hatosagi_eljarasrol.pdf">Nyilatkozat hatósági eljárásról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
              </ul>

              <p class="question"><strong>Megóvlak Minősített Fogyasztóbarát Otthonbiztosítás</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_karbejelentolap.pdf">Kárbejelentő nyomtatvány MEGÓV-LAK minősített fogyasztóbarát otthonbiztosítási káreseményhez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakvelemeny.pdf">Szakvélemény</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}felelosseg_elismero_nyilatkozat.pdf">Felelősség elismerő nyilatkozat</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ingosagi_karbejelento.pdf">Ingósági kárbejelentő</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_hatosagi_eljarasrol.pdf">Nyilatkozat hatósági eljárásról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
              </ul>

              <p class="question"><strong>Panoráma Otthonbiztosítás</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}panorama_karbejelentolap.pdf">Kárbejelentő nyomtatvány Panoráma Családi Otthonbiztosítási káreseményhez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakvelemeny.pdf">Szakvélemény</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}felelosseg_elismero_nyilatkozat.pdf">Felelősség elismerő nyilatkozat</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ingosagi_karbejelento.pdf">Ingósági kárbejelentő</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_hatosagi_eljarasrol.pdf">Nyilatkozat hatósági eljárásról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
              </ul>


              <p class="question"><strong>CMR biztosítás</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_karbejelento_20120921.pdf">CMR kárbejelentő lap</a></li>
              </ul>

              <p class="question"><strong>Szállítmánybiztosítás</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_karbejelento.pdf">Szállítmánybiztosítás kárbejelentő lap</a></li>
              </ul>

              <p class="question"><strong>Vagyonbiztosítások - Vállalkozások vagyon- és felelősségbiztosítása</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vallalkozoi_karbejelentolap.pdf">Kárbejelentő nyomtatvány Vállalkozói vagyonbiztosítás káreseményhez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakvelemeny.pdf">Szakvélemény</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}felelosseg_elismero_nyilatkozat.pdf">Felelősség elismerő nyilatkozat</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ingosagi_karbejelento.pdf">Ingósági kárbejelentő</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_hatosagi_eljarasrol.pdf">Nyilatkozat hatósági eljárásról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
              </ul>
              <p class="question"><strong>Vagyonbiztosítások - Trafik-biztosítás</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}trafik_karbejelentolap.pdf">Kárbejelentő nyomtatvány Nemzeti Dohánybolt vagyonbiztosítási káreseményhez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakvelemeny.pdf">Szakvélemény</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}felelosseg_elismero_nyilatkozat.pdf">Felelősség elismerő nyilatkozat</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ingosagi_karbejelento.pdf">Ingósági kárbejelentő</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_hatosagi_eljarasrol.pdf">Nyilatkozat hatósági eljárásról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
              </ul>
              <!--<p class="question"><strong>Kisállat biztosítás</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kisallat_biztositasi_szolgaltatasi_igeny_bejelentesevel_kapcsolatos_adatkezeleshez_es_szolgaltatasi_igenybejelento_nyomtatvany.pdf">Adatkezelési tájékoztató kisállat biztosítási szolgáltatási igény bejelentésével kapcsolatos adatkezeléshez és szolgáltatási igénybejelentő nyomtatvány</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kisallattartoi_felelossegbiztositasi_karesemeny_bejelentesevel_kapcsolatos_adatkezeleshez_es_karbejelento_nyomtatvany.pdf">Adatkezelési tájékoztató kisállattartói felelősségbiztosítási káresemény bejelentésével kapcsolatos adatkezeléshez és kárbejelentő nyomtatvány</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kisallat_biztositasi_szolgaltatasi_igeny_bejelentesevel_kapcsolatos_adatkezeleshez.pdf">Adatkezelési tájékoztató kisállat biztosítási szolgáltatási igény bejelentésével kapcsolatos</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kisallattartoi_felelossegbiztositasi_karesemeny_bejelentesevel_kapcsolatos_adatkezeleshez.pdf">Adatkezelési tájékoztató kisállattartói felelősségbiztosítási káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_egeszsegugyi_adatok_kezelesehez_kisallattartoi_felelossegbiztositasi_karesemenyhez.pdf">Nyilatkozat egészségügyi adatok kezeléséhez (kisállattartói felelősségbiztosítási káreseményhez)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_szemelyes_adatok_kezelesehez_adott_hozzajarulas_visszavonasarol.pdf">Nyilatkozat személyes adatok kezeléséhez adott hozzájárulás visszavonásáról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_szemelyes_es_egeszsegugyi_adatok_kezelesehez_adott_hozzajarulas_visszavonasarol.pdf">Nyilatkozat személyes és egészségügyi adatok kezeléséhez adott hozzájárulás visszavonásáról</a></li>
              </ul>-->

              <p class="question"><strong>KÁF/KSZF biztosítás</strong></p>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_kaf_karbejelento_20160101.pdf">BÁF/KÁF kárbejelentő lap</a></li>
              </ul>

              <h2 class="subtitle">
                <strong>Kapcsolódó anyagok:</strong>
              </h2>
              <ul>
                <li><a [routerLink]="['/szerzodott-javito-partnerek']">Szerződött javító partnerek</a></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
