<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">

        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">VAGYONBIZTOSÍTÁSOK</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <p>
                <b>Tisztelt Ügyfeleink!</b>
              </p>
              <p>
                Örömmel tájékoztatjuk, hogy Biztosítónk az Önök igényeit szem előtt tartva tovább
                szélesítette termékpalettáját, és bevezetésre került a vállalkozások számára kialakított
                vagyon- és felelősségbiztosítási, illetőleg a Nemzeti Dohányboltok biztosítására
                specializálódott Trafik-biztosítási terméke.
              </p>
              <ul>
                <li><a class="scroll" (click)="scrollToelement('A1')">Vállalkozások vagyon- és felelősségbiztosítása</a></li>
                <li><a class="scroll" (click)="scrollToelement('A2')">Trafik-biztosítás</a></li>
                <li><a class="scroll" (click)="scrollToelement('A3')">Petrisk kisállat biztosítás</a></li>
              </ul>

              <h2 class="subtitle"><b>Vonatkozó jogszabályok</b></h2>

              <ul class="ulstyle">
                <li>2014. évi LXXXVIII. törvény a biztosítási tevékenységről</li>
                <li>2013. évi V. törvény a Polgári Törvénykönyvről</li>
                <li>Általános Adatvédelmi Rendelet</li>
                <li>Az Információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény (Infotv.)</li>
              </ul>

              <h2 class="subtitle"><strong>A biztosítási módozathoz tartozó számlaszám</strong></h2>
              <table style="width: 100%;">
                <tr>
                  <td><span>10918001-00000068-64440075</span></td>
                  <td>IBAN: <span>HU46 1091 8001 0000 0068 6444 0075</span></td>
                </tr>
              </table>
              <div id="A1">
                <hr class="fancy-line" />
                <p>
                  &nbsp;
                </p>
                <h1>
                  Vállalkozások vagyon- és felelősségbiztosítása
                </h1>
                <p>
                  &nbsp;
                </p>
                <p>
                  Álmainak megvalósításával létrehozott, felépített vállalata kellő biztonságban van?
                  Minden váratlan eseményre biztosítva van a vállalkozás ingatlana és ingóságai?
                </p>
                <p>
                  Kössön Társaságunknál a Vállalkozások részére kialakított Vagyon- és Felelősségbiztosítást!
                </p>
                <p>
                  Az általunk ajánlott biztosítás széleskörű fedezetet nyújt tűz, vihar, árvíz, jégverés,
                  villám, felhőszakadás, csőtörés, üvegtörés és betöréses lopás károkra és még további
                  25 káreseményre.
                </p>
                <p>
                  Kiegészítő biztosításokat kínálunk munkahelyi baleset és irodai elektronikus berendezésekben
                  felmerült károkra.
                </p>
                <p>
                  A Gránit márkanév garantálja a megbízható minőséget az Ön számára! Ránk biztosan
                  számíthat!
                </p>
                <h2 class="subtitle">Letölthető dokumentumok</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_kkv_vagyon.pdf">Biztosítási termékismertető</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20240215.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2024.02.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a></li>

                  <li style="margin-top: 20px"><a class="download" target="_blank" href="{{weboldalDokumentumok}}vallalkozoi_vagyonbiztosítas_karesemeny_adatkezeles_es_karbejelento.pdf">Kárbejelentő nyomtatvány Vállalkozói vagyonbiztosítás káreseményhez és adatkezelési tájékoztató Vállalkozói vagyonbiztosítás káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vallalkozoi_nyilatkozat_szemelyes_adatok_kezelesenek_hozzajarulas_visszavonasa.pdf">Vállalkozói vagyonbiztosítás káresemény bejelentőjének nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a></li>
                </ul>
                <h2 class="subtitle">Kárbejelentési dokumentumok</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vallalkozoi_karbejelentolap.pdf">Kárbejelentő nyomtatvány Vállalkozói vagyonbiztosítás káreseményhez</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakvelemeny.pdf">Szakvélemény</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}felelosseg_elismero_nyilatkozat.pdf">Felelősség elismerő nyilatkozat</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ingosagi_karbejelento.pdf">Ingósági kárbejelentő</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_hatosagi_eljarasrol.pdf">Nyilatkozat hatósági eljárásról</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
                </ul>
                <h2 class="subtitle">Archívum</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20230615.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2023.06.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20211220.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20210501.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2021.05.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20200701.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2020.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20190426.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2019.04.26-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20190101.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2019.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20180808.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20180701.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20180525.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20180223.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20180101.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20170601.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2017.06.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20170401.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2017.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20170101.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2017.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20160901.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2016.09.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20160701.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2016.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kkv_feltetelcsomag_20160101.pdf">Vállalkozói vagyonbiztosítás kis- és középvállalkozások részére Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2016.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a></li>
                </ul>
              </div>
              <div id="A2">
                <hr class="fancy-line" />
                <p>
                  &nbsp;
                </p>
                <h1 class="subtitle">
                  Trafik-biztosítás
                </h1>
                <p>
                  &nbsp;
                </p>
                <p>
                  Sikeresen pályázott a nyilvánosan kiírt koncessziós pályázaton?<br />
                  Friss trafiktulajdonosnak mondhatja magát?<br />
                  Fontosnak tartja, hogy új vállalkozása biztonságban legyen?
                </p>
                <p>
                  Akkor kössön Gránit Trafik-biztosítást!
                </p>
                <p>
                  Az új igényekre reagálva Biztosítónk személyre szabott vagyon- és felelősségbiztosítási
                  biztosítást kínál a leendő Nemzeti Dohánybolt üzemeltetők részére. A Trafik-biztosítás
                  néven futó új termék fedezi a vállalkozás működése során esetlegesen felmerülő kockázatokból
                  eredő személyi és nem személyi jellegű károkat, emellett teljes körű védelmet nyújt
                  tűz- és elemi károk, üvegtörés, illetve betöréses lopás és rablás elszenvedése esetén.
                  Az alapbiztosítás nem csak épületre, de a készletekre és a berendezésekre is fedezetet
                  nyújt. Emellett egy mindenre kiterjedő felelősségbiztosítás választható a fedezetek
                  közé.
                </p>
                <p>
                  Az Ön igényeihez igazodik, hiszen Ön választhatja meg, hogy milyen kockázatokra
                  kívánja a biztosítást megkötni!
                </p>
                <p>
                  Emellett társaságunk jelentős kedvezményeket kínál majd az új szerződőknek; a több
                  koncessziós jogot elnyerők például extra engedményre jogosultak.
                </p>
                <p>
                  A Gránit márkanév garantálja a megbízható minőséget az Ön számára! Ránk biztosan
                  számíthat!
                </p>
                <h2 class="subtitle">Letölthető dokumentumok</h2>
                <p>
                  A társaság célja, hogy felelős és átlátható működésű biztosítóként, a kor információ-technológiai
                  eszközeinek maximális kihasználásával, kedvező árszínvonalú és az ügyféligényeken
                  alapuló termékeket és szolgáltatásokat kínáljon. Szem előtt tartva az Ön kényelmét
                  folyamatban van ezen termékek online kötési lehetőségének kialakítása is.
                </p>
                <p>
                  Amennyiben szeretne árajánlatot kérni vállalkozására vagy trafikjára, töltse le
                  a megfelelő adatközlőnket és kitöltve küldje el azt az
                  <img class="em_bitmap" src="../../../assets/images/email/uszi.png" alt="e-mail" />
                  e-mail címünkre. A levél tárgya legyen „Vállalkozói vagyonbiztosítás” vagy „Trafik-biztosítás”,
                  attól függően mire kér ajánlatot.
                </p>
                <h2 class="subtitle">Letölthető dokumentumok</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_trafik_vagyon.pdf">Biztosítási termékismertető</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20240625.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2024.02.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a></li>

                  <li style="margin-top: 20px"><a class="download" target="_blank" href="{{weboldalDokumentumok}}trafik_vagyonbiztositasi_karesemeny_adatkezeles_es_karbejelento.pdf">Kárbejelentő nyomtatvány Nemzeti Dohánybolt vagyonbiztosítási eseményhez és adatkezelési tájékoztató Nemzeti Dohánybolt vagyonbiztosítás káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}trafik_nyilatkozat_szemelyes_adatok_kezelesenek_hozzajarulas_visszavonasa.pdf">Nemzeti Dohánybolt vagyonbiztosítási káresemény bejelentőjének nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a></li>

                  <li style="margin-top: 20px"><a class="download" target="_blank" href="{{weboldalDokumentumok}}trafik_adatkozlo_toltheto.pdf">Adatkérő Trafik-biztosításhoz</a></li>
                </ul>
                <h2 class="subtitle">Kárbejelentési dokumentumok</h2>

                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}trafik_karbejelentolap.pdf">Kárbejelentő nyomtatvány Nemzeti Dohánybolt vagyonbiztosítási káreseményhez</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakvelemeny.pdf">Szakvélemény</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}felelosseg_elismero_nyilatkozat.pdf">Felelősség elismerő nyilatkozat</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ingosagi_karbejelento.pdf">Ingósági kárbejelentő</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_hatosagi_eljarasrol.pdf">Nyilatkozat hatósági eljárásról</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>                  
                </ul>
                <h2 class="subtitle">Archívum</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20230615.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2023.06.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20211220.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20210501.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2021.05.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20200701.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2020.07.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20190426.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2019.04.26-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20190101.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2019.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20180808.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20180701.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20180525.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20180223.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20180101.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20170601.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2017.06.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20170401.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2017.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20170101.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2017.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20160901.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2016.09.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20160701.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2016.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20160101.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag (2016.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20150101.pdf">Nemzeti Dohánybolt biztosítási feltételcsomag hatályos 2015.01.01-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20150101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2015.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20130920.pdf">Nemzeti Dohánybolt biztosítási feltételek hatályos 2013.09.20-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20131001.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2013.10.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130701.pdf">Ügyféltájékoztató 2013.07.01-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_nemzeti_dohanybolt_feltetel_20130601.pdf">Nemzeti Dohánybolt biztosítási feltételek hatályos 2013.06.01-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vagyon_ugyfel_taj_20130301.pdf">Ügyféltájékoztató 2013.03.01-től</a></li>
                </ul>
              </div>
              <div id="A3">
                <p>&nbsp;</p>
                <hr class="fancy-line" />
                <p>&nbsp;</p>
                <h1 class="subtitle">PetRisk Kisállat Biztosítás</h1>
                <p>&nbsp;</p>
                <p>A termék nem köthető.</p>
                <p>&nbsp;</p>
                <!--<h2 class="subtitle">Kárbejelentési dokumentumok</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kisallat_biztositasi_szolgaltatasi_igeny_bejelentesevel_kapcsolatos_adatkezeleshez_es_szolgaltatasi_igenybejelento_nyomtatvany.pdf">Adatkezelési tájékoztató kisállat biztosítási szolgáltatási igény bejelentésével kapcsolatos adatkezeléshez és szolgáltatási igénybejelentő nyomtatvány</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kisallattartoi_felelossegbiztositasi_karesemeny_bejelentesevel_kapcsolatos_adatkezeleshez_es_karbejelento_nyomtatvany.pdf">Adatkezelési tájékoztató kisállattartói felelősségbiztosítási káresemény bejelentésével kapcsolatos adatkezeléshez és kárbejelentő nyomtatvány</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kisallat_biztositasi_szolgaltatasi_igeny_bejelentesevel_kapcsolatos_adatkezeleshez.pdf">Adatkezelési tájékoztató kisállat biztosítási szolgáltatási igény bejelentésével kapcsolatos</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kisallattartoi_felelossegbiztositasi_karesemeny_bejelentesevel_kapcsolatos_adatkezeleshez.pdf">Adatkezelési tájékoztató kisállattartói felelősségbiztosítási káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_egeszsegugyi_adatok_kezelesehez_kisallattartoi_felelossegbiztositasi_karesemenyhez.pdf">Nyilatkozat egészségügyi adatok kezeléséhez (kisállattartói felelősségbiztosítási káreseményhez)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_szemelyes_adatok_kezelesehez_adott_hozzajarulas_visszavonasarol.pdf">Nyilatkozat személyes adatok kezeléséhez adott hozzájárulás visszavonásáról</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_szemelyes_es_egeszsegugyi_adatok_kezelesehez_adott_hozzajarulas_visszavonasarol.pdf">Nyilatkozat személyes és egészségügyi adatok kezeléséhez adott hozzájárulás visszavonásáról</a></li>
                </ul>
                <p>&nbsp;</p>
                <h2 class="subtitle">Archívum</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_petrisk.pdf">Biztosítási termékismertető</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}petrisk_feltetelcsomag_20200901.pdf">Petrisk biztosítási feltételcsomag (2020.09.01-tól hatályos)</a></li>
                </ul>-->
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
