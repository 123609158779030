<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 pt-4">
          <div class="container pt-5">
            <div class="row justify-content-center pt-4">
              <div class="col-lg-12">
                <h1 class="bold">NÉVVÁLTÁS</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-12">

                <div class="h4 text-primary">
                  Gránit Biztosító névre vált a Wáberer Hungária Biztosító
                </div>
                <p class="bold">
                  Február 15-től új névvel és arculattal, Gránit Biztosítóként folytatja a működését a Wáberer Hungária Biztosító,
                  amely a Gránit Bankkal kötött stratégiai együttműködési megállapodásának köszönhetően a digitális pénzintézet szakmai hátterére is építhet a jövőben.
                  A stratégiai megállapodás keretében a Gránit Bank hozzájárult a Gránit márkanév használatához.
                </p>

                <p>
                  <span class="bold"> Budapest, 2024. január 30.</span>
                  Nevet vált a Wáberer Hungária Biztosító, amely 2024. február 15-től Gránit Biztosítóként folytatja a tevékenységét.
                  A biztosítótársaság és a Gránit Bank még 2023 végén kötött stratégiai együttműködést a pénzügyi
                  és biztosítási szolgáltatások terén meglévő üzleti erőforrások kölcsönös szinergiájának kihasználására,
                  ennek keretében a dinamikusan fejlődő pénzintézet hozzájárult a Gránit márkanév használatához a biztosító számára.
                  Az együttműködés jegyében előbbi mellett a biztosító a termékportfóliójának értékesítésében számíthat a bank infrastruktúrájára,
                  miközben a pénzintézet újabb szolgáltatással egészíti ki az ügyfeleknek kínált portfólióját.
                </p>
                <p>
                  <span class="fst-italic">
                    „A Gránit Bank stratégiai célja, hogy full service pénzintézetté váljon, teljes körű banki, alapkezelési,
                    befektetési és biztosítási szolgáltatásokat nyújtva. A Wáberer Hungáriával kötött stratégiai együttműködésünknek köszönhetően
                    immáron biztosítási termékeket is tudunk mobil applikációban integrálva nyújtani lakossági és vállalati ügyfeleink számára,
                    tovább erősítve a Gránit brandet”
                  </span>
                  – mondta Hegedüs Éva, a Gránit Bank elnök-vezérigazgatója a névváltás kapcsán.
                </p>
                <p>
                  <span class="fst-italic">
                    „A Gránit Bank stratégiai célja, hogy full service pénzintézetté váljon, teljes körű banki, alapkezelési,
                    befektetési és biztosítási szolgáltatásokat nyújtva. A Wáberer Hungáriával kötött stratégiai együttműködésünknek köszönhetően
                    immáron biztosítási termékeket is tudunk mobil applikációban integrálva nyújtani lakossági és vállalati ügyfeleink számára,
                    tovább erősítve a Gránit brandet”
                  </span>
                  – mondta Hegedüs Éva, a Gránit Bank elnök-vezérigazgatója a névváltás kapcsán.
                </p>
                <p>
                  <span class="fst-italic">
                    „A Waberer’s Csoport üzleti stratégiájának megfelelően nem csak a logisztikai szolgáltatások terén igyekszik növelni piaci részesedését,
                    hanem a biztosítási üzletágában is.
                    Utóbbi szempontjából stratégiai fontosságú a termék- és értékesítési csatornák bővítése,
                    illetve a további biztosítási szegmensekben való növekedés.
                    Úgy láttuk, hogy a jövőbeni működést és organikus bővülést hatékonyan támogatja egy olyan márkanév felvétele,
                    amely jobban fókuszál a lakossági piacra és amelynek a neve egybeforrt a digitalizációval és innovációval”
                  </span>
                  – mondta Barna Zsolt, a Waberer’s Csoport elnök-vezérigazgatója.
                </p>
                <p>
                  <span class="fst-italic">
                    „Több mint másfél évtizedes működése alatt a társaságunk a hazai biztosítási piac megkerülhetetlen szereplőjévé fejlődött a kollégáink szakértelme,
                    illetve a digitális megoldásainknak köszönhetően.
                    A Gránit Bankkal kötött stratégiai együttműködés újabb fejezetet nyit a cégünk életében,
                    hiszen immáron a pénzintézet szakmai hátterére is támaszkodhatunk, és ezáltal a bank széles ügyfélköre számára válnak elérhetővé biztosítási termékeink,
                    köztük a lakásbiztosításaink is. Utóbbi területen ambiciózus célokkal vágunk neki a márciusi lakásbiztosítási kampánynak,
                    amely során növelni szeretnénk a piaci részesedésünket”
                  </span>
                  – tette hozzá dr. Nyilasy Bence, a Gránit Biztosító vezérigazgatója.
                </p>
                <p>
                  A Gránit Biztosító megújult arculatának bevezetése, illetve új honlapjának indítása február második felében valósul meg.
                  A társaság menedzsmentje a névváltás hatására nem változik.
                  A biztosítóval korábban megkötött szerződések változatlan feltételekkel és tartalommal élnek tovább,
                  ugyanolyan professzionális ügyfélkiszolgálás és változatlan, erős szakmai háttér mellett.
                </p>
                <p class="d-flex justify-content-center">
                  ***
                </p>
                <p class="bold">
                  A Gránit Biztosítóról
                </p>
                <p>
                  A társaságot 2008. szeptember 9-én alapították Wabard Biztosító Zrt. néven,
                  majd október 27-én „Gépjármű-biztosítás” végzésére kapott tevékenységi engedélyt,
                  egyben 2008. szeptember 12 óta a Magyar Biztosítók Szövetségének teljes jogú tagja.
                  A társaság célja, hogy felelős és átlátható működésű biztosítóként, a kor információ-technológiai eszközeinek maximális kihasználásával,
                  korszerű, kedvező árszínvonalú, és az ügyféligényeken alapuló termékeket és szolgáltatásokat kínáljon,
                  és ezzel a hazai biztosítási szektor megbízható szereplőjévé váljék.
                  Munkatársai szakmai felkészültsége, az új ismeretek iránti nyitottsága, és nem utolsó sorban a stabil tulajdonosi háttér a biztosíték arra,
                  hogy a Gránit Biztosító Zártkörűen Működő Részvénytársaság színvonalas és versenyképes szolgáltatásokkal áll
                  meglévő és leendő lakossági továbbá vállalati ügyfeleinek a rendelkezésére.
                </p>
                <p class="bold">
                  A Gránit Bankról
                </p>
                <p>
                  A Gránit Bank már 2010-ben „digitálisnak született”.
                  2010-es alapításától hatékonyan kombinálta a konzervatív hitelezést és az innovatív digitális banki megoldások egyre szélesebb körben való elterjesztését.
                  Ennek eredményeként a Bank kiváló minőségű vállalati és lakossági hitelportfóliót épített fel.
                  Lakossági ügyfeleit első sorban fiókhálózat nélkül, elektronikus csatornákon, okoseszközökön keresztül szolgálja ki.
                  Új csúcstechnológiát jelentő és az ügyfelek számára ingyenes digitális megoldásokkal széles rétegek számára teszi elérhetővé a fenntartható,
                  költséghatékony bankolást, ezzel Magyarországon a legkisebb ökológiai lábnyommal rendelkező pénzintézet.
                  A Gránit Bank 2022-ben a magyarországi bankok legrangosabb seregszemléjének számító Mastercard - év bankja 2022 versenyben négy kategóriában szerzett dobogós helyet.
                  A független zsűri a Gránit Banknak ítélte „Az év digitális banki UX megoldása”, valamint „Az év fiataloknak szóló programja” díjat.
                  2023-ban a Global Banking and Finance Review Magazine a „Leggyorsabban Növekvő Digitális Bank Magyarországon 2023” díjat adományozta a Banknak.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
