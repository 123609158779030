<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">CASCO BIZTOSÍTÁS</h1>                
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <p>&nbsp;</p>
              <div><a href="https://kalk.granitbiztosito.hu/casco_kalk1.aspx" class="btn btn-lg rounded-pill btn-secondary" target="_blank">Casco díjkalkuláció és kötés</a></div>
              <div class="clr"></div>
              <p>&nbsp;</p>
              <p class="question"><strong>Honnan ered a Casco kifejezés?</strong></p>
              <p>
                <b>A CASCO szó spanyol eredetű</b>, többféle jelentése ismert: „sisak”
                vagy „cserép, letört darab”. A CASCO a XVII. század óta ismert fogalom, főképp a
                tengerészetben, tengeri szállításban használatos, „hajó törzse, gépjármű” kifejezés.
                Mai értelemben a CASCO biztosítás a közlekedési eszközben elszenvedett károkat takarja,
                a legelterjedtebb az autó CASCO biztosítás, de létezik hajó casco és légi jármű
                casco biztosítás is.
              </p>
              <p class="question"><strong>Mire nyújt fedezetet a CASCO biztosítás?</strong></p>
              <p>
                A CASCO biztosítás a gépjárműben, alkatrészeiben, tartozékaiban - balesetből vagy
                idegen személy által okozott rongálásból - keletkezett károk fedezetére szolgál.
                <b>A gépjármű szériafelszereltségén</b> felüli extra tartozékokat külön
                kell biztosítani, az utólag beépített, szériafelszereltségek felett rendelt tartozékok
                abban az esetben biztosítottak, ha azok a biztosítási ajánlatban felsorolásra kerültek.
              </p>
              <p class="question"><strong>Fedezetek fajtái:</strong></p>
              <ul class="ulstyle">
                <li>Arany fedezet (elemi-, törés-, lopás károk, üvegtörés)</li>
                <li>Ezüst fedezet (elemi-, törés, üvegkárok)</li>
              </ul>
              <p>&nbsp;</p>
              <p class="question"><strong>Kiegészítő biztosítások:</strong></p>
              <ul class="ulstyle">
                <li>Poggyászlopás biztosítások,</li>
                <li>Kiegészítő közlekedési balesetbiztosítás,</li>
                <li>Hibás tankolás biztosítás,</li>
                <li>Gyerekfelszerelés biztosítás,</li>
                <li>Rendszám, Forgalmi engedély pótlása,</li>
                <li>Kölcsöngépjármű költségtérítése,</li>
              </ul>
              <p>&nbsp;</p>
              <p class="question"><strong>Speciális szolgáltatások:</strong></p>
              <ul class="ulstyle">
                <li>speciális avultatások,</li>
                <li>12 féle egyedi önrész konstrukció,</li>
                <li>kárrendezési szolgáltatások</li>
              </ul>
              <p>&nbsp;</p>
              <p class="question"><strong>Ki kötheti meg a biztosítási szerződést?</strong></p>
              <p>
                A forgalmi engedélyében tulajdonosként, vagy üzembentartóként megnevezett személy,
                és akinek a vagyontárgy megóvásához érdeke fűződik.
              </p>
              <p class="question"><strong>Mi a biztosítás területi és időbeli hatálya?</strong></p>
              <p>
                A biztosítás a földrajzi értelemben vett Európa területén bekövetkezett károkra
                nyújt fedezetet. Európa területi hatályába tartozik a volt Szovjetunió utódállamai
                közül: Észtország, Lettország, Litvánia (az ázsiai területekre nem terjed ki a biztosítás
                hatálya), valamint Törökország teljes területe.
              </p>
              <p>
                A biztosítási szerződés lehet határozott vagy határozatlan idejű, melyet a
                biztosítási szerződés tartalmaz.
              </p>
              <p class="question"><strong>Mi határozza meg a biztosítási díjat?</strong></p>
              <p>
                a gépjármű gyártmánya,&nbsp; az extra felszereltségek értéke,<br />
                a választott önrész mértéke, a díjfizetés fajtája és gyakorisága<br />
                a szerződő életkora és lakhelye<br />
                a biztosító szabályzataiban meghatározott kedvezmények és pótdíjak
              </p>
              <p class="question"><strong>További információra van szüksége?</strong></p>
              <p>
                <a [routerLink]="['/gyik']">Gyakran ismételt kérdések menü alatt</a> részletes tájékoztatást
                nyújtunk Önnek a CASCO biztosítás szolgáltatásáról, a szerződés megkötésére és megszűntetésére
                vonatkozó tudnivalókról, a kockázatban állásról és a kárügyintézésről.
              </p>
              <p>
                Kérdés esetén készséggel állunk rendelkezésére hívja bizalommal ügyfélszolgálati
                munkatársainkat. Adminisztratív ügyintézés és Kárrendezési hely: 1211 Budapest,
                Szállító u. 4. Tel.: 06 1 6666 200
              </p>
              <h2 class="subtitle">
                <b>Vonatkozó jogszabályok</b>
              </h2>

              <ul class="ulstyle">
                <li>2014. évi LXXXVIII. törvény a biztosítási tevékenységről</li>
                <li>2013. évi V. törvény a Polgári Törvénykönyvről</li>
              </ul>

              <h2 class="subtitle"><strong>A biztosítási módozathoz tartozó számlaszám</strong></h2>
              <table style="width: 100%;"><tr><td><span>10918001-00000068-64440044 </span></td><td>IBAN: <span>HU10 1091 8001 0000 0068 6444 0044</span></td></tr></table>

              <h2 class="subtitle">Letölthető dokumentumok</h2>
              <ul>
                <li><a class="download" href="{{weboldalDokumentumok}}granit_casco_arany.pdf" target="_blank">CASCO Biztosítási termékismertető - Arany fedezet</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}granit_casco_ezust.pdf" target="_blank">CASCO Biztosítási termékismertető - Ezüst fedezet</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20240215.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2024.02.15-től hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_valtozas_bejelento_20240215.pdf" target="_blank">Változás bejelentő adatlap CASCO szerződéshez 2024.02.15-től hatályos</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}csop_besz_megbizas_20120921.pdf" target="_blank">Felhatalmazás csoportos beszedési megbízás teljesítésére</a></li>                
                <li><a class="download" href="{{weboldalDokumentumok}}casco_egyedi_tarsbiztositott_bejegyzes_nyilatkozat.pdf" target="_blank">CASCO Egyedi Társbiztosított Bejegyzés Nyilatkozat</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_egyedi_tarsbiztositott_bejegyzes_nyilatkozat_ceges.pdf" target="_blank">CASCO Egyedi Társbiztosított Bejegyzés Nyilatkozat - céges</a></li>                
              </ul>
              <h2 class="subtitle">
                <a name="dl"></a><strong>Kárbejelentési dokumentumok</strong>
              </h2>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_adatkez_tajek_gj_es_kieg_bizt_adatkez.pdf">Adatkezelési tájékoztató casco biztosítási gépjármű- és kiegészítő biztosítási káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_adatkez_tajek_kieg_bizt_adatkez.pdf">Adatkezelési tájékoztató casco kiegészítő balesetbiztosítási esemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_kieg_bizt_esemeny_bejelento_nyomt_adatkez_tajek.pdf">Balesetbiztosítási esemény bejelentő nyomtatvány casco kiegészítő balesetbiztosítási szerződéshez és adatkezelési tájékoztató casco kiegészítő balesetbiztosítási esemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_gj_es_kieg_bizt_bejelento_nyilatkozat_visszavonas.pdf">Casco biztosítási gépjármű- és kiegészítő biztosítási káresemény bejelentőjének nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_kieg_bizt_bejelento_nyilatkozat_visszavonas.pdf">Casco kiegészítő balesetbiztosítási esemény bejelentőjének nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_karbejelento_nyomt_gj_es_kieg_bizt_adatkez_tajek.pdf">Kárbejelentő nyomtatvány casco biztosítási gépjármű- és kiegészítő biztosítási káreseményhez és adatkezelési tájékoztató casco biztosítási gépjármű- és kiegészítő biztosítási káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
              </ul>
              <p>
                <b>Flottaüzemeltetők részére</b> is igyekszünk versenyképes biztosítási alternatívát kínálni.<br />
                Egyedi, személyre szabott ajánlatért keressék bizalommal kollégáinkat.
              </p>
              <h2 class="subtitle">Archívum</h2>
              <ul>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20230615.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2023.06.15-től hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_valtozas_bejelento_20120921.pdf" target="_blank">Változás bejelentő adatlap CASCO szerződéshez 2012.09.21-től hatályos</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20230501.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2023.05.01-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20210501.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2021.05.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a><br /></li>

                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20200701.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2020.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a><br /></li>

                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20190426.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2019.04.26-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20190101.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2019.01.01-től hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20181105.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.11.05-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20180808.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2018.08.08-tól hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20180701.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2018.07.01-től hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20180525.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2018.05.25-tól hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20180223.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2018.02.23-tól hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20180101.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2018.01.01-től hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20170601.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2017.06.01-től hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20170501.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2017.05.01-től hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20170401.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2017.04.01-től hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20170101.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2017.01.01-től hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20160901.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2016.09.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20160701.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2016.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-től hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20160101.pdf" target="_blank">CASCO biztosítási feltételek, ügyfél- és adatkezelési tájékoztató, általános kárbiztosítási és különös szerződési feltételek (CASCKF 2016.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a><br /></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20150101.pdf" target="_blank">CASCO biztosítási feltételek 2015.01.01-től hatályos</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20150101.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2015.01.01-től hatályos)</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20140610.pdf" target="_blank">CASCO biztosítási feltételek 2014.06.10-től hatályos</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20131001.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2013.10.01-től hatályos)</a></li>
                <li>
                  <a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20140315.pdf" target="_blank">
                    CASCO biztosítási
                    feltételek 2014.03.15-től hatályos
                  </a>
                </li>
                <li>
                  <a class="download" href="{{weboldalDokumentumok}}casco_feltetelcsomag_20120921.pdf" target="_blank">
                    CASCO biztosítási
                    feltételek 2012.09.21-től hatályos
                  </a>
                </li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20130701.pdf" target="_blank">Ügyféltájékoztató 2013.07.01-től</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20130301.pdf" target="_blank">Ügyféltájékoztató 2013.03.01-től</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_adatkez_taj_20120120.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató 2012. január 20-tól</a></li>
                <li>
                  <a class="download" href="{{weboldalDokumentumok}}casco_biztositasi feltetelek.pdf" target="_blank">
                    CASCO biztosítási
                    feltételek (ÜFTK212/ÁVF02/CASKF212)
                  </a>
                </li>
                <li><a class="download" href="{{weboldalDokumentumok}}gepjarmu_karbejelento.pdf" target="_blank">Kárbejelentő lap</a></li>
                <li>
                  <a class="download" href="{{weboldalDokumentumok}}csop_besz_megbizas.pdf" target="_blank">
                    Felhatalmazás csoportos
                    beszedési megbízás teljesítésére
                  </a>
                </li>
                <li>
                  <a class="download" href="{{weboldalDokumentumok}}casco_valtozas_bejelento.pdf" target="_blank">
                    Változás bejelentő
                    adatlap CASCO szerződéshez
                  </a>
                </li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20120101.pdf" target="_blank">Ügyféltájékoztató 2012. január 1-jétől</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20110701.pdf" target="_blank">Ügyféltájékoztató 2011. július 1-jétől</a><br /></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
