<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">

        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">MEGÓV-LAK MINŐSÍTETT FOGYASZTÓBARÁT OTTHONBIZTOSÍTÁS</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <p>
                Miért fogyasztóbarát biztosítási termék a „Megóv-lak” lakásbiztosítás?
              </p>
              <p>
                Az Ön igényeinek megfelelően már az Alapbiztosítás is széleskörű biztosítási védelem nyújt! Tűz- és elemi károktól a vezetékes vízkáron át a felelősségbiztosításig 20 biztosítási kockázatot tartalmaz az Alapcsomag! Így már nem kell félni egy esetleges betöréstől, rongálástól vagy üvegtöréstől sem!
              </p>
              <p>
                A választható kiegészítő biztosításokkal az alapkockázatokon túlmutató veszélyekre is felkészülhet. A duguláselhárítás költségeitől, akár a kerti dísznövények biztosításig 17 kiegészítő biztosítás közül választva saját igényeihez igazíthatja biztosítását.
                Kötéstől a kárrendezésig szabályozott, gyors ügyintézés, akár otthonából is intézheti ügyeit. Kárügyéről pontos információt kap a biztosítótól! Gyors kárkifizetés, a kárszemle vagy az utolsó szükséges dokumentum beérkezését követő 5 munkanapon belül történik.
                A MEGÓV-LAK Minősített Fogyasztóbarát Lakásbiztosítás az MNB felügyelet engedélyével kerül forgalomba, ez garancia Önnek! Ránk biztosan számíthat!
              </p>
              <div class="row">
                <div class="col-6">
                  <img id="mfo_logo" src="../../../assets/images/mfo_logo.png" />
                </div>
                <div class="col-6">
                  <h2 class="subtitle">A Magyar Nemzeti Bank összehasonlító oldala</h2>
                  <ul>
                    <li><a target="_blank" href="https://www.mnb.hu/mfo">https://www.mnb.hu/mfo</a></li>
                  </ul>
                </div>
                </div>

                <div>
                  <a href="https://mfo.granitbiztosito.hu/mfo_kalk1.aspx" target="_blank" class="btn btn-lg rounded-pill btn-secondary">Kössön Minősített Fogyasztóbarát Otthonbiztosítást!</a>
                </div>
                <div class="clr"></div>
                <h2 class="subtitle">
                  <b>Vonatkozó jogszabályok</b>
                </h2>

                <ul class="ulstyle">
                  <li>2014. évi LXXXVIII. törvény a biztosítási tevékenységről</li>
                  <li>2013. évi V. törvény a Polgári Törvénykönyvről</li>
                </ul>

                <h2 class="subtitle"><strong>A biztosítási módozathoz tartozó számlaszám</strong></h2>
                <table style="width: 100%;">
                  <tr>
                    <td><span>10918001-00000068-64440075</span></td>
                    <td>IBAN: <span>HU46 1091 8001 0000 0068 6444 0075</span></td>
                  </tr>
                </table>

                <h2 class="subtitle"><b>Letölthető dokumentumok</b></h2>
                <ul>

                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_mfo_biztositasi_termekismerteto_ipid.pdf">MEGÓV-LAK Minősített Fogyasztóbarát Otthonbiztosítás Biztosítási termékismertető</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_mfo_ugyfeltajekoztato_akf_kulonos_feltetelek.pdf">MEGÓV-LAK Minősített Fogyasztóbarát Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2023.10.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a></li>

                  <li style="margin-top: 20px"><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_kiegeszito_balesetbiztositasi_esemeny_adatkezeles_es_karbejelento.pdf">Kárbejelentő nyomtatvány MEGÓV-LAK minősített fogyasztóbarát otthonbiztosítás kiegészítő balesetbiztosítási káreseményhez és adatkezelési tájékoztató MEGÓV-LAK minősített fogyasztóbarát otthonbiztosítás alapján bejelentett kiegészítő balesetbiztosítási eseménnyel összefüggő adatkezeléshez</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_otthonbiztositasi_esemeny_adatkezeles_es_karbejelento.pdf">Kárbejelentő nyomtatvány MEGÓV-LAK minősített fogyasztóbarát otthonbiztosítási káreseményhez és adatkezelési tájékoztató MEGÓV-LAK minősített fogyasztóbarát otthonbiztosítás alapján bejelentett káreseménnyel összefüggő adatkezeléshez</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_nyilatkozat_szemelyes_adatok_kezelesenek_hozzajarulas_visszavonasa.pdf">MEGÓV-LAK minősített fogyasztóbarát otthonbiztosítás otthonbiztosítási káresemény bejelentőjének nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_nyilatkozat_egeszsegugyi_adatok_kezelesehez_balesetbiztositasi_esemeny.pdf">Nyilatkozat MEGÓV-LAK Minősített fogyasztóbarát otthonbiztosítás kiegészítő balesetbiztosítási eseménnyel összefüggő egészségügyi adat kezeléséhez</a></li>


                  <li style="margin-top: 20px"><a class="download" target="_blank" href="{{weboldalDokumentumok}}csop_besz_megbizas_20120921.pdf">Felhatalmazás csoportos beszedési megbízás teljesítésére</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}igenyazonosito_lap_20240215.pdf">Igényazonosító lap</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}karrendezeshez_szukseges_dokumentumok_listaja.pdf">Kárrendezéshez szükséges dokumentumok listája</a></li>

                  <li><a class="download" href="{{weboldalDokumentumok}}egyedi_zalogkotelezetti_nyilatkozat.pdf" target="_blank">Egyedi Zálogkötelezetti nyilatkozat</a></li>
                  <li><a class="download" href="{{weboldalDokumentumok}}egyedi_zalogkotelezetti_nyilatkozat_ceges.pdf" target="_blank">Egyedi Zálogkötelezetti nyilatkozat - céges</a></li>
                </ul>
                <h2 class="subtitle"><b>Kárbejelentési dokumentumok</b></h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_karbejelentolap.pdf">Kárbejelentő nyomtatvány MEGÓV-LAK minősített fogyasztóbarát otthonbiztosítási káreseményhez</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakvelemeny.pdf">Szakvélemény</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}felelosseg_elismero_nyilatkozat.pdf">Felelősség elismerő nyilatkozat</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ingosagi_karbejelento.pdf">Ingósági kárbejelentő</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_hatosagi_eljarasrol.pdf">Nyilatkozat hatósági eljárásról</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
                </ul>

                <h2 class="subtitle">Archívum</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_mfo_ugyfeltajekoztato_akf_kulonos_feltetelek_20231001.pdf">MEGÓV-LAK Minősített Fogyasztóbarát Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2023.10.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_mfo_ugyfeltajekoztato_akf_kulonos_feltetelek_20220701.pdf">MEGÓV-LAK Minősített Fogyasztóbarát Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2022.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_mfo_ugyfeltajekoztato_akf_kulonos_feltetelek_20211220.pdf">MEGÓV-LAK Minősített Fogyasztóbarát Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_mfo_ugyfeltajekoztato_akf_kulonos_feltetelek_20210501.pdf">MEGÓV-LAK Minősített Fogyasztóbarát Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2021.05.01-től hatályos)</a></li>
                  <li>
                    <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a><br />
                  </li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}megov-lak_mfo_ugyfeltajekoztato_akf_kulonos_feltetelek.pdf">MEGÓV-LAK Minősített Fogyasztóbarát Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2020.12.15-től hatályos)</a></li>
                  <li>
                    <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a><br />
                  </li>
                </ul>
              </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
