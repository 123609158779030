<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5">
          <div class="container pt-5">
            <div class="row justify-content-center pt-4">
              <div class="col-lg-12">
                <h1 class="bold">GYAKRAN ISMÉTELT KÉRDÉSEK</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-info mb-4 mt-5 py-4 article-section">
          <div class="container pb-5">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="accordion shadow-lg rounded-4" id="accordionGranit">
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header ">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        1. Hogyan köthetek kötelező gépjármű felelősségbiztosítást, kinek a kötelessége ez?
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Amennyiben Ön új, vagy használt gépjárműnek tulajdonosává, vagy üzembentartójává vált,
                            úgy a tulajdonjog, illetve az üzembentartói jog átszállásának napjával kötelező felelősségbiztosítást kell kötnie.
                            A felelősségbiztosítás megkötésére a gépjármű mindenkori üzembentartója kötelezett, azaz,
                            amennyiben a gépjármű tulajdonosa és üzembentartója a forgalmi engedélybe való bejegyzés alapján eltérő személy, a szerződést akkor is az üzembentartónak kell megkötnie.
                            A szerződés megkötését kezdeményezheti személyesen ügyfélszolgálati irodánkban az alábbi címen:
                            1211 Budapest, Szállító utca 4., honlapunkon a <a href="https://granitbiztosito.hu"><strong>granitbiztosito.hu</strong></a> címen,
                            vagy szerződött alkusz partnereinken keresztül.
                          </p>
                          <p>
                            <span class="bold">Hagyományos díjkalkuláció</span><br />
                            A hagyományos díjkalkuláció során minden olyan adat bekérésre kerül, amit a biztosítási ajánlat elkészítéséhez szükséges.
                            Ezt a kötési módot ajánljuk, amennyiben Ön új vagy használt gépjárművet vásárolt, vagy annak üzembentartójává vált, forgalomba még nem helyezett,
                            vagy forgalomból kivont gépjárműre szeretne szerződést kötni, vagy Önnek díjnemfizetés miatt szűnt meg az előző szerződése.
                            Az alábbi gombra kattintva megkezdheti az adatok megadását.
                          </p>
                          <div class="pt-2">
                            <a [routerLink]="['/kgfb']" class="btn rounded-pill btn-secondary">RÉSZLETEK</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        2. Hogyan igényelhetek igazolást, kötvényt, csekket, vagy nemzetközi zöldkártyát biztosításomhoz?
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            A fentiekben felsorolt dokumentumokat a nemzetközi zöldkártya kivételével minden esetben automatikusan küldjük ügyfeleinknek.
                            Amennyiben valamilyen oknál fogva Ön mégsem kapta volna meg az iratokat, úgy igényelhet személyesen a 1211 Budapest, Szállító utca 4. számon ügyfélszolgálati irodánkban,
                            telefonon a +36-1-666-6200 számon, illetve e-mail-ben az
                            <a href="mailto:ugyfelszolgalat@granitbiztosito.hu">ugyfelszolgalat@granitbiztosito.hu</a> címünkön.
                          </p>
                          <p>
                            Nemzetközi zöldkártya igényét megteheti a <a href="mailto:zoldkartya@granitbiztosito.hu">zoldkartya@granitbiztosito.hu</a> e-mail címen is.
                            Munkatársaink az igény beérkezését követő munkanapon postázzák a zöldkártyát.
                          </p>
                          <p>
                            2019. január 8-ától elindult a MABISZ által fejlesztett digitális kárbejelentő alkalmazás, amely az ekar.hu oldalon keresztül tölthető le.
                            Ennek használata esetén nem szükséges a papír alapú kék-sárga bejelentőlap kitöltése.
                            A Zöldkártyán található egy QR kód, ennek beolvasásával az Ön szerződésének adatai feltölthetők a digitális kárbejelentőre,
                            így gyorsabb és pontosabb annak kitöltése.
                          </p>
                          <p>
                            Javasoljuk, hogy nemzetközi zöldkártya igénylését az utazás előtt legalább egy héttel tegye meg,
                            hogy a postai kézbesítés esetleges csúszása semmiképpen ne okozzon kellemetlenséget Önnek.
                          </p>
                          <p>
                            A zöld kártya kizárólagosan fehér színű papírra nyomtatható.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        3. Milyen bankszámlaszámra utalhatom a biztosítási díjat?
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <table class="table table-striped table-borderless">
                            <tbody>
                              <tr>
                                <td class="col-lg-3">GFB biztosítási díj:</td>
                                <td class="col-lg-4">10918001-00000068-64440037</td>
                                <td class="col-lg-5">IBAN: HU05 1091 8001 0000 0068 6444 0037</td>
                              </tr>
                              <tr>

                                <td>Vagyon biztosítások díja:</td>
                                <td>10918001-00000068-64440075</td>
                                <td>IBAN: HU46 1091 8001 0000 0068 6444 0075</td>
                              </tr>
                              <tr>

                                <td>KÁF, KSZF biztosítások díja:</td>
                                <td>10918001-00000068-64440068</td>
                                <td>IBAN: HU41 1091 8001 0000 0068 6444 0068</td>
                              </tr>
                              <tr>

                                <td>CMR biztosítások díja:</td>
                                <td>10918001-00000068-64440051</td>
                                <td>IBAN: HU15 1091 8001 0000 0068 6444 0051</td>
                              </tr>
                              <tr>
                                <td>Casco biztosítások díja:</td>
                                <td>10918001-00000068-64440044</td>
                                <td>IBAN: HU10 1091 8001 0000 0068 6444 0044</td>
                              </tr>
                              <tr>
                                <td>EUR díjfizetésű flotta számla:</td>
                                <td>10918001-00000068-64440257</td>
                                <td>IBAN: HU79 1091 8001 0000 0068 6444 0257</td>
                              </tr>
                              <tr>
                                <td>Regressz, megtérülési számla:</td>
                                <td>10918001-00000068-64440329</td>
                                <td>IBAN: HU75 1091 8001 0000 0068 6444 0329</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>
                            Kérjük, hogy az utalás
                            <span class="bold">
                              közlemény mezőjébe a szerződésazonosító számot
                            </span>
                            szíveskedjen feltüntetni.
                          </p>
                          <p>
                            GIRO rendszeren keresztül történő díjlehívás esetén, az alábbi azonosítók megadása szükséges a csoportos beszedési megbízás megadásakor:<br />
                            Jogosult azonosítója: A14489765<br />
                            A fogyasztó, vagy ügyfél azonosító mezőbe a kötvényen és egyéb dokumentumokon szerződésazonosító megnevezéssel szereplő számsort kell beírni.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        4. Mi a teendőm, ha értékesítettem a gépjárművet?
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Társaságunk a központi gépjármű-nyilvántartásból értesül az adás-vétel bejegyzéséről és az értesítés alapján,
                            <span class="bold">a kötelező gépjármű felelősségbiztosítási szerződés törlését elvégzi.</span><br />
                            Kérjük, hogy írásban akkor szíveskedjen bejelenteni Társaságunk felé az adás-vételt,
                            ha az okmányirodai ügyintézéstől számított két héten belül nem kapja meg a törlésről szóló értesítést.
                          </p>
                          <p>
                            A biztosító az érdekmúlást rendszerében rögzíti és a szerződésen rögzített kommunikációnak megfelelően e-mailcímére,
                            vagy postai úton a megszűnésről kártörténeti igazolást küld, melyet Ön két éven belül felhasználhat egy másik gépjárműre vonatkozó szerződés kötésénél,
                            amennyiben az eladott és vásárolt gépjármű kategóriája megegyezik.
                          </p>
                          <p>
                            <span class="bold">
                              A casco szerződések megszüntetéséhez
                            </span>
                            továbbra is kérjük, hogy az eladás tényét 8 napon belül írásban szíveskedjen bejelenteni Társaságunk felé.
                          </p>
                          <p>
                            Levélben, vagy személyesen a 1211 Budapest, Szállító utca 4. címünkre,
                            illetve e-mail-ben az <a href="mailto:ugyfelszolgalat@granitbiztosito.hu">ugyfelszolgalat@granitbiztosito.hu</a> címünkre.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        5. Mit tegyek, ha gépjárművemen üzembentartó váltás történt?
                      </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Társaságunk a központi gépjármű-nyilvántartásból értesül az üzembentartóváltás bejegyzéséről és az értesítés alapján,
                            <span class="bold">a kötelező gépjármű-felelősségbiztosítási szerződés törlését elvégzi.</span>
                            Kérjük, hogy írásban akkor szíveskedjen bejelenteni Társaságunk felé az üzembentartóváltást,
                            ha az okmányirodai ügyintézéstől számított két héten belül nem kapja meg a törlésről szóló értesítést.
                          </p>
                          <p>
                            A biztosító az érdekmúlást rendszerében rögzíti és a szerződésen rögzített kommunikációnak megfelelően e-mailcímére,
                            vagy postai úton a megszűnésről kártörténeti igazolást küld, melyet Ön két éven belül felhasználhat egy másik gépjárműre vonatkozó szerződés kötésénél,
                            amennyiben az eladott és vásárolt gépjármű kategóriája megegyezik.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        6. Hogyan járjak el, ha gépjárművemet ki szeretném vonatni a forgalomból?
                      </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            A kivonás a Kormányablaknál kezdeményezhető.
                          </p>
                          <p>
                            Társaságunk a központi gépjármű-nyilvántartásból értesül a gépjármű ideiglenes vagy végleges forgalomból történő kivonásáról és az értesítés alapján a szerződés módosítását, végleges kivonás esetén a szerződés törlését elvégzi.
                          </p>
                          <p>
                            Társaságunk a szerződésen rögzített kommunikációnak megfelelően e-mailcímére, vagy postai úton a bejegyzésről értesítést küld.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        7. Mi a teendőm, ha újra forgalomba szeretném helyezni a gépjárművet?
                      </button>
                    </h2>
                    <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Újra forgalomba helyezés előtt kérjük, hogy keresse meg Társaságunkat elérhetőségeink valamelyikén, mivel a forgalomba visszaléptetés előtt kötvényt küldünk Önnek,
                            amellyel a Kormányablakban a forgalomba való visszahelyezést kezdeményezheti.
                          </p>
                          <p>
                            A visszahelyezésről Társaságunk a központi gépjármű-nyilvántartásból értesül és az értesítés alapján a szerződés módosítását elvégzi.
                          </p>
                          <p>
                            Társaságunk a szerződésen rögzített kommunikációnak megfelelően e-mailcímére, vagy postai úton a bejegyzésről értesítést küld.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        8. Mit tegyek, ha szerződésem díj nemfizetéssel megszűnik?
                      </button>
                    </h2>
                    <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Amennyiben biztosítónk arról küld értesítést, hogy szerződése díj nemfizetéssel megszűntetésre került,
                            úgy kérjük, hogy mihamarabb vegye fel a kapcsolatot ügyfélszolgálatunkkal,
                            vagy az Ön által megbízott biztosítás közvetítő céggel az új szerződés megkötése érdekében.
                            Amennyiben szerződése az adott biztosítási időszakban szűnt meg, úgy az új szerződését csak és kizárólag ahhoz a biztosítóhoz kötheti vissza,
                            amely a biztosítási szerződését megszűntette. Felhívjuk szíves figyelmét arra, hogy a biztosításra vonatkozó szabályok 2010. 01. 01-től megváltoztak.
                            Bevezetésre került az úgynevezett fedezetlenségi díj, mely gépjármű kategóriánként változó összegű és napi díjas - esetenként igen jelentős értékkel bír -,
                            továbbá a gépjármű a megszűnés napját követő naptól nem áll biztosítási kockázatban, így az új szerződés mielőbbi megkötése különösen fontos az Ön számára.
                          </p>
                          <p>
                            A fedezetlenségi díjakról a <a href="www.mabisz.hu" target="_blank">www.mabisz.hu</a> honlapon tájékozódhat.
                          </p>
                          <p>
                            Szerződését a biztosító, a díj esedékességétől számított 60 napos türelmi időt követően törli, és a megszűnésről 15 napon belül értesítést küld
                          </p>
                          <p>
                            A szerződés újra kötését megteheti személyesen a 1211 Budapest, Szállító utca 4. címen található ügyfélszolgálati irodánkban,
                            honlapunkon a <a href="https://granitbiztosito.hu">granitbiztosito.hu</a> címen, továbbá szerződött alkusz partnereink segítségével.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                        9. Mit kell tennem, amennyiben baleset részese voltam?
                      </button>
                    </h2>
                    <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Amennyiben Ön baleset részese volt, úgy a kitöltött európai baleseti bejelentő másolati példányát,
                            illetve a honlapunkról / <a href="https://granitbiztosito.hu">granitbiztosito.hu</a> / letölthető és kitöltött baleseti bejelentő (nem azonos az európai baleseti bejelentő űrlappal)
                            egy-egy másolati példányát szíveskedjen eljuttatni biztosítónkhoz.
                            A bejelentést megteheti a <a href="mailto:kar@granitbiztosito.hu">kar@granitbiztosito.hu</a> e-mail címünkön, személyesen az 1211 Budapest, Szállító utca 4. szám alatti ügyfélszolgálati irodánkban,
                            vagy a weboldalunkon, https://granitbiztosito.hu/karbejelento oldalon.
                          </p>
                          <p>
                            Működik a kárbejelentő applikáció! Az autósok mobiltelefonjukon is intézhetik már a baleset utáni adminisztratív teendőket!
                            Elindult ugyanis a Magyar Biztosítók Szövetségének (MABISZ) új, digitális kárbejelentője,
                            amely a hagyományos, „kék-sárga” nyomtatvánnyal egyenértékű, ám gyorsabb és egyszerűbb ügyintézést tesz lehetővé.
                            Az egész kárrendezési folyamat digitalizációjának is lökés adhat az E-kárbejelentő,
                            amely a MABISZ és az érintett 13 tagbiztosító teljes összefogásával valósulhatott meg.
                            Balesetet követően mindkét félnek jelentkeznie kell a saját biztosítójánál.
                            Erre a károkozónak 5 nap áll rendelkezésre, a károsultnak pedig 30 napja van arra,
                            hogy a károkozó biztosítójánál a saját kárigényét bejelentse. Az alkalmazás használata ezt a kötelezettséget is leegyszerűsíti,
                            hiszen az adatok kitöltése után, ha minden érintett fél aláírta a bejelentőt, egy gombnyomással el tudják azt küldeni a helyszínről.
                            A bejelentés során a rendszer e-mailben is küld egy értesítést minden érintettnek, mellékelve a kitöltött pdf formátumú kárbejelentőt.
                            Ezt követően a biztosító belső folyamatainak megfelelően felveszi a kapcsolatot a bejelentőkkel.
                          </p>
                          <p>
                            Az alkalmazás letölthető a <a href="https://ekar.hu" target="_blank">https://ekar.hu</a> oldalon, androidos és iphone-os telefonokra is.
                          </p>
                          <p>
                            Amennyiben Ön károkozó volt a balesetben, úgy a bejelentés megtételére 5 munkanap áll rendelkezésére,
                            ha pedig Ön a károsult, úgy a bejelentést 30 napon belül tegye meg biztosítónk felé a kár kifizetésének mielőbbi rendezésére.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        10. Mivel tudom igazolni biztosításom meglétét és érvényességét?
                      </button>
                    </h2>
                    <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            A biztosítás igazolására szolgál a biztosítási díj megfizetését igazoló bizonylat (feladóvevény),
                            amennyiben azon feltüntetésre került a díjjal fedezett időszak.
                            E mellett még tartsa magánál biztosítási kötvényét, illetve a biztosítás megkötéséről szóló ajánlatot is.
                            Amennyiben Ön átutalással, vagy csoportos beszedéssel fizette meg a biztosítási díját,
                            úgy a biztosító a díj szerződésre könyvelése után automatikusan igazolást küld címére,
                            melyet szintén használhat a biztosítási fedezet meglétének bizonyítására.
                            Ezeken felül még igazoló értékű a nemzetközi zöldkártya és az Igazolás kötelező felelősség biztosításról elnevezésű nyomtatvány is.
                            Amennyiben Önnek egyik igazoló eszköz sem áll rendelkezésére,
                            úgy igényelhet személyesen a 1211 Budapest, Szállító utca 4. szám alatti ügyfélszolgálati irodánkban,
                            telefonon a +36-1-666-6200 számon,
                            vagy e-mail-ben az <a href="mailto:ugyfelszolgalat@granitbiztosito.hu">ugyfelszolgalat@granitbiztosito.hu</a> címünkön
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                        11. Hogyan használhatom fel másik gépjárműre már megszerzett bónuszomat?
                      </button>
                    </h2>
                    <div id="collapseEleven" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Amennyiben Ön értékesítette gépjárművét és ahhoz a szerződéshez kapcsolódóan már volt megszerzett bónusza,
                            úgy legfeljebb 2 éven belül kérheti a bónusz átvezetését az újonnan vásárolt gépjármű szerződésére,
                            amennyiben a gépjármű kategóriák megegyeznek. Tehát például egy tehergépkocsira vonatkozó bónuszt nem lehet átvezettetni személygépkocsira.
                            A bónusz besorolása személyre szól, így az másra nem ruházható át.
                            Bónusz átvezetésének, vagy felvezetésének kérését írásban tegye meg biztosítónk felé az előző szerződésre vonatkozó kártörténeti igazolás becsatolásával.
                            Kérését megteheti személyesen a 1211 Budapest, Szállító utca 4. szám alatti ügyfélszolgálati irodánkban,
                            levélben ugyanezen a címen,
                            vagy e-mail-ben az <a href="mailto:ugyfelszolgalat@granitbiztosito.hu">ugyfelszolgalat@granitbiztosito.hu</a> címünkön.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                        12. Miként emelkedhet a bónuszom?
                      </button>
                    </h2>
                    <div id="collapseTwelve" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            A bónusz fokozat abban az esetben emelkedik a következő biztosítási időszakban egy osztályt,
                            ha a szerződéssel  érintett gépjármű az új biztosítási időszakot közvetlenül megelőző biztosítási időszak és
                            az új biztosítási időszak kezdő napjai közötti időtartam során legalább 270 napig biztosítási fedezettel rendelkezett és
                            ebben az időtartamban az üzemben tartónak az érintett gépjármű vonatkozásában – a károkozás időpontjától függetlenül – kártérítési
                            kötelezettsége (az első kárkifizetés vagy a biztosítóval szemben hozott jogerős ítélet dátuma) nem vált ismertté.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                        13. Mit kell tennem, ha gépjárművemet ellopták?
                      </button>
                    </h2>
                    <div id="collapseThirteen" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            A biztosítónak meg kell küldeni a rendőrségi határozatot a nyomozás megszűntetéséről,
                            vagy a gépjármű megkerüléséről, illetve javasolt a nyomozás ideje alatt a gépjármű forgalomból történő ideiglenes kivonása annak érdekében,
                            hogy Önt, mint üzembentartót ne terhelje a gépjármű megkerüléséig fizetési kötelezettség.
                            A kivonás tényét a forgalmi engedélybe történő bejegyzés után írásban be kell jelenteni a biztosítónak.
                            Ezt megteheti személyesen a 1211 Budapest, Szállító utca 4. szám alatti ügyfélszolgálati irodánkban, levélben ugyanezen a címen,                            
                            vagy e-mail-ben az <a href="mailto:ugyfelszolgalat@granitbiztosito.hu">ugyfelszolgalat@granitbiztosito.hu</a> címünkön.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                        14. Mit kell tennem, ha adatváltozást szeretnék bejelenteni (lakcím, név, rendszám stb.)?
                      </button>
                    </h2>
                    <div id="collapseFourteen" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Amennyiben személyes adataiban változás történik, úgy kérjük, hogy a változástól történő 8 napon belül szíveskedjen azt írásban bejelenteni Társaságunk felé.
                            Bejelentését megteheti személyesen a 1211 Budapest, Szállító utca 4. szám alatti ügyfélszolgálati irodánkban, levélben ugyanezen a címen,
                            vagy e-mail-ben az <a href="mailto:ugyfelszolgalat@granitbiztosito.hu">ugyfelszolgalat@granitbiztosito.hu</a> címünkön
                          </p>
                          <p>
                            Társaságunk a központi gépjármű-nyilvántartásból értesül a rendszámváltozásról és szerződésén a módosítást elvégzi.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                        15. Hogyan változik bónuszom, ha kárt okoztam?
                      </button>
                    </h2>
                    <div id="collapseFifteen" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Amennyiben Ön kárt okozott és személygépkocsit, vagy motorkerékpárt üzemeltet 2 fokozatot csökken a bónusza a következő biztosítási időszakban,
                            tehergépkocsi, vontató, mezőgazdasági vontató, autóbusz üzemeltetése esetén 1 bónusz fokozatot.
                            A biztosító Önnek, mint a kárt okozó gépjármű üzemeltetőjének lehetőséget ad,
                            hogy a teljes kárkifizetés összegéről szóló írásbeli értesítést követő 45 napon belül a teljes kárösszeget megtérítse a biztosítónak.
                            Abban az esetben, ha Ön él ezzel a lehetőséggel, bónusza változatlan marad a továbbiakban.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                        16. Mit kell tudnom az érvényben lévő bonus-malus rendeletről?
                      </button>
                    </h2>
                    <div id="collapseSixteen" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            A 2011. június 15-én hatályba lépett új bonus-malus rendelet leglényegesebb változása,
                            hogy a biztosító a szerződést kötni kívánó üzemben tartó saját nyilatkozata alapján határozza meg a szerződés besorolását.
                            Ez a besorolás azonban előzetes és ideiglenes.
                            Az új biztosító az üzemben tartó által közölt hatósági jelzés és az előző biztosítást
                            igazoló okirat száma alapján a biztosítási időszak kezdetét követő 15. és 30. nap közötti időtartamon belül elektronikusan lekérdezi a kártörténeti
                            nyilvántartásból a besoroláshoz szükséges adatokat.
                            Amennyiben az üzemben tartó által közöltek alapján,
                            a szerződés besorolásához szükséges adatok a biztosítási időszak kezdetét követő 30. napig a kártörténeti nyilvántartásban nem beazonosíthatók,
                            erről a biztosító 15 napon belül értesítést küld az üzemben tartónak.
                            Ha a biztosítási időszak kezdetét követő 60. napig elteltéig az adatok a kártörténeti nyilvántartásban továbbra sem beazonosíthatók,
                            a biztosító a szerződés végleges besorolását A00 osztályban állapítja meg a biztosítási időszak kezdetére visszamenő hatállyal,
                            és a besorolás szerinti díjról értesítést küld az üzemben tartónak.
                            Amennyiben az üzemben tartó közölt adatai alapján a gépjármű beazonosítás a kártörténeti nyilvántartásban elvégezhető,
                            a biztosító a biztosítási időszak kezdetét követő 45 napon belül véglegesen megállapítja a szerződés besorolását a
                            biztosítási időszak kezdetére visszamenő hatállyal, egyidejűleg a besorolást bejegyzi a kártörténeti nyilvántartásba,
                            és a besorolás szerinti díjról értesítést küld az üzemben tartónak, amennyiben az, az üzemben tartó nyilatkozata szerinti besorolástól eltér.
                            Amennyiben a biztosító tudomást szerez arról, hogy az üzemben tartó a kedvezőbb besorolás érdekében akár a gépjármű beazonosítására,
                            akár a szerződés besorolására vonatkozóan valótlan adatokat közöl, melynek következtében a kártörténeti nyilvántartásban
                            történő beazonosítás lehetetlenné válik, M04 osztályba sorolja. A fenti esetkörök azoknak a kivételeknek a sorába tartoznak,
                            amelyek esetében a szerződés besorolása egy adott biztosítási időszakban változhat,
                            és ennek következményeként a biztosító a biztosítás díját az adott biztosítási időszakban megváltoztathatja. Mindezekre figyelemmel tanácsos,
                            ha az üzemben tartók – mivel az érintett szerződés vonatkozásában a bonus-malus rendszerrel
                            járó előnyök és hátrányok a szerződő üzemben tartó személyéhez fűződnek – a meglévő biztosítójuknál a besorolásukra vonatkozó adatokat egyeztetik.
                            Azon szerződések besorolására, amelyek esetében a megfigyelési időszak biztosítója által a megfigyelési időszak kártörténeti
                            adatairól kiállított kártörténeti igazolása az új biztosító részére –annak ez irányú kérésére - 2011. július 1-jét megelőzően beérkezett,
                            a bonus-malus rendszer, az abban való besorolás, illetve a kártörténeti igazolások kiadásának
                            szabályairól szóló 19/2009. (X.9.) PM rendelet besorolásra vonatkozó szabályait kell alkalmazni.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                        17. Hol tudom beszerezni a biztosításra vonatkozó legfontosabb szabályozásokat, ügyféltájékoztatókat, nyomtatványokat?
                      </button>
                    </h2>
                    <div id="collapseSeventeen" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Amennyiben Önnek szüksége van ezekre a rendeletekre, tájékoztatókra informálódás céljából, illetve nyomtatványokra ügyintézéshez,
                            úgy ezeket letöltheti honlapunkról a <a href="https://granitbiztosito.hu">granitbiztosito.hu</a> címen.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                        18. Hol tudok információt szerezni a kötelező felelősségbiztosítástól eltérő különböző biztosítási módozatú szerződésemmel
                        (Egyéni és Flotta Casco, Közúti Árufuvarozói Felelősségbiztosítás, Közúti Személyszállítói Felelősségbiztosítás, CMR biztosítás) kapcsolatosan?
                      </button>
                    </h2>
                    <div id="collapseEighteen" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Kérjük, hogy hívja bizalommal telefonos ügyfélszolgálatunkat a +36-1-666-6200 telefonszámon,
                            érdeklődjön személyesen a 1211 Budapest, Szállító utca 4. számon található ügyfélszolgálatunkon,
                            vagy kérdését tegye fel akár e-mail-ben az <a href="mailto:ugyfelszolgalat@granitbiztosito.hu">ugyfelszolgalat@granitbiztosito.hu</a> címünkön és ügyintéző kollégáink készséggel állnak rendelkezésére.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                        19. Melyik banknál vezetik számlájukat?
                      </button>
                    </h2>
                    <div id="collapseNineteen" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Számlavezető bankunk az UniCredit Bank Zrt.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                        20. Meddig kell befizetni a kötelező felelősségbiztosításom díját?
                      </button>
                    </h2>
                    <div id="collapseTwenty" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Általános Szerződési Feltételeinkben felhívjuk ügyfeleink figyelmét a biztosítási díj fizetésének módjára, és ennek elmulasztásának következményeire.
                          </p>
                          <p>
                            <span class="bold">A biztosítási díjat a biztosítási időszak kezdő napján aktuális tarifa alapján kell fizetni.</span>
                            A szerződő, ha az általa választott módon valamely okból nem tud eleget tenni a fizetési kötelezettségének,
                            a szerződés díjfizetéssel történő hatályban tartása érdekében köteles a díjat más módon az esedékesség időpontjában megfizetni.
                          </p>
                          <p>
                            A biztosító a befizetett összegből elsőként az esetlegesen felmerülő fedezetlenségi díjat könyveli le és csak ezt követően a biztosítási díjat.
                          </p>
                          <p>
                            Amennyiben Önnek aktuálisan fizetendő biztosítási díja, vagy díjelmaradása van Társaságunk felé, azt gyors és kényelmes módon, biztonságos online bankkártyás fizetéssel megteheti alábbi gombra kattintva:
                          </p>
                          <div class="pt-2">
                            <a href="https://webapps.granitbiztosito.hu/befizetes" target="_blank" class="btn rounded-pill btn-secondary">Befizetés</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
                        21. Mit tegyek, ha macOS Safari böngésző nem nyitja meg a Biztosító weboldalait?
                      </button>
                    </h2>
                    <div id="collapseTwentyOne" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            macOS alatt használt Safari böngészőnél előfordulhat, hogy tanúsítvány hibára hivatkozva nem nyitja meg a Biztosító weboldalait. A jelenség oka, hogy a Kulcskarika tárolóból hiányzik a megfelelő főtanúsítvány.
                          </p>
                          <p>
                            Kérjük, a pótlásához a főtanúsítványt töltse le a <a href="https://netlock.hu/tanusitvanykiadok/" target="_blank">https://netlock.hu/tanusitvanykiadok/</a> oldalról, majd importálja a Kulcskarika program Fájl / Elemek importálása… menüpontban.
                          </p>
                          <p>
                            A Biztosító weboldala (<a href="https://granitbiztosito.hu">https://granitbiztosito.hu</a>) a Netlock Kft. által kiadott sha256RSA aláírási algoritmust használ.
                            A Netlock Kft. tanúsítványait a modern böngésző programok felismerik és alkalmazzák.
                            Abban az esetben ha az Ön böngésző programja tanúsítvány hibára hivatkozva nem tudja megnyitni a weboldalunkat, javasoljuk telepítse a hivatkozott tanúsítványt,
                            vagy frissítse le a böngésző programját a legfrissebb stabil verzióra.
                          </p>
                          <p>
                            Amennyiben Önnek a fent felsoroltaktól eltérő kérdése lenne, illetve az Önben felmerült bármely kérdésére itt nem talált megnyugtató választ,
                            úgy kérjük, hogy forduljon bizalommal telefonos ügyfélszolgálatunkhoz a +36-1-666-6200 telefonszámon, érdeklődjön személyesen a 1211 Budapest, Szállító utca 4. számon található ügyfélszolgálatunkon,
                            vagy kérdését tegye fel e-mail-ben az <a href="mailto:ugyfelszolgalat@granitbiztosito.hu">ugyfelszolgalat@granitbiztosito.hu</a> címünkön és munkatársaink válaszolnak feltett kérdéseire.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
                        22. Hogyan tudom letölteni az e-mailen kapott dokumentumot?
                      </button>
                    </h2>
                    <div id="collapseTwentyTwo" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            A kiküldött dokumentum neve egy link. A linkre kattintást követően a program átirányítja Önt a weboldalunkra, ahol be kell írni a szerződésazonosítóját,
                            majd kiválasztani a szerződő típusát (nő/férfi vagy egyéni vállalkozó nő/férfi/jogi személy), majd megadni a születési dátumát, vagy adószámát.
                            Ezt követően a letöltés gombra kattintva tekintheti meg a dokumentumot
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                        23. Hogyan tudom felmondani a kötelező gépjármű felelősségbiztosításomat?
                      </button>
                    </h2>
                    <div id="collapseTwentyThree" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            A kötelező gépjármű-felelősségbiztosításról szóló 2009. évi LXII. törvény alapján 7. § (1) A biztosítási szerződést a biztosítási időszak utolsó napjára (biztosítási évfordulóra),
                            azt legalább 30 nappal megelőzően a, a biztosító írásban, b, a szerződő üzembentartó írásban vagy - a felek megállapodása alapján - elektronikus úton indoklás nélkül felmondhatja.
                            (1a) * A felmondás akkor hatályosul, ha az a másik félhez határidőben megérkezik.
                            Lehetőség van sima e-mailben történő felmondásra, ha a felmondás a szerződésen rögzített kommunikációs e-mail címről érkezik.
                            Nem e-mailkommunikációval rendelkező szerződés esetében, az aláírásával ellátott felmondást az e-mailhez csatoltan áll módunkban elfogadni.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                        24. Hogyan tudom felmondani az otthonbiztosítási szerződésemet?
                      </button>
                    </h2>
                    <div id="collapseTwentyFour" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            A szerződési feltétekben foglaltak szerint a határozatlan időtartamra kötött biztosítási szerződést a felek írásban, a biztosítási időszak végére, 30 napos felmondási idővel felmondhatják.
                          </p>
                          <p>
                            A szerződés ügyfél általi, e-mailben közölt felmondása érvényességének feltétele, hogy az e-mail mellékleteként a szerződő a felmondást tartalmazó írásbeli nyilatkozat szkennelt másolatát is megküldje a Biztosító részére.
                          </p>
                          <p>
                            MFO termékünk esetében, a szerződés naptári negyed év végére történő felmondása esetén, amennyiben a szerződő a naptári negyedév végétől számított 30 napon belül mondja fel a szerződést, úgy az a következő naptári negyedév végére szűnik meg.
                          </p>
                          <p>
                            Tájékoztatjuk, hogy a vagyonbiztosítási szerződésekre alkalmazandó Korm. rendelet értelmében – a lakásbiztosítási szerződésének évfordulóján túl további egy alkalommal – minden év márciusában lehetősége van arra, hogy költségmentesen új biztosításra váltson.<br />
                            <span style="font-size:10px">
                              1 a vagyonbiztosítási szerződésekre alkalmazandó egyes veszélyhelyzeti szabályokról szóló 25/2023. (II. 1.) Korm. rendelet 5. §. (1) bekezdés<br />
                              1 <a href="https://minositettotthonbiztositas.mnb.hu/mfo/kalkulator" target="_blank">https://minositettotthonbiztositas.mnb.hu/mfo/kalkulator</a>
                            </span>
                          </p>
                          <p>
                            Felmondását közölheti:<br />
                            •	Postai úton (1443. Budapest, Pf. 180.)<br />
                            •	Személyesen (1211. Budapest, Szállító u. 4. - Nyitva tartás a <a href="https://granitbiztosito.hu">granitbiztosito.hu</a> oldalon)<br />
                            •	E-mailben (<a href="mailto:ugyfelszolgalat@granitbiztosito.hu">ugyfelszolgalat@granitbiztosito.hu</a>) csak papíron aláírt és az emailhez csatolt szkennelt dokumentum formájában áll módunkban elfogadni a felmondását. Fokozott biztonságú elektronikus aláírással vagy a magyar állam által biztosított e-dokumentum hitelesítéssel („AVDH”) ellátott dokumentum formájában (<a href="https://niszavdh.gov.hu/index" target="_blank">https://niszavdh.gov.hu/index</a>)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                        25. Hogyan tudom megnézni szerződésemet az Ügyfélportálon?
                      </button>
                    </h2>
                    <div id="collapseTwentyFive" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            Aktív felhasználónévvel és jelszóval lehet bejelentkezni. A felhasználónév egyedi kell legyen, a jelszónak minimum 12 karakterből kell állnia és tartalmaznia kell legalább egy nagybetűt,
                            egy kis betűt és egy számot. Ékezetes karakter nem megengedett.
                          </p>
                          <p>
                            Ötszöri sikertelen bejelentkezés után a rendszer a felhasználót kizárja a használatból. Ebben az esetben az elfelejtett jelszó funkciót kell igénybe venni.
                          </p>
                          <p>
                            Elfelejtett jelszavát? felirat segítségével az elfelejtett jelszó felületre navigálni, vagy a Regisztrálok gombbal a portál regisztrációs felületre eljutni.
                          </p>
                          <p>
                            Új jelszót az Ügyfélportálhoz megadott felhasználónév ismeretében lehet kérni. Amennyiben létezik a felhasználónév, a hozzá megadott email címre értesítés érkezik.
                          </p>
                          <p>
                            Az email tartalmaz egy egyedi linket, amely segítségével vissza lehet jutni az elfelejtett jelszó oldalra és új jelszót megadni a jelszó házirendnek megfelelően. Sikeres módosításról üzenetablak tájékoztat.
                          </p>
                          <p>
                            A regisztráció 3 lépésben történik.
                          </p>
                          <p>
                            1.	Először érvényes szerződés adatokat kell megadni, majd a Tovább a profil adatok megadásához gombbal tovább lépni a második lépésre.
                          </p>
                          <p>
                            2.	Itt szükség lesz egy legalább 6 karakter hosszú felhasználónévre, email címre, majd jelszóra, aminek minimum 12 karakterből kell állnia és tartalmaznia kell legalább egy nagybetűt, egy kis betűt és egy számot.
                            Ékezetes karakter nem megengedett. Egy email cím több portál regisztráció során is használható, felhasználónév viszont egyedi kell legyen.
                            A Regisztrálok az ügyfélportálra gombbal lehet átlépni a véglegesítésre
                          </p>
                          <p>
                            3.	A regisztráció véglegesítésére egy link szolgál, amit email-en küld ki a portál a regisztráció során megadott címre. A linkre kattintva a regisztráció végleges, be lehet jelentkezni a portálra.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item bg-light">
                    <h2 class="accordion-header">
                      <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
                        26. Hogyan tudok időpontot foglalni a személyes ügyfélszolgálatra?
                      </button>
                    </h2>
                    <div id="collapseTwentySix" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
                      <div class="accordion-body accordion-shadow-lg text-light">
                        <div class="small">
                          <p>
                            A weboldalunkon, alábbi linken kezdeményezhető időpontfoglalás személyes ügyfélszolgálatunkra:<br />
                          </p>
                          <div class="pt-2 pb-3">
                            <a href="https://webapps.granitbiztosito.hu/idopont-uszi" target="_blank" class="btn rounded-pill btn-secondary">Időpont foglalás</a>
                          </div>
                          <p>
                            Amennyiben Önnek a fent felsoroltaktól eltérő kérdése lenne, illetve az Önben felmerült bármely kérdésére itt nem talált megnyugtató választ,
                            úgy kérjük, hogy forduljon bizalommal telefonos ügyfélszolgálatunkhoz a +36-1-666-6200 telefonszámon, érdeklődjön személyesen a 1211 Budapest, Szállító utca 4. számon található ügyfélszolgálatunkon,
                            vagy kérdését tegye fel e-mail-ben az <a href="mailto:ugyfelszolgalat@granitbiztosito.hu">ugyfelszolgalat@granitbiztosito.hu</a> címen és munkatársaink készséggel válaszolnak feltett kérdéseire.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
