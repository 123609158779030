<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row justify-content-center pt-4">
              <div class="col-lg-12">
                <h1 class="bold">IMPRESSZUM</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <table class="table table-striped table-borderless">
                  <tbody>
                    <tr>
                      <td class="col-lg-9">Cégnév</td>
                      <td class="col-lg-3">Gránit Biztosító</td>
                    </tr>
                    <tr>
                      <td>Székhely</td>
                      <td>H-1211 Budapest, Szállító utca 4.</td>
                    </tr>
                    <tr>
                      <td>Telefon</td>
                      <td>+36 1 666 6200</td>
                    </tr>                    
                    <tr>
                      <td>E-mail</td>
                      <td><img class="lev" src="../../../../assets/images/email/uszi.png" alt="Ügyfélszolgálat" /></td>
                    </tr>
                    <tr>
                      <td>Web</td>
                      <td>
                        <a href="https://www.granitbiztosito.hu" target="_blank">https://www.granitbiztosito.hu</a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cégbíróság</td>
                      <td>Fővárosi Törvényszék Cégbírósága</td>
                    </tr>
                    <tr>
                      <td>Cégjegyzékszám</td>
                      <td>01-10-046203</td>
                    </tr>
                    <tr>
                      <td>Engedélyek</td>
                      <td>
                        H-EN-II-86/2016,
                        EN-II-125/2011,
                        EN-II-35/2011,
                        E-II-396/2008
                      </td>
                    </tr>
                    <tr>
                      <td>Felügyeleti hatóság</td>
                      <td>Magyar Nemzeti Bank</td>
                    </tr>
                    <tr>
                      <td>Székhely</td>
                      <td>1013 Budapest, Krisztina körút 55.</td>
                    </tr>
                    <tr>
                      <td>Levélcím</td>
                      <td>Magyar Nemzeti Bank, 1850 Budapest</td>
                    </tr>
                    <tr>
                      <td>Telefon</td>
                      <td>+36 1 428 2600</td>
                    </tr>
                    <tr>
                      <td>E-mail</td>
                      <td>
                        <a href="mailto:info@mnb.hu" target="_blank">info@mnb.hu</a>
                      </td>
                    </tr>
                    <tr>
                      <td>Web</td>
                      <td>
                        <a href="http://www.mnb.hu/felugyelet" target="_blank">http://www.mnb.hu/felugyelet</a>
                      </td>
                    </tr>
                    <tr>
                      <td>Ügyfélszolgálat</td>
                      <td>1122 Budapest, Krisztina krt. 6.</td>
                    </tr>
                    <tr>
                      <td>Telefon</td>
                      <td>+36 80 203 776</td>
                    </tr>
                    <tr>
                      <td>E-mail</td>
                      <td>
                        <a href="mailto:ugyfelszolgalat@mnb.hu" target="_blank">ugyfelszolgalat@mnb.hu</a>
                      </td>
                    </tr>
                    <tr>
                      <td>Web</td>
                      <td>
                        <a href="http://mnb.hu/fogyasztovedelem" target="_blank">http://mnb.hu/fogyasztovedelem</a>
                      </td>
                    </tr>
                    <tr>
                      <td>Adószám</td>
                      <td>14489765-2-44</td>
                    </tr>
                    <tr>
                      <td>Kamara megnevezése</td>
                      <td>Budapesti Kereskedelmi és Iparkamara</td>
                    </tr>
                    <tr>
                      <td>Nyilvántartási szám:	</td>
                      <td>BU14489765</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  A Biztosító tevékenységére vonatkozó főbb szabályok:
                </p>
                <ul class="ulstyle">
                  <li>A biztosítási tevékenységről szóló 2014. évi LXXXVIII. törvény</li>
                  <li>A Polgári Törvénykönyvről szóló 2013. évi V. törvény</li>
                  <li>A Magyar Nemzeti Bankról szóló 2013. évi CXXXIX. törvény</li>
                  <li>A kötelező gépjármű-felelősségbiztosításról szóló 2009. évi LXII. törvény</li>
                  <li>A biztosítási adóról szóló 2012. évi CII. törvény</li>
                  <li>A fogyasztókkal szembeni tisztességtelen kereskedelmi gyakorlat tilalmáról szóló 2008. évi XLVII. törvény</li>
                  <li>A gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény</li>
                  <li>Az Európai Parlament és Tanács 2009/138/EK irányelve (Szolvencia II)</li>
                  <li>A Bizottság (EU) 2015/35 felhatalmazáson alapuló rendelete</li>
                </ul>
                <p></p>
                <p>
                  A Biztosító tevékenységre vonatkozó magyar és európai szintű jogszabályok megismeréséhez keresse fel felügyeleti hatóságunk
                  <a href="https://www.mnb.hu/felugyelet/szabalyozas/jogszabalyok" target="_blank">https://www.mnb.hu/felugyelet/szabalyozas/jogszabalyok</a>
                  honlapját.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

