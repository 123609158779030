<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">

        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">PANORÁMA CSALÁDI OTTHONBIZTOSÍTÁS</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <p>
                Élete munkájával megvásárolt, felépített lakás kellő biztonságban van? Minden váratlan
                eseményre biztosítva van ingatlana és ingóságai? Értéktárgyai megfelelő összegre
                vannak biztosítva?
              </p>
              <div>
                A termék már nem köthető.
              </div>
              <div class="clr"></div>
              <ul>
                <li>
                  <a class="download" href="{{weboldalDokumentumok}}otthon_szolgaltatasi_tabla_20171001.pdf">
                    Biztosítási csomagjaink
                    szolgáltatásait és a térítési összeghatárokat itt tekintheti meg!
                  </a>
                </li>
              </ul>
              <p>
                Társaságunk széleskörű fedezetet nyújt tűz, vihar, árvíz, jégverés, villám, felhőszakadás,
                csőtörés, üvegtörés és betöréses lopás károkra és még további 25 káreseményre.
              </p>
              <p>
                Kiegészítő biztosításokat kínálunk a baleseti, ebtartói felelősségi és autókulcs
                elvesztéséből, ellopásából eredő károkra.
              </p>
              <p>
                Gránit Gyorsszolgálat 24 szolgáltatásunk az év minden napján, éjjel-nappal segítséget
                nyújt a váratlan helyzetekre.
              </p>
              <p>
                24 órás segítségnyújtó szolgálatunk a következő számon hívható: +36 1 465 3634
              </p>
              <p>
                A Gránit márkanév garantálja a megbízható minőséget az Ön számára! Ránk biztosan
                számíthat!
              </p>
              <h2 class="subtitle">
                <b>Vonatkozó jogszabályok</b>
              </h2>

              <ul class="ulstyle">
                <li>2014. évi LXXXVIII. törvény a biztosítási tevékenységről</li>
                <li>2013. évi V. törvény a Polgári Törvénykönyvről</li>
              </ul>

              <h2 class="subtitle"><strong>A biztosítási módozathoz tartozó számlaszám</strong></h2>
              <table style="width: 100%;">
                <tr>
                  <td><span>10918001-00000068-64440075</span></td>
                  <td>IBAN: <span>HU46 1091 8001 0000 0068 6444 0075</span></td>
                </tr>
              </table>

              <h2 class="subtitle"><b>Letölthető dokumentumok</b></h2>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_lakas_garzon.pdf">Panoráma Családi Otthonbiztosítás Biztosítási termékismertető - Garzon csomag</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_lakas_centrum.pdf">Panoráma Családi Otthonbiztosítás Biztosítási termékismertető - Centrum csomag</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_lakas_trend.pdf">Panoráma Családi Otthonbiztosítás Biztosítási termékismertető - Trend csomag</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20240215.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2024.02.15-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a></li>

                <li style="margin-top: 20px"><a class="download" target="_blank" href="{{weboldalDokumentumok}}panorama_kiegeszito_balesetbiztositasi_esemeny_adatkezeles_es_karbejelento.pdf">Kárbejelentő nyomtatvány Panoráma Családi Otthonbiztosítás alapján bejelentett kiegészítő balesetbiztosítási eseményhez és adatkezelési tájékoztató Panoráma Családi Otthonbiztosítás alapján bejelentett kiegészítő balesetbiztosítási eseménnyel összefüggő adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}panorama_otthonbiztositasi_esemeny_adatkezeles_es_karbejelento.pdf">Kárbejelentő nyomtatvány Panoráma Családi Otthonbiztosítás alapján bejelentett biztosítási eseményhez és adatkezelési tájékoztató Panoráma Családi Otthonbiztosítás alapján bejelentett biztosítási eseménnyel összefüggő adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}panorama_nyilatkozat_egeszsegugyi_adatok_kezelesehez_balesetbiztositasi_esemeny.pdf">Nyilatkozat Panoráma Családi Otthonbiztosítás kiegészítő balesetbiztosítási eseménnyel összefüggő egészségügyi adat kezeléséhez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}panorama_nyilatkozat_szemelyes_adatok_kezelesenek_hozzajarulas_visszavonasa.pdf">Panoráma Családi Otthonbiztosítási káresemény bejelentőjének nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a></li>

                <li style="margin-top: 20px"><a class="download" target="_blank" href="{{weboldalDokumentumok}}csop_besz_megbizas_20120921.pdf">Felhatalmazás csoportos beszedési megbízás teljesítésére</a></li>
              </ul>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}mabisz_tuzvedelmi_ajanlat.pdf">A Magyar Elektrotechnikai Egyesület, a Magyar Biztosítók Szövetsége és az Országos Katasztrófavédelmi Főigazgatóság ajánlása a villamos eredetű tűzkárok megelőzéséhez és csökkentéséhez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}betoresvedelmi_tajekoztato.pdf">MABISZ Betörésvédelmi tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}mabisz_lakasbiztositasi_utmutato_20140315.pdf">MABISZ Lakásbiztosítási útmutató (Hatályos: 2014.03.15-től, az új Ptk. alapján  megkötött szerződésekre)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}mabisz_lakasbiztositasi_utmutato.pdf">MABISZ Lakásbiztosítási útmutató (Hatályos: 2014.03.14-ig megkötött lakásbiztosítási szerződésekre)</a></li>

                <li><a class="download" href="{{weboldalDokumentumok}}egyedi_zalogkotelezetti_nyilatkozat.pdf" target="_blank">Egyedi Zálogkötelezetti nyilatkozat</a></li>
                <li><a class="download" href="{{weboldalDokumentumok}}egyedi_zalogkotelezetti_nyilatkozat_ceges.pdf" target="_blank">Egyedi Zálogkötelezetti nyilatkozat - céges</a></li>
              </ul>
              <h2 class="subtitle"><b>Kárbejelentési dokumentumok</b></h2>
                  <ul>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}panorama_karbejelentolap.pdf">Kárbejelentő nyomtatvány Panoráma Családi Otthonbiztosítási káreseményhez</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakvelemeny.pdf">Szakvélemény</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}felelosseg_elismero_nyilatkozat.pdf">Felelősség elismerő nyilatkozat</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ingosagi_karbejelento.pdf">Ingósági kárbejelentő</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_hatosagi_eljarasrol.pdf">Nyilatkozat hatósági eljárásról</a></li>
                    <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
                  </ul>
              <h2 class="subtitle"><b>Archívum</b></h2>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20230615.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2023.06.15-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20211220.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2021.12.20-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20210501.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2021.05.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20200701.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2020.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20190426.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2019.04.26-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20190101.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2019.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20181105.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.11.05-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20180808.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2018.08.08-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20180701.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2018.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20180525.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2018.05.25-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20180223.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2018.02.23-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20180101.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2018.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20171001.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2017.10.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20170601.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2017.06.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20170401.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2017.04.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20170101.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2017.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20160901.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2016.09.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20160701.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2016.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20160101.pdf">Panoráma Családi Otthonbiztosítás Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek (2016.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20150801.pdf">Panoráma Családi Otthonbiztosítás feltételcsomag 2015.08.01-től hatályos</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_szolgaltatasi_tabla_20150801.pdf">Panoráma Családi Otthonbiztosítás szolgáltatási tábla 2015.08.01-től hatályos</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20150101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2015.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20150101.pdf">Panoráma Családi Otthonbiztosítás feltételcsomag 2015.01.01-től hatályos</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20140315.pdf">Panoráma Családi Otthonbiztosítás feltételcsomag 2014.03.15-től hatályos</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20131001.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2013.10.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20130901.pdf">Panoráma Családi Otthonbiztosítás 2013.09.01-től hatályos</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_biztositasi_feltetelek_20120921.pdf">Panoráma Családi Otthonbiztosítás 2012.09.21-től hatályos</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130701.pdf">Ügyféltájékoztató 2013.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130301.pdf">Ügyféltájékoztató 2013.03.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthonbiztositasi_feltetelek.pdf">Panoráma Családi Otthonbiztosítás 2012. január 20-ától</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_adatkez_taj_20120120.pdf">Ügyfél- és Adatkezelési tájékoztató 2012. január 20-ától</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20120101.pdf">Ügyféltájékoztató 2012. január 1-jétől</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}otthon_uftk213_20110901.pdf">Panoráma Családi Otthonbiztosítás 2011. szeptember 1-jétől</a></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
