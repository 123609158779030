import { Component, OnInit } from '@angular/core';
import { UtilityService } from '@granit/common-library';

@Component({
  selector: 'fizetokepesseg',
  templateUrl: './fizetokepesseg.component.html',
  styleUrls: ['./fizetokepesseg.component.scss']
})
export class FizetokepessegComponent implements OnInit {

  constructor(private utilityService: UtilityService) { }

  weboldalDokumentumok: string = "";

  ngOnInit(): void {
    this.linkek();
  }

  async linkek() {

    Promise.all(
      [this.utilityService.appsettingsKeyErtekLekerese({ key: 'WeboldalDokumentumok' })]).then(
        data => {
          this.weboldalDokumentumok = data[0];
        }
      );
  }

}
