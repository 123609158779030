<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">KÉRDÉSEK ÉS VÁLASZOK INTERNETES KÁRTYÁS FIZETÉSRŐL</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">              
              <h2><strong>KÁRTYAELFOGADÁS</strong></h2>
              <p>&nbsp;</p>
              <p class="question"><strong>Milyen típusú kártyákkal lehet fizetni?</strong></p>
              <p>A VISA és a Mastercard dombornyomott kártyáival, ill. egyes VISA Electron, V Pay kártyákkal. A VISA Electron kártyák interneten történő használatának lehetősége a kártyát kibocsátó banktól függ. A CIB által kibocsátott VISA Electron típusú bankkártya használható interneten történő vásárlásra.</p>
              <p class="question"><strong>Mely bankok kártyái alkalmasak internetes fizetésre?</strong></p>
              <p>Minden olyan VISA és Mastercard/Maestro kártyával, mely internetes fizetésre a kártyakibocsátó bank által engedélyeztetve lettek, valamint a kifejezetten internetes használatra hivatott webkártyák.</p>
              <p class="question"><strong>Lehet-e vásárlókártyákkal fizetni?</strong></p>
              <p>Hűségpontokat tartalmazó, kereskedők/szolgáltatók által kibocsátott pontgyűjtő kártyákkal nem lehet interneten fizetni.</p>
              <p class="question"><strong>Lehet-e co-branded kártyákkal fizetni?</strong></p>
              <p>Bármilyen olyan co-branded kártyával lehetséges fizetni, mely internetes fizetésre alkalmas MasterCard vagy VISA alapú kártya.</p>
              <h2><strong>FIZETÉS FOLYAMATA</strong></h2>
              <p></p>
              <p class="question"><strong>Hogyan működik az online fizetés banki háttérfolyamata?</strong></p>
              <p>A vásárló a kereskedő/szolgáltató internetes oldalán a bankkártyás fizetési mód választását követően a fizetést kezdeményezi, melynek eredményeként átkerül a Bank biztonságos kommunikációs csatornával ellátott fizetőoldalára. A fizetéshez szükséges megadni kártyaszámát, lejárati idejét, és a kártya hátoldalának aláíráscsíkján található 3 jegyű érvényesítési kódot. A tranzakciót Ön indítja el, ettől kezdve a kártya valós idejű engedélyezésen megy keresztül, melynek keretében a kártyaadatok eredetisége-, fedezet-, vásárlási limit kerül ellenőrzésre. Amennyiben a tranzakció folytatásához minden adat megfelel, a fizetendő összeget számlavezető (kártyakibocsátó) bankja zárolja a kártyáján. Az összeg számlán történő terhelése (levonása) számlavezető banktól függően néhány napon belül következik be.</p>
              <p class="question"><strong>Miben különbözik az internetes kártyás vásárlás a hagyományostól?</strong></p>
              <p>Megkülönböztetünk kártya jelenlétével történő (Card Present) és kártya jelenléte nélküli (Card not Present) tranzakciókat. A Card Present tranzakció POS terminál eszköz segítségével történik. A kártya lehúzását és a PIN kód beütését követően a terminál kapcsolatba lép a kártyabirtokos bankjával, az engedélyező központon keresztül, és a kártya típusától, illetve a kártya kibocsátójától függően a VISA vagy MasterCard hálózaton keresztül. Itt megtörténik az érvényesség és fedezetvizsgálat (authorizáció). Az előbbi útvonalon visszafelé haladva a POS terminál (illetve a kereskedő) megkapja a jóváhagyást vagy elutasítást. A vásárló aláírja a bizonylatot. A Card not Present olyan tranzakció, melynek lebonyolításakor a bankkártya fizikailag nincs jelen. Ide tartoznak a levélben, telefonon, illetve az elektronikus úton (internet) lebonyolított tranzakciók, amelyek esetében a vásárló (kártyabirtokos) a tranzakciót biztonságos (256 bites titkosítású) fizetőoldalon bekért kártyaadatok megadásával indítja. A sikeres tranzakcióról Ön kap ún. engedélyszámot, mely megegyezik a papír alapú bizonylaton található számmal.</p>
              <p class="question"><strong>Mit jelent a foglalás?</strong></p>
              <p>A tranzakciót a bank tudomására jutásakor azonnal foglalás (zárolás) követi, hiszen a tényleges terheléshez előbb a hivatalos adatoknak be kell érkezniük, mely néhány napot igénybe vesz és az alatt a vásárolt összeg újra elkölthető lenne. Ezért a foglalással a levásárolt vagy felvett pénzt elkülönítik, foglalás alá teszik. A foglalt összeg hozzátartozik a számlaegyenleghez, azaz jár rá kamat, de még egyszer nem költhető el. A foglalás biztosítja azon tranzakciók visszautasítását, melyre már nincs fedezet, noha a számlaegyenleg erre elvben még lehetőséget nyújtana.</p>
              <h2><strong>SIKERTELEN FIZETÉSEK ÉS TEENDŐK</strong></h2>
              <p>&nbsp;</p>
              <p class="question"><strong>Milyen esetben lehet sikertelen egy tranzakció?</strong></p>
              <p>Általában a kártyát kibocsátó bank (tehát ahol az ügyfél a kártyát kapta) által el nem fogadott fizetési megbízás; de bankkártya használat esetében ez lehet olyan okból is, hogy távközlési vagy informatikai hiba miatt az engedélykérés nem jut el a kártyát kibocsátó bankhoz.</p>
              <p class="question"><strong>Kártya jellegű hiba</strong></p>
              <ul class="ulstyle">
                <li><p>A kártya nem alkalmas internetes fizetésre.</p></li>
                <li><p>A kártya internetes használata számlavezető bank által tiltott.</p></li>
                <li><p>A kártyahasználat tiltott.</p></li>
                <li><p>A kártyaadatok (kártyaszám, lejárat, aláíráscsíkon szereplő kód) hibásan lettek megadva.</p></li>
                <li><p>A kártya lejárt.</p></li>
              </ul>
              <p class="question"><strong>Számla jellegű hiba</strong></p>
              <ul class="ulstyle">
                <li><p>Nincs fedezet a tranzakció végrehajtásához.</p></li>
                <li><p>A tranzakció összege meghaladja a kártya vásárlási limitét.</p></li>
              </ul>
              <p class="question"><strong>Kapcsolati jellegű hiba</strong></p>
              <ul class="ulstyle">
                <li><p>A tranzakció során valószínűleg megszakadt a vonal. Kérjük, próbálja meg újra.</p></li>
                <li><p>A tranzakció időtúllépés miatt sikertelen volt. Kérjük, próbálja meg újra.</p></li>
              </ul>
              <p class="question"><strong>Technikai jellegű hiba</strong></p>
              <ul class="ulstyle">
                <li><p>Amennyiben a fizetőoldalról nem tért vissza a kereskedő/szolgáltató oldalára, a tranzakció sikertelen.</p></li>
                <li><p>Amennyiben a fizetőoldalról visszatért, de a böngésző "back", "reload" illetve "refresh" segítségével visszatér a fizetőoldalra, tranzakcióját a rendszer biztonsági okokból automatikusan visszautasítja.</p></li>
              </ul>
              <p class="question"><strong>Mi a teendő a fizetési procedúra sikertelensége esetén?</strong></p>
              <p>A tranzakcióról minden esetben generálódik egy tranzakcióazonosító, melyet javaslunk feljegyezni. Amennyiben a fizetési kísérlet során tranzakció banki oldalról visszautasításra kerül, kérjük vegye fel a kapcsolatot számlavezető bankjával.</p>
              <p class="question"><strong>Miért a számlavezető bankkal kell felvenni a kapcsolatot a fizetés sikertelensége esetén?</strong></p>
              <p>A kártyaellenőrzés során a számlavezető (kártyakibocsátó) bank értesíti az összeget beszedő kereskedő (elfogadó) bankjának, hogy a tranzakció elvégezhető-e. Más bank ügyfelének az elfogadó bank nem adhat ki bizalmas információkat, arra csak a kártyabirtokost azonosító banknak van joga.</p>
              <p class="question"><strong>Mit jelent az, ha bankomtól azonban SMS kaptam az összeg foglalásáról/zárolásáról, azonban a kereskedő/szolgáltató azt jelzi, hogy sikertelen volt a fizetés?</strong></p>
              <p>Ez a jelenség olyan esetben fordulhat elő, ha a kártya ellenőrzése megtörtént a fizetőoldalon, azonban Ön nem tért vissza kereskedő/szolgáltató internetes oldalára. A tranzakció ebben az esetben befejezetlennek, így automatikusan sikertelennek minősül. Ilyen esetben az összeg nem kerül terhelésre kártyáján, a foglalás feloldásra kerül.</p>
              <h2><strong>BIZTONSÁG</strong></h2>
              <p>&nbsp;</p>
              <p class="question"><strong>Mit jelent a VeriSign és a 256 bites titkosítású TLS kommunikációs csatorna? </strong></p>
              <p>A TLS, a Transport Layer Security elfogadott titkosítási eljárás rövidítése. Bankunk rendelkezik egy 256 bites titkosító kulccsal, amely a kommunikációs csatornát védi. A VeriSign nevű cég teszi lehetővé a CIB Bank-nak a 256 bites kulcs használatát, amely segítségével biztosítjuk a TLS alapú titkosítást. Jelenleg a világ elektronikus kereskedelmének 90%-ában ezt a titkosítási módot alkalmazzák. A vásárló által használt böngésző program a TLS segítségével a kártyabirtokos adatait az elküldés előtt titkosítja, így azok kódolt formában jutnak el a CIB Bankhoz, ezáltal illetéktelen személyek számára nem értelmezhetőek.</p>
              <p class="question"><strong>A fizetés után figyelmeztetett a böngészőm, hogy elhagyom a biztonsági zónát. A fizetésem biztonsága így garantált?</strong></p>
              <p>Teljes mértékben igen. A fizetés folyamata 256-bites titkosított kommunikációs csatornán folyik, így teljesen biztonságos. A tranzakciót követően Ön a kereskedő honlapjára jut vissza, amennyiben a kereskedő oldala nem titkosított, a böngészője figyelmezteti, hogy a titkosított csatornát elhagyta. Ez nem jelent veszélyt a fizetés biztonságát illetően.</p>
              <p class="question"><strong>Mit jelent a CVC2/CVV2 kód?</strong></p>
              <p>A MasterCard esetében az ún Card Verification Code, a Visa esetében az ún. Card Verification Value egy olyan, a bankkártya mágnescsíkján kódolt numerikus érték, melynek segítségével megállapítható egy kártya valódisága. Az ún. CVC2 kódot, mely az Mastercard/Maestro kártyák hátoldalán található számsor utolsó három számjegyében szerepel, az internetes vásárlások során kell megadni.</p>
              <p class="question"><strong>Mit jelent a Verified by Visa?</strong></p>
              <p>A Verified by Visa a Visa kártyabirtokosok a kártyát kibocsátó banknál beállított olyan egyszeri kódon vagy biometrikus azonosításon (arcfelismerésen, ujjlenyomaton, illetve más emberi tulajdonságon) alapuló biztonsági ellenőrzése, mely segítségével azonosíthatják magukat internetes vásárlás esetén, és amely védelmet nyújt a Visa kártyák jogosulatlan használata ellen. A CIB Bank elfogadja a Verified by Visa rendszer keretein belül kibocsátott kártyákat.</p>
              <p class="question"><strong>Mit jelent a Mastercard Identity Check (ID Check)?</strong></p>
              <p>A Mastercard ID Check a Mastercard/Maestro kártyabirtokosok a kártyát kibocsátó banknál beállított olyan egyszeri kódon vagy biometrikus azonosításon (arcfelismerésen, ujjlenyomaton, illetve más emberi tulajdonságon) alapuló biztonsági ellenőrzése, mely segítségével azonosíthatják magukat internetes vásárlás esetén, és amely védelmet nyújt a Mastercard/Maestro kártyák jogosulatlan használata ellen. A CIB Bank elfogadja a Mastercard ID Check rendszer keretein belül kibocsátott kártyákat.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
