<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 pt-4">
          <div class="container pt-5">
            <div class="row justify-content-center pt-4">
              <div class="col-lg-12">
                <h1 class="bold" style="text-transform:uppercase;">Ügyfél elégedettségmérés</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-info py-4 article-section mt-5">
          <form [formGroup]="formGroup" class="form-group">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div class="d-flex flex-column card shadow-lg rounded-4">
                    <span class="mb-4" style="font-size:16px;">
                      Az Ön véleménye fontos számunkra, ezért kérjük, hogy válaszával segítse az ügyfélkiszolgálás színvonalának javítását.<br />A válaszadás önkéntes, névtelen és a kapott adatokat csak az intézményi minőségfejlesztés során használjuk fel.
                    </span>
                    <div class="d-flex justify-content-center svgContainer">
                      <svg width="128" height="128" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" (click)="onErtekValasztasClick(1)" [class.kivalasztva]="formGroup.controls['ertekeles'].value == 1">
                        <g id="g_csillag_belso">
                          <path d="M18.749 8.026a.542.542 0 0 0-.431-.363l-5.319-.813-2.384-5.078c-.178-.379-.8-.379-.979 0L7.253 6.851l-5.319.813a.54.54 0 0 0-.305.912l3.864 3.961-.913 5.599a.542.542 0 0 0 .795.56l4.751-2.626 4.751 2.626a.544.544 0 0 0 .574-.031.54.54 0 0 0 .222-.529l-.913-5.599 3.864-3.961a.546.546 0 0 0 .125-.55z" style="paint-order: fill; fill: rgb(255, 255, 255);"></path>
                        </g>
                        <circle cx="17.5" cy="17.5" r="5.85" style="fill: rgb(255, 255, 255); "></circle>
                        <g id="g_szam">
                          <path d="M18.25 21a.75.75 0 0 1-.75-.75V15.5h-.25a.75.75 0 0 1 0-1.5h1a.75.75 0 0 1 .75.75v5.5a.75.75 0 0 1-.75.75z" style="fill: rgb(33, 37, 41);"></path>
                        </g>
                        <path d="M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" style="fill: rgb(33, 37, 41);"></path>
                        <path d="M4.283 20a.75.75 0 0 1-.741-.871l1.031-6.32L.213 8.34a.749.749 0 0 1 .424-1.265l5.996-.917L9.321.43c.246-.526 1.111-.526 1.357 0l2.688 5.728 5.996.917a.749.749 0 0 1 .424 1.265L18.459 9.7a.75.75 0 0 1-1.075-1.048l.309-.315-4.949-.756a.75.75 0 0 1-.565-.422L10 2.515 7.82 7.159a.75.75 0 0 1-.565.422l-4.949.756 3.606 3.695c.166.17.241.41.203.645l-.843 5.169 3.364-1.86a.75.75 0 0 1 .727 1.312l-4.717 2.607a.735.735 0 0 1-.363.095z" style="fill: rgb(33, 37, 41);"></path>
                      </svg>

                      <svg width="128" height="128" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" (click)="onErtekValasztasClick(2)" [class.kivalasztva]="formGroup.controls['ertekeles'].value == 2">
                        <defs></defs>
                        <g id="g_csillag_belso">
                          <path d="M18.749 8.026a.542.542 0 0 0-.431-.363l-5.319-.813-2.384-5.078c-.178-.379-.8-.379-.979 0L7.253 6.851l-5.319.813a.54.54 0 0 0-.305.912l3.864 3.961-.913 5.599a.542.542 0 0 0 .795.56l4.751-2.626 4.751 2.626a.544.544 0 0 0 .574-.031.54.54 0 0 0 .222-.529l-.913-5.599 3.864-3.961a.546.546 0 0 0 .125-.55z" style="fill: rgb(255, 255, 255);"></path>
                        </g>
                        <circle cx="17.5" cy="17.5" r="5.85" style="fill: rgb(255, 255, 255);"></circle>
                        <g id="g_szam">
                          <path d="M19.25 21h-2.5a.75.75 0 0 1-.75-.75v-1.5c0-1.103.897-2 2-2a.5.5 0 0 0 .5-.5V16a.5.5 0 0 0-.5-.5h-1.25a.75.75 0 0 1 0-1.5H18c1.103 0 2 .897 2 2v.25c0 1.103-.897 2-2 2a.5.5 0 0 0-.5.5v.75h1.75a.75.75 0 0 1 0 1.5z" style="fill: rgb(33, 37, 41);"></path>
                        </g>
                        <path d="M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" style="fill: rgb(33, 37, 41);"></path>
                        <path d="M4.283 20a.75.75 0 0 1-.741-.871l1.031-6.32L.213 8.34a.749.749 0 0 1 .424-1.265l5.996-.917L9.321.43c.246-.526 1.111-.526 1.357 0l2.688 5.728 5.996.917a.749.749 0 0 1 .424 1.265L18.459 9.7a.75.75 0 0 1-1.075-1.048l.309-.315-4.949-.756a.75.75 0 0 1-.565-.422L10 2.515 7.82 7.159a.75.75 0 0 1-.565.422l-4.949.756 3.606 3.695c.166.17.241.41.203.645l-.843 5.169 3.364-1.86a.75.75 0 0 1 .727 1.312l-4.717 2.607a.735.735 0 0 1-.363.095z" style="fill: rgb(33, 37, 41);"></path>
                      </svg>

                      <svg width="128" height="128" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" (click)="onErtekValasztasClick(3)" [class.kivalasztva]="formGroup.controls['ertekeles'].value == 3">
                        <defs></defs>
                        <g id="g_csillag_belso">
                          <path d="M18.749 8.026a.542.542 0 0 0-.431-.363l-5.319-.813-2.384-5.078c-.178-.379-.8-.379-.979 0L7.253 6.851l-5.319.813a.54.54 0 0 0-.305.912l3.864 3.961-.913 5.599a.542.542 0 0 0 .795.56l4.751-2.626 4.751 2.626a.544.544 0 0 0 .574-.031.54.54 0 0 0 .222-.529l-.913-5.599 3.864-3.961a.546.546 0 0 0 .125-.55z" style="fill: rgb(255, 255, 255);"></path>
                        </g>
                        <circle cx="17.5" cy="17.5" r="5.85" style="fill: rgb(255, 255, 255); "></circle>
                        <g id="g_szam">
                          <path d="M18.25 21h-1.5a.75.75 0 0 1 0-1.5h1.5a.25.25 0 0 0 .25-.25v-3.5a.25.25 0 0 0-.25-.25h-1.5a.75.75 0 0 1 0-1.5h1.5c.965 0 1.75.785 1.75 1.75v3.5c0 .965-.785 1.75-1.75 1.75z" style="fill: rgb(33, 37, 41);"></path>
                          <path d="M19.25 18.25h-2.5a.75.75 0 0 1 0-1.5h2.5a.75.75 0 0 1 0 1.5z" style="fill: rgb(33, 37, 41);"></path>
                        </g>
                        <path d="M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" style="fill: rgb(33, 37, 41);"></path>
                        <path d="M4.283 20a.75.75 0 0 1-.741-.871l1.031-6.32L.213 8.34a.749.749 0 0 1 .424-1.265l5.996-.917L9.321.43c.246-.526 1.111-.526 1.357 0l2.688 5.728 5.996.917a.749.749 0 0 1 .424 1.265L18.459 9.7a.75.75 0 0 1-1.075-1.048l.309-.315-4.949-.756a.75.75 0 0 1-.565-.422L10 2.515 7.82 7.159a.75.75 0 0 1-.565.422l-4.949.756 3.606 3.695c.166.17.241.41.203.645l-.843 5.169 3.364-1.86a.75.75 0 0 1 .727 1.312l-4.717 2.607a.735.735 0 0 1-.363.095z" style="fill: rgb(33, 37, 41);"></path>
                      </svg>

                      <svg width="128" height="128" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" (click)="onErtekValasztasClick(4)" [class.kivalasztva]="formGroup.controls['ertekeles'].value == 4">
                        <defs></defs>
                        <g id="g_csillag_belso">
                          <path d="M18.749 8.026a.542.542 0 0 0-.431-.363l-5.319-.813-2.384-5.078c-.178-.379-.8-.379-.979 0L7.253 6.851l-5.319.813a.54.54 0 0 0-.305.912l3.864 3.961-.913 5.599a.542.542 0 0 0 .795.56l4.751-2.626 4.751 2.626a.544.544 0 0 0 .574-.031.54.54 0 0 0 .222-.529l-.913-5.599 3.864-3.961a.546.546 0 0 0 .125-.55z" style="fill: rgb(255, 255, 255);"></path>
                        </g>
                        <circle cx="17.5" cy="17.5" r="5.85" style="fill: rgb(255, 255, 255); "></circle>
                        <g id="g_szam">
                          <path d="M18.25 21a.75.75 0 0 1-.75-.75V18h-.75c-.965 0-1.75-.785-1.75-1.75v-1.5a.75.75 0 0 1 1.5 0v1.5c0 .138.112.25.25.25h1.5a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75z" style="fill: rgb(33, 37, 41);"></path>
                          <path d="M18.25 18a.75.75 0 0 1-.75-.75v-2.5a.75.75 0 0 1 1.5 0v2.5a.75.75 0 0 1-.75.75z" style="fill: rgb(33, 37, 41);"></path>
                        </g>
                        <path d="M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" style="fill: rgb(33, 37, 41);"></path>
                        <path d="M4.283 20a.75.75 0 0 1-.741-.871l1.031-6.32L.213 8.34a.749.749 0 0 1 .424-1.265l5.996-.917L9.321.43c.246-.526 1.111-.526 1.357 0l2.688 5.728 5.996.917a.749.749 0 0 1 .424 1.265L18.459 9.7a.75.75 0 0 1-1.075-1.048l.309-.315-4.949-.756a.75.75 0 0 1-.565-.422L10 2.515 7.82 7.159a.75.75 0 0 1-.565.422l-4.949.756 3.606 3.695c.166.17.241.41.203.645l-.843 5.169 3.364-1.86a.75.75 0 0 1 .727 1.312l-4.717 2.607a.735.735 0 0 1-.363.095z" style="fill: rgb(33, 37, 41);"></path>
                      </svg>

                      <svg width="128" height="128" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" (click)="onErtekValasztasClick(5)" [class.kivalasztva]="formGroup.controls['ertekeles'].value == 5">
                        <defs></defs>
                        <g id="g_csillag_belso">
                          <path d="M18.749 8.026a.542.542 0 0 0-.431-.363l-5.319-.813-2.384-5.078c-.178-.379-.8-.379-.979 0L7.253 6.851l-5.319.813a.54.54 0 0 0-.305.912l3.864 3.961-.913 5.599a.542.542 0 0 0 .795.56l4.751-2.626 4.751 2.626a.544.544 0 0 0 .574-.031.54.54 0 0 0 .222-.529l-.913-5.599 3.864-3.961a.546.546 0 0 0 .125-.55z" style="fill: rgb(255, 255, 255);"></path>
                        </g>
                        <circle cx="17.5" cy="17.5" r="5.85" style="fill: rgb(255, 255, 255); "></circle>
                        <g id="g_szam">
                          <path d="M17.875 21H16.75a.75.75 0 0 1 0-1.5h1.125a.625.625 0 0 0 0-1.25H16.75a.75.75 0 0 1-.75-.75v-2.75a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5H17.5v1.25h.375c1.172 0 2.125.953 2.125 2.125S19.047 21 17.875 21z" style="fill: rgb(33, 37, 41);"></path>
                        </g>
                        <path d="M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" style="fill: rgb(33, 37, 41);"></path>
                        <path d="M4.283 20a.75.75 0 0 1-.741-.871l1.031-6.32L.213 8.34a.749.749 0 0 1 .424-1.265l5.996-.917L9.321.43c.246-.526 1.111-.526 1.357 0l2.688 5.728 5.996.917a.749.749 0 0 1 .424 1.265L18.459 9.7a.75.75 0 0 1-1.075-1.048l.309-.315-4.949-.756a.75.75 0 0 1-.565-.422L10 2.515 7.82 7.159a.75.75 0 0 1-.565.422l-4.949.756 3.606 3.695c.166.17.241.41.203.645l-.843 5.169 3.364-1.86a.75.75 0 0 1 .727 1.312l-4.717 2.607a.735.735 0 0 1-.363.095z" style="fill: rgb(33, 37, 41);"></path>
                      </svg>
                    </div>
                    <div class="row">
                      <div class="col-12 mt-3">
                        <label class="form-label text-uppercase strong text-primary small">
                          Szöveges értékelés
                          ({{this.formGroup.controls['szoveges_ertekeles'].value != null ? this.formGroup.controls['szoveges_ertekeles'].value.length : 0}} / 200)
                        </label>
                        <textarea formControlName="szoveges_ertekeles" class="form-control mb-0" cols="40" rows="5"></textarea>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center pt-5">
                      <button class="btn rounded-pill btn-secondary" type="button" [disabled]="!formGroup.valid" (click)="insert()">Értékelés elküldése</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</section>
