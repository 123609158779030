import { Component, OnInit } from '@angular/core';
import { UtilityService } from '@granit/common-library';
import { WeboldalService } from '../../../services/weboldal.service';

@Component({
  selector: 'hazmester',
  templateUrl: './hazmester.component.html',
  styleUrls: ['./hazmester.component.scss']
})
export class HazmesterComponent implements OnInit {

  constructor(private utilityService: UtilityService,
    public weboldalService: WeboldalService  ) { }

  weboldalDokumentumok: string = "";
  hazmesterKalkLink: string = "";

  ngOnInit(): void {
    this.linkek();
  }

  async linkek() {

    Promise.all(
      [this.utilityService.appsettingsKeyErtekLekerese({ key: 'WeboldalDokumentumok' }), this.utilityService.appsettingsKeyErtekLekerese({ key: 'HazmesterKalkLink' })]).then(
        data => {
          this.weboldalDokumentumok = data[0];

          let model = {
            f_link: data[1] + "?alkusz=0980"
          }
          this.weboldalService.linkHash(model).subscribe(
            adat => {
              this.hazmesterKalkLink = adat;
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a link lekérése közben",
                confirmButtonText: 'Rendben'
              });
            })          
        }
      );
  }

}
