<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">

        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">EGÉSZSÉGBIZTOSÍTÁSOK</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <h2 class="subtitle">
                <b>Vonatkozó jogszabályok</b>
              </h2>

              <ul class="ulstyle">
                <li>2014. évi LXXXVIII. törvény a biztosítási tevékenységről</li>
                <li>2013. évi V. törvény a Polgári Törvénykönyvről</li>
              </ul>

              <hr class="fancy-line" />
              <p>
                &nbsp;
              </p>
              <h1>
                Gránit Esély Egészségbiztosítás
              </h1>
              <p>
                &nbsp;
              </p>
              <div>
                <a href="https://kalk.granitbiztosito.hu/esely/kalk_adatok.aspx" target="_blank" class="btn btn-lg rounded-pill btn-secondary">
                  Esély biztosítás kalkuláció
                  és ajánlatkészítés
                </a>
              </div>
              <div class="clr">
              </div>
            </div>
            <p>
              &nbsp;
            </p>
            <p>
              Kutatások szerint a daganatos betegségek egyharmada gyógyítható lenne az időben
              történő diagnosztizálással és a megfelelő kezeléssel. A gyors és korai rákdiagnózis
              életmentő lehet, és a legkorszerűbb eljárásokkal végzett gyors és komplex rákdiagnosztika
              jelentősen javíthatja a túlélési esélyt. A Gránit Biztosító Zrt. Esély Egészségbiztosítása
              gyors és teljes körű rákdiagnosztikát biztosít a legkorszerűbb eljárásokkal és kiemelt
              hazai szakértőkkel, valamint komplex beteg támogatással minden daganat típusban.
            </p>
            <p class="question">
              <strong>Ki lehet szerződő?</strong>
            </p>
            <p>
              Bármely természetes személy, vagy gazdálkodó szervezet.<br />
              A szerződést nem csak magánszemélyek, hanem cégek, gazdálkodó szervezetek is megköthetik
              csoportos biztosítás keretében munkavállalóikra.
            </p>
            <p class="question">
              <strong>Ki lehet biztosított?</strong>
            </p>
            <p>
              Magyarország területén állandó lakóhellyel rendelkező 18 és 60 év közötti természetes
              személy, aki a magyar kötelező egészségbiztosítás rendszerében biztosított, vagy
              az egészségügyi szolgáltatásra egyéb jogcímen jogosult, továbbá érvényes társadalombiztosítási
              azonosító jellel (TAJ szám) rendelkezik (állampolgárságtól függetlenül). Egyéni
              biztosítás esetén Társaságunk 3 hónapos várakozási időt ír elő, amely időtartamra
              a kockázatviselés nem terjed ki.
            </p>
            <p class="question">
              <strong>Milyen szolgáltatásokat nyújtunk az egészségbiztosítás keretében?</strong>
            </p>
            <p class="question">
              Amennyiben a biztosítottnál valamely orvosi vizsgálat során daganatra specifikus
              diagnosztikus vizsgálatot írnak elő az alábbi szolgáltatásokra válik jogosulttá.
            </p>
            <ul class="ulstyle">
              <li>
                Daganattípustól függő gyors és komplex diagnosztika, ideértve a PET-CT vizsgálatot
                is.
              </li>
              <li>A kezelés hatásosságát értékelő és a további terápiás tervet segítő PET-CT</li>
              <li>Komplex betegtámogatás, asszisztencia - beteg vezetés (beleértve az indokolt betegszállítást)</li>
              <li>Dietetikai tanácsadás</li>
              <li>Lelki/pszichológiai támogatás (onkopszichológiai konzultáció)</li>
              <li>Klinikai kutatási programokban való részvétel lehetőség szerinti megszervezése</li>
            </ul>
            <p>
              A fenti szolgáltatásokat a biztosító szolgáltató partnere, az <a href="http://www.onkomplex.hu/"
                                                                               target="_blank">ONKOMPLEX Kft.</a> révén nyújtja, amely megszervezi a biztosított
              részére a komplett diagnosztikai vizsgálatot. A szolgáltatások kizárólag a szolgáltató
              által megszervezett módon vehetők igénybe az alábbi telefonszámon történő bejelentéssel:
            </p>
            <p style="text-align: center;">
              06 (1) 666-62-00
            </p>
            <p class="question">
              A diagnosztikai és betegtámogató szolgáltatásokon kívül az alábbi - a választott
              csomagtól függő - összegben meghatározott szolgáltatásokat is tartalmazza a termék:
            </p>
            <ul class="ulstyle">
              <li>
                <strong>Műtéti támogatás:</strong>
                <ul class="ulstyle">
                  <li>alapcsomagban 100.000 - 200.000 Ft közötti összegben,</li>
                  <li>bővített csomagban <span>150.000 - 300.000 Ft közötti összegben</span></li>
                </ul>
              </li>
              <li>
                <strong>Kórházi napi térítés:</strong> 10.000 Ft/nap (alapcsomag) illetve 15.000
                Ft/nap (bővített csomag) a kórházban töltött idő 4, napjától legfeljebb az 50. napig.
              </li>
            </ul>
            <p>
              A szerződő kétféle csomag közül választhat, amelyek az alábbi szolgáltatásokat nyújtják.
            </p>
            <div class="TablePlaceHolder">
              <table>
                <tr>
                  <td>
                    Szolgáltatások
                  </td>
                  <td>
                    Alap
                  </td>
                  <td>
                    Bővített
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    Daganattípustól függő gyors és komplex diagnosztika:
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0 0 0 20px; height: 14px;">
                    Probléma feltáró konzultáció
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0 0 0 20px;">
                    Daganat helye (típusa) alapján a diagnózishoz szükséges vizsgálati sor:<br />
                    képalkotó diagnosztika (CT, MRI, PET), szövettan, labor, stb.
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0 0 0 20px;">
                    Alap molekuláris diagnosztika*
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                  <td style="background: #969696;">
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0 0 0 20px;">
                    Kiterjesztett molekuláris diagnosztika**
                  </td>
                  <td style="background: #969696;">
                    &nbsp;
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                </tr>
                <tr>
                  <td>
                    A kezelés hatásosságát értékelő és a további terápiás tervet segítő PET-CT
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                </tr>
                <tr>
                  <td>
                    Komplex betegtámogatás, asszisztencia - beteg vezetés (beleértve az indokolt betegszállítást)
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                </tr>
                <tr>
                  <td>
                    Dietetikai tanácsadás
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                </tr>
                <tr>
                  <td>
                    Lelki/pszichológiai támogatás (onkopszichológiai konzultáció)
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    Klinikai kutatási programokban való részvétel lehetőség szerinti megszervezése:
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0 0 0 20px;">
                    Klinikai kutatási program szelekció, konzultáció
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0 0 0 20px;">
                    Klinikai kutatási program szervezés
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0 0 0 20px;">
                    Klinikai kutatási programhoz tolmács, utazás, szállás
                  </td>
                  <td class="center">
                    100 000 Ft
                  </td>
                  <td class="center">
                    200 000 Ft
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0 0 0 20px;">
                    Klinikai vizsgálatok monitorozása, nyomon követése a beteg egyéni profilja alapján
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                  <td class="center">
                    &#x2713;
                  </td>
                </tr>
                <tr>
                  <td>
                    Műtéti támogatás (nagy műtét)
                  </td>
                  <td class="center">
                    200 000 Ft
                  </td>
                  <td class="center">
                    300 000 Ft
                  </td>
                </tr>
                <tr>
                  <td>
                    Műtéti támogatás (közepes műtét)
                  </td>
                  <td class="center">
                    150 000 Ft
                  </td>
                  <td class="center">
                    200 000 Ft
                  </td>
                </tr>
                <tr>
                  <td>
                    Műtéti támogatás (kis műtét)
                  </td>
                  <td class="center">
                    100 000 Ft
                  </td>
                  <td class="center">
                    150 000 Ft
                  </td>
                </tr>
                <tr>
                  <td>
                    Kórházi napi térítés
                  </td>
                  <td class="center">
                    10 000 Ft
                  </td>
                  <td class="center">
                    15 000 Ft
                  </td>
                </tr>
              </table>
            </div>
            <p>
              * Alap molekuláris diagnosztika: annak a vizsgálata, hogy a daganatban milyen genetikai
              elváltozások vannak jelen – (Ezen genetikai elváltozások kimutatása alapvetően befolyásolja
              az adott daganat kezelésére törzskönyvezett úgynevezett célzott, biológiai terápiák
              megfelelő kiválasztását.)<br />
              ** Kiterjesztett molekuláris diagnosztika: azon genetikai elváltozások kiválasztása
              és kimutatása a daganatban, amelyekkel kapcsolatos célzott, biológiai terápiák az
              adott időpontban csak klinikai kutatási programok keretei között érhetőek el.
            </p>
            <p>
              &nbsp;
            </p>
            <h2 class="subtitle">
              Letölthető dokumentumok
            </h2>
            <ul>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_esely_alap.pdf">Biztosítási termékismertető - Alap csomag</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_esely_bovitett.pdf">Biztosítási termékismertető - Bővített csomag</a><br></li>

              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20240215.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a><br /></li>

            </ul>
            <h2 class="subtitle">
              Archívum
            </h2>
            <ul>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20230615.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a><br /></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20211220.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a><br /></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20210501.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a><br /></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20200701.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a><br /></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20190426.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a><br /></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20190101.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.01.01-től hatályos)</a></li>
              <li><a class="download" href="{{weboldalDokumentumok}}ugyfel_taj_20181105.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.11.05-től hatályos)</a><br /></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20180808.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20180701.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20180525.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20180223.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a><br /></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20180101.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a><br /></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20170601.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-től hatályos)</a><br /></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20170401.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-től hatályos)</a><br /></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20170101.pdf">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-től hatályos)</a><br /></li>
              <li><a class="download" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20160901.pdf" target="_blank">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-től hatályos)</a><br /></li>
              <li><a class="download" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20160701.pdf" target="_blank">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-től hatályos)</a><br /></li>
              <li><a class="download" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20160101.pdf" target="_blank">Biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.01-től hatályos)</a></li>
              <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a><br /></li>
              <li><a class="download" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20150101.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Biztosítási feltételek (2015.01.01-től hatályos)</a></li>
              <li><a class="download" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20140315.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Biztosítási feltételek (2014.03.15-től hatályos)</a></li>
              <li><a class="download" href="{{weboldalDokumentumok}}esely_feltetelcsomag_20131126.pdf" target="_blank">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Biztosítási feltételek (2013.11.26-tól hatályos)</a></li>
            </ul>
            <p>
              &nbsp;
            </p>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
