<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">FIZETŐKÉPESSÉGRŐL ÉS PÉNZÜGYI HELYZETRŐL SZÓLÓ JELENTÉS</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fizetokepesseg_penzugyi_helyzet_2016.pdf">Fizetőképességről és pénzügyi helyzetről szóló jelentés 2016.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fizetokepesseg_penzugyi_helyzet_2017.pdf">Fizetőképességről és pénzügyi helyzetről szóló jelentés 2017.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fizetokepesseg_penzugyi_helyzet_2018.pdf">Fizetőképességről és pénzügyi helyzetről szóló jelentés 2018.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fizetokepesseg_penzugyi_helyzet_2019.pdf">Fizetőképességről és pénzügyi helyzetről szóló jelentés 2019.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fizetokepesseg_penzugyi_helyzet_2020.pdf">Fizetőképességről és pénzügyi helyzetről szóló jelentés 2020.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fizetokepesseg_penzugyi_helyzet_2021.pdf">Fizetőképességről és pénzügyi helyzetről szóló jelentés 2021.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fizetokepesseg_penzugyi_helyzet_2022.pdf">Fizetőképességről és pénzügyi helyzetről szóló jelentés 2022.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fizetokepesseg_penzugyi_helyzet_2023.pdf">Fizetőképességről és pénzügyi helyzetről szóló jelentés 2023.</a></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
