import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jogi-nyilatkozat',
  templateUrl: './jogi-nyilatkozat.component.html',
  styleUrls: ['./jogi-nyilatkozat.component.scss']
})
export class JogiNyilatkozatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
