import { Component, OnInit } from '@angular/core';
import { UtilityService } from '@granit/common-library';

@Component({
  selector: 'nyitolap',
  templateUrl: './nyitolap.component.html',
  styleUrls: ['./nyitolap.component.scss']
})
export class NyitolapComponent implements OnInit {

  onlineKarbejelentoLink: string = "";
  onlineFizetesLink: string = "";
  ugyfelPortalLink: string = "";
  partnerPortalLink: string = "";  

  constructor(private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.linkek();
  }

  async linkek() {

    let model = {
      key: "OnlineKarbejelentoLink"
    }
    this.onlineKarbejelentoLink = await this.utilityService.appsettingsKeyErtekLekerese(model);

    model = {
      key: "OnlineFizetesLink"
    }
    this.onlineFizetesLink = await this.utilityService.appsettingsKeyErtekLekerese(model);    

    model = {
      key: "UgyfelPortalLink"
    }
    this.ugyfelPortalLink = await this.utilityService.appsettingsKeyErtekLekerese(model);

    model = {
      key: "PartnerPortalLink"
    }
    this.partnerPortalLink = await this.utilityService.appsettingsKeyErtekLekerese(model);
  }

}
