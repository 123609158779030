<div class="container">
  <div class="row pb-5">
    <div class="col-12 col-lg">
      <ul class="nav flex-column">
        <li class="nav-item">
          <span class="nav-link bold text-primary text-uppercase">Elérhetőségeink</span>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" href="tel:+3616666200">Telecenter (+36-1) 666-62-00</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/kapcsolat']">Kapcsolat</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link text-light" href="#">Írj nekünk</a>
        </li>-->
      </ul>
    </div>
    <div class="col-12 col-lg">
      <ul class="nav flex-column">
        <li class="nav-item">
          <span class="nav-link bold text-primary text-uppercase">TERMÉKEK</span>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/kgfb']">Kötelező biztosítás</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/casco']">CASCO biztosítás</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/egeszseg']">Egészségbiztosítás</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/kaf-kszf']">KÁF/KSZF biztosítás</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/utas']">Utasbiztosítás</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/otthon']">Otthonbiztosítás</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/cmr']">CMR/szállítmány- biztosítás</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/vagyon']">Vagyonbiztosítások</a>
        </li>
      </ul>
    </div>
    <div class="col-12 col-lg">
      <ul class="nav flex-column">
        <li class="nav-item">
          <span class="nav-link bold text-primary text-uppercase">Ügyintézés</span>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" href="{{onlineFizetesLink}}" target="_blank">Díjfizetés</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link text-light" href="#">Szerződés- és adatváltozás</a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link text-light" href="{{dokumentumIgenylesLink}}" target="_blank">Dokumentum igénylés</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link text-light" href="#">Dokumentum pótlás</a>
        </li>-->
        <!--<li class="nav-item">
          <a class="nav-link text-light" href="#">Befektetések kezelése</a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link text-light" href="{{ugyfelPortalLink}}" target="_blank">Ügyfélportál</a>
        </li>
      </ul>
    </div>
    <div class="col-12 col-lg">
      <ul class="nav flex-column">
        <li class="nav-item">
          <span class="nav-link bold text-primary text-uppercase">Kárrendezés</span>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" href="{{onlineKarbejelentoLink}}" target="_blank">Kárbejelentés</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link text-light" href="#">Kárstátusz</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" href="#">Hiánypótlás</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" href="#">Segélyhívók</a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/karrendezes']">Kárrendezés</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/karrendezesi-tajekoztatok']">Kárrendezési tájékoztatók</a>
        </li>
      </ul>
    </div>
    <div class="col-12 col-lg">
      <ul class="nav flex-column">
        <li class="nav-item">
          <span class="nav-link bold text-primary text-uppercase">CÉG</span>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/vezetoseg']">Rólunk</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/allasajanlatok']">Karrier</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link text-light" href="#">Céginformációk</a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/hirek']">Sajtószoba</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-light" [routerLink]="['/nevvaltas']">Névváltás</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-2 text-center text-lg-start">
      <img src="assets/images/logo-light.svg" class="img-fluid" alt="Gránit Biztosító">
    </div>
    <div class="col-lg-10">
      <ul class="nav flex-column flex-lg-row justify-content-center justify-content-lg-end">
        <li class="nav-item pe-0">
          <a class="nav-link text-uppercase text-light small pe-0 text-center text-lg-start" [routerLink]="['/kapcsolat']">Kapcsolat</a>
        </li>
        <li class="nav-item pe-0">
          <a class="nav-link text-uppercase text-light small pe-0 text-center text-lg-start" href="{{partnerPortalLink}}" target="_blank">Partnerportál</a>
        </li>
        <li class="nav-item pe-0">
          <a class="nav-link text-uppercase text-light small pe-0 text-center text-lg-start" href="{{onlineFizetesLink}}" target="_blank">Bankkártyás fizetés</a>
        </li>
        <li class="nav-item pe-0">
          <a class="nav-link text-uppercase text-light small pe-0 text-center text-lg-start" [routerLink]="['/impresszum']">Impresszum</a>
        </li>
        <li class="nav-item pe-0">
          <a class="nav-link text-uppercase text-light small pe-0 text-center text-lg-start" [routerLink]="['/jogi-nyilatkozat']">Jogi nyilatkozat</a>
        </li>
        <li class="nav-item pe-0">
          <a class="nav-link text-uppercase text-light small pe-0 text-center text-lg-start" href="{{weboldalDokumentumok}}GB_adatvedelmi_es_adatkezelesi_tajekoztato.pdf" target="_blank">ADATVÉDELMI NYILATKOZAT ÉS ADATKEZELÉSI TÁJÉKOZTATÓ</a>
        </li>
        <li class="nav-item pe-0">
          <a class="nav-link text-uppercase text-light small pe-0 text-center text-lg-start" href="{{weboldalDokumentumok}}GB_adatkezelesi_tajekoztato_elektronikus_megfigyelo_es_keprogzito_rendszerrol.pdf" target="_blank">ADATKEZELÉSI TÁJÉKOZTATÓ AZ ELEKTRONIKUS MEGFIGYELŐ- ÉS KÉPRÖGZÍTŐ RENDSZER ALKALMAZÁSÁRÓL</a>
        </li>
      </ul>
      <div class="row justify-content-end">
        <div class="col-lg-10">
          <div class="text-uppercase text-light-2 small text-lg-end px-3 px-lg-0">
            <em>
              GRÁNIT BIZTOSÍTÓ ZRT. ©2024. MINDEN JOG FENNTARTVA. UTOLSÓ FRISSÍTÉS DÁTUMA: 2024.04.02
            </em>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<script>
  document.addEventListener('DOMContentLoaded', function (event) {

    var navbarToggler = document.querySelectorAll('.navbar-toggler')[0];
    navbarToggler.addEventListener('click', function (e) {
      e.target.children[0].classList.toggle('active');
    });

  });
</script>
