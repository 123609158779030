<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">PÉNZÜGYI NAVIGÁTOR A TUDATOS FOGYASZTÓKÉRT</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <p><b>A Magyar Nemzeti Bankról szóló 2013. évi CXXXIX. törvény az MNB feladataként jelöli meg a pénzügyi közvetítőrendszer felügyeletét, illetve a pénzügyi szervezetek által nyújtott szolgáltatásokat igénybe vevők érdekeinek védelmét.</b></p>
              <p>&nbsp;</p>
              <p>E tevékenység keretében az MNB vizsgálja a hazai és nemzetközi piaci folyamatokat, figyelemmel kíséri és ellenőrzi az intézmények jogszerű működését, kommunikációs csatornáit és azok tartalmát, általános sajtófigyelés keretében értékeli a pénzügyi intézményekről és szolgáltatókról megjelenő híreket, továbbá elemzi a jogszabályon alapuló adatszolgáltatás nyomán beérkező információkat.</p>
              <p>A tudatos fogyasztói társadalom kialakítása, a megalapozatlan, helytelen fogyasztói döntésekből adódó félreértések és károk csökkentése érdekében az MNB proaktív pénzügyi fogyasztóvédelmi tájékoztató tevékenységet végez, professzionális ügyfélszolgálatot működtet, továbbá társintézményekkel, valamint a civil és a kereskedelmi-szolgáltató szféra szereplőivel működik együtt.</p>
              <p>A mindennapi életben hasznos, pénzügyekkel kapcsolatos információk a Pénzügyi Navigátor tájékoztatóanyagokban érhetők el. A Pénzügyi Navigátor eszközrendszer pénzügyi fogyasztóvédelmi honlapból, tematikus füzetsorozatból, oktató- és egyperces kisfilmekből, termékválasztást és tudatos döntést segítő alkalmazásokból, valamint mobilapplikációból áll.</p>
              <p>A megalapozott pénzügyi döntést ösztönző tájékoztatás mellett az MNB a panaszos helyzetek kezeléséhez is segítséget nyújt.  A budapesti Krisztina körúton található ügyfélszolgálat munkatársai személyesen, telefonon és írásban is rendelkezésre állnak, míg a fővárostól távol élő fogyasztók az MNB-vel együttműködő Pénzügyi Navigátor Tanácsadó Irodahálózat szakértőihez fordulhatnak számos vidéki városban.</p>

              <p>Pénzügyi Navigátor. Utat mutat a pénzügyekben.</p>
              <p><a href="http://www.mnb.hu/penzugyinavigator">www.mnb.hu/penzugyinavigator</a></p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
