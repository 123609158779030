import { Component, OnInit } from '@angular/core';
import { UtilityService } from '@granit/common-library';

@Component({
  selector: 'karrendezesi-tajekoztatok',
  templateUrl: './karrendezesi-tajekoztatok.component.html',
  styleUrls: ['./karrendezesi-tajekoztatok.component.scss']
})
export class KarrendezesiTajekoztatokComponent implements OnInit {

  constructor(private utilityService: UtilityService) { }

  weboldalDokumentumok: string = "";

  ngOnInit(): void {
    this.linkek();
  }

  async linkek() {
    let model = {
      key: "WeboldalDokumentumok"
    }
    this.weboldalDokumentumok = await this.utilityService.appsettingsKeyErtekLekerese(model);
  }

}
