<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">ONLINE FIZETÉSI TÁJÉKOZTATÓ</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <p>
                Honlapunk online értékesítési és fizetési felülete a CIB Bank által biztosított biztonságos bankkártyás fizetési megoldást nyújtja ügyfeleknek. A biztonságot az adatok szétválasztása alapozza meg. Az online értékesítési és fizetési felület a megrendeléssel kapcsolatos információkat kapja meg a vásárlótól, a CIB Bank pedig kizárólag a fizetési tranzakcióhoz szükséges kártyaadatokat a 256 bites TLS titkosítással ellátott fizetőoldalon. A fizetőoldal adattartalmáról a Gránit Biztosító Zrt. értékesítési és fizetési felülete nem értesül, azokat csak a CIB Bank érheti el. A tranzakció eredményéről a fizetést követően a Gránit Biztosító Zrt. értékesítési és fizetési felülete oldala tájékoztatja. A kártyás fizetéshez az Ön internet böngésző programjának támogatnia kell a TLS titkosítást.
                A biztosítási szolgáltatás ellenértéke, a kifizetett összeg azonnal zárolásra kerül kártyaszámláján. Kérjük, olvassa el részletes tájékoztatónkat!
              </p>

              <p><strong>Üdvözöljük a CIB Bank biztonságos, internetes fizetési megoldásával működő Gránit Biztosító Zrt. online értékesítési és fizetési felületén! </strong></p>
              <p>Az alábbiakban röviden ismertetjük, hogy miképp intézheti biztonságos módon vásárlását.</p>
              <p><b>Mire ügyeljen a tranzakció során?</b></p>
              <ul class="ulstyle">
                <li>Olvassa el az adott biztosítási termék ismertetőjét, szerződéskötési feltételeit, a Gránit Biztosító Zrt. online értékesítési és fizetési felület ismertetőjét, a vásárlás kondícióit és a fizetés feltételeit!</li>
                <li>Tanulmányozza át a Gránit Biztosító Zrt. online értékesítési felület biztonsági feltételeit, hiszen ezzel garantálják az Ön adatainak biztonságát!</li>
                <li>Tartsa nyilván a vásárlásával kapcsolatos adatait!</li>
                <li>Tartsa nyilván a fizetéssel kapcsolatos tranzakciós adatait! (tranzakció azonosító, engedélyszám)</li>
                <li>Biztosítsa, hogy titkos kártyaadataihoz illetéktelen személy soha ne férhessen hozzá.</li>
                <li>Használjon olyan böngészőt, amely támogatja a TLS titkosításhoz szükséges opciót!</li>
              </ul>
              <p></p>
              <p><strong>A biztonságról</strong></p>
              <p>A TLS, a Transport Layer Security elfogadott titkosítási eljárás rövidítése. Bankunk rendelkezik egy 256 bites titkosító kulccsal, amely a kommunikációs csatornát védi. A VeriSign nevű cég teszi lehetővé a CIB Banknak a 256 bites kulcs használatát, amely segítségével biztosítjuk az TLS alapú titkosítást. Jelenleg a világ elektronikus kereskedelmének 90%-ában ezt a titkosítási módot alkalmazzák. A vásárló által használt böngésző program az TLS segítségével a kártyabirtokos adatait az elküldés előtt titkosítja, így azok kódolt formában jutnak el a CIB Bankhoz, ezáltal illetéktelen személyek számára nem értelmezhetőek.</p>
              <p><strong>Elfogadott kártyák</strong></p>
              <p>A CIB Bank internetes fizetési rendszere a Mastercard/Maestro, a VISA termékcsaládba tartozó VISA és VISA Electron (az Electronnál csak abban az esetben, ha azt a kibocsátó bank engedélyezi) bankkártyák használatát, valamint internetes használatra alkalmas webkártyával való fizetést teszi lehetővé.</p>
              <p><strong>Fizetés lépései</strong></p>
              <ol>
                <li>
                  <p>Ön a Gránit Biztosító Zrt. online értékesítési felületén elkészített biztosítási ajánlatának elkészítése után, dönthet a biztosítás díjának bankkártyás fizetési móddaltörténő kiegyenlítéséről. A szükséges adatok megadásával már megkötött biztosítási szerződésén fennálló aktuális díjtartozás összegéről is meggyőződhet. A díjhátralék kifizetése érdekében elindíthatja a bankkártyás fizetési tranzakciót. Kötelező gépjármű-felelősségbiztosítási szerződés esetén kérjük, vegye figyelembe a jogszabály által meghatározott türelmi időt. 60 napnál régebbi díjtartozás esetén Ön tájékoztató üzenetet fog kapni. Ezt követően Ön átkerül a CIB Bank biztonságos fizetést garantáló oldalára, ahol a fizetés megkezdéséhez a kártyaadatait szükséges kitöltenie.</p>
                </li>
                <li>
                  <p>Ezt követően Ön átkerül a CIB Bank biztonságos fizetést garantáló oldalára, ahol a fizetés megkezdéséhez kártyaadatait szükséges kitöltenie. A kártyaadatok megadását követően a Fizetés gombra kattintva indíthatja el a tranzakciót.</p>
                </li>
                <li>
                  <p>A sikeres hitelesítést követően folytatódik a fizetési folyamat.</p>
                </li>
                <li>
                  <p>A fizetést követően Ön visszatér a Gránit Biztosító Zrt. online értékesítési és fizetési felület oldalára, ahol a tranzakció eredményéről kap visszaigazolást.</p>
                </li>
              </ol>
              <p>A bankkártyával történő fizetés esetén a sikeres tranzakciót követően – ez a bankkártya érvényessége és a fedezet ellenőrzése utáni elfogadást jelenti –, a CIB Bank elindítja a Kártyabirtokos számlájának megterhelését a biztosítási szolgáltatás ellenértékével (biztosítási díj).</p>
              <p>Amennyiben Ön nem kerül visszairányításra a fizetőoldalról a Gránit Biztosító Zrt. online értékesítési felület oldalára, úgy a tranzakció sikertelennek minősül. Sikertelennek minősül a fizetés abban az esetben is, ha a banki fizetőoldalon a böngésző „Vissza/Back” vagy a „Frissítés/Refresh” gombjára kattint, vagy a böngésző ablakot – mielőtt visszairányításra kerülne a Gránit Biztosító Zrt. online értékesítési felület oldalára – bezárja, a fizetés sikertelennek minősül.</p>
              <p>Amennyiben a tranzakció eredményéről, annak sikertelensége esetén, okáról, részleteiről bővebben kíván tájékozódni, kérjük, vegye fel a kapcsolatot számlavezető bankjával.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
