import { Component, OnInit, HostListener } from '@angular/core';
import { UtilityService } from '@granit/common-library';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {

  constructor(
    private utilityService: UtilityService) { }

  onlineKarbejelentoLink: string = "";
  ugyfelPortalLink: string = "";
  onlineFizetesLink: string = "";
  partnerPortalLink: string = "";
  weboldalDokumentumok: string = "";
  dokumentumIgenylesLink: string = "";


  ngOnInit(): void {
    this.linkek();
  }

  async linkek() {

    Promise.all(
      [this.utilityService.appsettingsKeyErtekLekerese({ key: 'OnlineKarbejelentoLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'UgyfelPortalLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'OnlineFizetesLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'PartnerPortalLink' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'WeboldalDokumentumok' }),
      this.utilityService.appsettingsKeyErtekLekerese({ key: 'DokumentumIgenylesLink' })]).then(
        data => {
          this.onlineKarbejelentoLink = data[0];
          this.ugyfelPortalLink = data[1];
          this.onlineFizetesLink = data[2];
          this.partnerPortalLink = data[3];
          this.weboldalDokumentumok = data[4];
          this.dokumentumIgenylesLink = data[5];
        }
      );
  }
}
