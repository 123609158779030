import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dokumentum-igenyles',
  templateUrl: './dokumentum-igenyles.component.html',
  styleUrls: ['./dokumentum-igenyles.component.scss']
})
export class DokumentumIgenylesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
