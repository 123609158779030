<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">SÜTIK (COOKIE) KEZELÉSE A HONLAPON</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <p>Honlapunkon, egyes alkalmazások megfelelő működéséhez, az oldal állapotának kezeléséhez, bizonyos beállítások tárolásához sütiket vagy más néven "cookie"-kat (a böngészőt futtató eszköz háttértárolóján elhelyezett rövid szöveges fájlok) használunk.</p>
              <p>Egyes szolgáltatásaink kifejezetten a sütik elfogadásától függnek, így a sütik esetleges tiltásával a látogató számára a honlapon nem várt működés lesz tapasztalható, rosszabb esetben lehetetlenné válik a honlap bizonyos szolgáltatásainak használata.</p>
              <h3>Munkamenet (session) sütik</h3>
              <p>Ezek a sütik ahhoz szükségesek, hogy a felhasználók böngészhessék weboldalunkat, használják annak funkciót, pl. többek között az Ön által adott oldalakon végzett műveletek megjegyzését egy látogatás során. Ezen sütik érvényességi ideje kizárólag az Ön aktuális látogatására vonatkozik, a munkamenet végeztével, illetve a böngésző bezárásával a sütik e fajtája automatikusan törlődik a számítógépéről.</p>
              <h3>Használatot elősegítő sütik</h3>
              <p>Ezek lehetőséget biztosítanak számunkra arra, hogy megjegyezhessük a weboldalunkkal kapcsolatos választásait, mint pl. az ügyfél- vagy partnerportál belépés oldalon az azonosító megjegyzése.</p>
              <h3>Honlap használatának jellegét, statisztikák készítését segítő sütik</h3>
              <p>A Google Analytics sütiket arra használjuk, hogy információt gyűjtsünk azzal kapcsolatban, hogyan használják látogatóink weboldalunkat. Ezek a sütik nem tudják Önt személy szerint beazonosítani (az éppen használt IP címet is csak részben rögzítik), olyan információkat gyűjtenek, mint pl. hogy melyik oldalt nézett meg a látogatónk, a felhasználó a weboldal mely részére kattintott, hány oldalt keresett fel, milyen hosszú volt az egyes munkamenetek megtekintési ideje, melyek voltak az esetleges hibaüzenetek - mindezt weboldalunk fejlesztésének, valamint a felhasználók számára biztosított élmények javításának céljával.</p>
              <p>Google Analytics sütikkel kapcsolatos további tudnivalók érdekében kérjük, <a href="https://support.google.com/analytics/answer/6004245?hl=hu">kattintson ide.</a></p>
              <p>A legnépszerűbb böngészők süti beállításairól, korlátozás végrehajtásáról az alábbi linkeken tájékozódhat:</p>
              <ul>
                <li><a class="link" href="https://support.google.com/accounts/answer/61416?hl=hu" target="_blank">Google Chrome</a> </li>
                <li><a class="link" href="https://support.mozilla.org/hu/kb/Sütik%20kezelése" target="_blank">Firefox</a> </li>
                <li><a class="link" href="https://support.apple.com/hu-hu/guide/deployment/depf7d5714d4/web" target="_blank">Safari</a> </li>
                <li><a class="link" href="https://support.microsoft.com/hu-hu/windows/cookie-k-t%C3%B6rl%C3%A9se-%C3%A9s-kezel%C3%A9se-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Microsoft Edge</a>
              </ul>
              <p>Az érintett felhasználó a saját személyes nyilvántartott adatairól jogosult tájékoztatást kapni.</p>
              <p>Amennyiben kérdései vannak személyes adatainak felhasználásával kapcsolatban, felvilágosítás iránti kéréseivel vagy javaslataival az alábbi címen fordulhat Társaságunkhoz.</p>
              <p>
                Központi Ügyfélszolgálat:
              <p>Cím:	1211 Budapest, Szállító utca 4.</p>
              <p>Tel.:	(+36-1) 666-62-00</p>
              <p>E-mail: <img src="../../../assets/images/email/uszi.png" alt="Ügyfélszolgálat" /></p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
