<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">CMR/SZÁLLÍTMÁNYBIZTOSÍTÁS</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <ul>
                <li><a class="scroll" (click)="scrollToelement('A1')">Szállítmánybiztosítás</a></li>
                <li><a class="scroll" (click)="scrollToelement('A2')">Nemzetközi közúti árufuvarozói felelősségbiztosítás (CMR)</a></li>
                <li><a class="scroll" (click)="scrollToelement('A3')">Belföldi közúti árutovábbítási felelősségbiztosítás (BÁF)</a></li>
              </ul>
              <h2 class="subtitle">
                <b>Vonatkozó jogszabályok</b>
              </h2>

              <ul class="ulstyle">
                <li>2014. évi LXXXVIII. törvény a biztosítási tevékenységről</li>
                <li>2013. évi V. törvény a Polgári Törvénykönyvről</li>
              </ul>

              <h2 class="subtitle"><strong>A biztosítási módozathoz tartozó számlaszám</strong></h2>
              <table style="width: 100%;"><tr><td><span>10918001-00000068-64440051</span></td><td>IBAN: <span>HU15 1091 8001 0000 0068 6444 0051</span></td></tr></table>

              <div id="A1">
                <hr class="fancy-line" />
                <p>
                  &nbsp;
                </p>

                <h1>
                  Szállítmánybiztosítás
                </h1>
                <p>
                  &nbsp;
                </p>
                <p class="question">
                  <strong>Milyen típusú szállítmányok biztosíthatóak?</strong>
                </p>
                <ul class="ulstyle">
                  <li>Magyarországról induló, vagy ide érkező nemzetközi, illetve belföldi</li>
                  <li>szárazföldön, vízi vagy légi úton, vagy ezek kombinációjával továbbított szállítmányok</li>
                  <li>egyszeri és éves szerződés formájában</li>
                </ul>
                <p class="question">
                  <strong>Ki kötheti a biztosítást? Ki lehet a Szerződő?</strong>
                </p>
                <p>
                  Az a természetes személy (egyéni vállalkozó), jogi személy, vagy jogi személyiséggel
                  nem rendelkező szervezet (cég), aki/amely valamely vagyoni, dologi jogi jogviszony
                  alapján a biztosítási esemény elkerülésében érdekelt, vagy aki/amely a szerződést
                  érdekelt személy javára köti meg. Például:
                </p>
                <ul class="ulstyle question">
                  <li>az áru tulajdonosa</li>
                  <li>az áru feladója, illetve címzettje a kereskedelmi paritástól függően</li>
                  <li>a szállítmányozó</li>
                </ul>
                <p class="question">
                  &nbsp;
                </p>
                <p>
                  <strong>Mi a biztosítási esemény?</strong>
                </p>
                <p>
                  A szállítmányt ért - véletlenül, balesetszerűen bekövetkezett - dologi kár, azaz
                  annak sérülése, megsemmisülése, továbbá külső fizikai behatás miatt bekövetkezett
                  mennyiségi vesztesége vagy egyéb károsodása.
                </p>
                <p class="question">
                  <strong>Milyen kockázatokra köthető?</strong>
                </p>
                <p>
                  A nemzetközi gyakorlatban szokásos ICC (Institute Cargo Clauses) feltételrendszert
                  alapul véve:
                </p>
                <ul class="ulstyle question">
                  <li><strong>All Risks az ICC (A)</strong> klauzúra szerint: </li>
                  <li><strong>Kiterjesztett kockázatokra szóló fedezet az ICC (B)</strong> klauzúra szerint:</li>
                  <li>
                    <strong>Korlátozott fedezetet az ICC (C)</strong> klauzúra szerint: csak a záradékban
                    megnevezett káresemények kapcsán keletkezett károkat téríti meg:
                  </li>
                </ul>
                <p class="question">
                  <strong>Köthető kiegészítő kockázatok:</strong>
                </p>
                <ul class="ulstyle question">
                  <li>
                    ICC (B) és (C) fedezethez:
                    <ul class="ulstyle question">
                      <li>a lopási, dézsmálási és elveszési károk fedezete </li>
                    </ul>
                  </li>
                  <li>
                    ICC (A) fedezethez:
                    <ul class="ulstyle question">
                      <li>a hűtött árukra vonatkozó hűtőberendezések meghibásodása</li>
                      <li>a háború és sztrájk kockázatok fedezete</li>
                    </ul>
                  </li>
                </ul>
                <p class="question">
                  &nbsp;
                </p>
                <p class="question">
                  <b>Mire terjed ki a biztosító kockázatviselése?</b>
                </p>
                <p>
                  A biztosító kockázatviselésének területi hatálya a biztosítási kötvényen, a fedezeti
                  igazoláson, vagy a szerződő felek közötti keretszerződésben meghatározott biztosított
                  útvonalon/útvonalakon – feladási és rendeltetési hely/helyek között – történő szállítás
                  teljes egészére terjed ki (háztól házig)
                </p>
                <p class="question">
                  <strong>Mi a biztosítási összeg?</strong>
                </p>
                <p>
                  A szállítmány a biztosított által megjelölt biztosítási összeg erejéig biztosított,
                  ez az összeg azonban nem haladhatja meg a szállítmány számla szerinti értéke, a
                  fuvardíj és az út folyamán felmerülő költségek, ideértve a biztosítási díjat, valamint
                  ezek együttes összege után számított legfeljebb 10%-os felülbiztosítás együttes
                  összegét.<br />
                  A díjszámítás alapja a biztosítási összeg, a minimumdíj 15 000,- Ft/szállítás.<br />
                  A biztosító a biztosítási összeg keretén belül téríti meg az áru teljes vagy részleges
                  elveszéséből valamint sérüléséből eredő károkat.
                </p>
                <p>
                  <strong>Hogyan kell megkötni a biztosítást?</strong>
                </p>
                <p>
                  A biztosítási igényt a
                  <img class="em_bitmap" src="../../../assets/images/email/cmr.png" alt="e-mail" />
                  címre kérjük megküldeni. Munkatársaink ezután rövidesen megküldik a kapott adatok
                  alapján elkészített díjjavaslatunkat.
                </p>
                <h2 class="subtitle">Letölthető dokumentumok</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_szallitmany.pdf">Biztosítási termékismertető</a></li>

                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20240215.pdf">Szállítmánybiztosítási feltételcsomag (2024.02.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a><br /></li>

                  <!--<li>&nbsp;</li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_adatkero.pdf">Adatkérő szállítmánybiztosításhoz (egyutas)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_keret_adatkero.pdf">Adatkérő szállítmánybiztosításhoz (keret)</a></li>-->
                </ul>

                <h2 class="subtitle">Kárbejelentési dokumentumok</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_karbejelento.pdf">Szállítmánybiztosítás kárbejelentő lap</a></li>
                </ul>
                <h2 class="subtitle">Archívum</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20230615.pdf">Szállítmánybiztosítási feltételcsomag (2023.06.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20211220.pdf">Szállítmánybiztosítási feltételcsomag (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20210501.pdf">Szállítmánybiztosítási feltételcsomag (2021.05.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20200701.pdf">Szállítmánybiztosítási feltételcsomag (2020.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20190426.pdf">Szállítmánybiztosítási feltételcsomag (2019.04.26-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20190101.pdf">Szállítmánybiztosítási feltételcsomag (2019.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20181105.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.11.05-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20180808.pdf">Szállítmánybiztosítási feltételcsomag (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20180701.pdf">Szállítmánybiztosítási feltételcsomag (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20180525.pdf">Szállítmánybiztosítási feltételcsomag (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20180223.pdf">Szállítmánybiztosítási feltételcsomag (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20180101.pdf">Szállítmánybiztosítási feltételcsomag (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20170601.pdf">Szállítmánybiztosítási feltételcsomag (2017.06.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20170401.pdf">Szállítmánybiztosítási feltételcsomag (2017.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20170101.pdf">Szállítmánybiztosítási feltételcsomag (2017.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20160901.pdf">Szállítmánybiztosítási feltételcsomag (2016.09.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20160701.pdf">Szállítmánybiztosítási feltételcsomag (2016.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20160101.pdf">Szállítmánybiztosítási feltételcsomag (2016.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_feltetelcsomag_20150101.pdf">Szállítmánybiztosítási feltételcsomag (2015.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20150101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2015.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20131001.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2013.10.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_szerzodesi_feltetel_2013.pdf">Szállítmánybiztosítási feltételek (2012.01.29-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130701.pdf">Ügyféltájékoztató (2013.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmany_ugyfel_taj_20130301.pdf">Ügyféltájékoztató (2013.03.01-től hatályos)</a></li>
                </ul>
              </div>
              <div id="A2">
                <hr class="fancy-line" />
                <p>
                  &nbsp;
                </p>

                <h1>
                  Nemzetközi közúti árufuvarozói felelősségbiztosítás (CMR)
                </h1>
                <p>
                  &nbsp;
                </p>
                <p class="question">
                  <strong>Mi a CMR fogalma?</strong>
                </p>
                <p>
                  A CMR a Nemzetközi közúti árufuvarozási szerződésről szóló, az 1971. évi 3. törvényerejű
                  rendeletben kihirdetett „Nemzetközi Közúti Árufuvarozási Szerződésről szóló nemzetközi
                  egyezmény” (továbbiakban: CMR Egyezmény).
                </p>
                <p class="question">
                  <b>Mire nyújt fedezetet a CMR biztosítás?</b>
                </p>
                <p>
                  A Gránit Biztosító Zrt. a biztosítási szerződés alapján vállalja azokat
                  a kártérítési kötelezettségeket, melyeket a nemzetközi CMR Egyezmény alapján a fuvarozót
                  biztosítottként a megbízóval szemben terheli, így a fuvarozott áruban keletkezett
                  sérülés, elveszés következtében okozott károkra nyújt fedezetet.
                </p>
                <p>
                  A biztosítás kizárólag a biztosítási kötvényben megjelölt fuvareszközökkel, a biztosított
                  által saját nevében végzett fuvarozásokra érvényes.
                </p>
                <p class="question">
                  <b>Ki kötheti meg a biztosítási szerződést? </b>
                </p>
                <p>
                  A szerződést megkötheti bármely, nemzetközi közúti árufuvarozói tevékenység végzésére
                  jogosult, Magyarországon bejegyzett vállalkozás.
                </p>
                <p class="question">
                  <b>Mi a biztosítás területi és időbeli hatálya?</b>
                </p>
                <p>
                  A Biztosító kockázatviselése Európa országaira terjed ki földrajzi értelemben véve,
                  beleértve Nagy-Britannia, Írország és Törökország ázsiai részét is.
                </p>
                <p>
                  A biztosítási fedezet nem terjed ki az ENSZ embargó alatt álló országokra, valamint
                  azok tranzitjára sem.
                </p>
                <p>
                  A biztosítási fedezet a biztosítási szerződés hatálya alatt okozott, bekövetkezett
                  és a biztosítónak legkésőbb a szerződés megszűnését követő 60 napon belül bejelentett
                  károkra terjed ki.
                </p>
                <p class="question">
                  <b>Mi határozza meg a biztosítási díjat?</b>
                </p>
                <p>
                  a gépjármű össztömege<br />
                  az éves, káreseményenkénti kártérítési limitek
                </p>
                <p>
                  Ez éves díjak egy fuvareszközre vonatkozó éves alapdíjak. A fuvareszköz lehet egy
                  darab tehergépjármű vagy járműszerelvény. Járműszerelvényen olyan összekapcsolt
                  járművek értendők, melyek a forgalomban egyetlen egységként vesznek részt.
                </p>
                <p class="question">
                  <b>Milyen kártérítési limitek választhatóak?</b>
                </p>
                <p>
                  A biztosított igénye szerint választható:
                </p>
                <p>
                  50.000 EUR, 100.000 EUR, 200.000 EUR, 300.000 EUR vagy
                </p>
                <p>
                  50.000 USD, 100.000 USD, 200.000 USD, 300.000 USD.
                </p>
                <p class="question">
                  <b>További információra van szüksége?</b>
                </p>
                <p>
                  <a [routerLink]="['/gyik']">Gyakran ismételt kérdések menü alatt</a> részletes tájékoztatást
                  nyújtunk Önnek a CMR biztosítás szolgáltatásáról, a szerződés megkötésére és megszűntetésére
                  vonatkozó tudnivalókról, a kockázatban állásról és a kárügyintézésről.
                </p>
                <p>
                  Kérdés esetén készséggel állunk rendelkezésére hívja bizalommal ügyfélszolgálati
                  munkatársainkat. Adminisztratív ügyintézés és Kárrendezési hely: 1211 Budapest,
                  Szállító u. 4. Tel.: 06 1 6666 200
                </p>
                <h2 class="subtitle">
                  Letölthető dokumentumok
                </h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_cmr.pdf">Biztosítási termékismertető</a></li>

                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20240215.pdf">CMR biztosítási feltételcsomag (2024.02.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a><br /></li>
                  <!--<li>&nbsp;</li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_egyezmeny.pdf">Egyezmény a nemzetközi közúti árufuvarozói szerződésről</a></li>-->
                </ul>
                <h2 class="subtitle">
                  Archívum
                </h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20230615.pdf">CMR biztosítási feltételcsomag (2023.06.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20211220.pdf">CMR biztosítási feltételcsomag (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20210501.pdf">CMR biztosítási feltételcsomag (2021.05.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20200701.pdf">CMR biztosítási feltételcsomag (2020.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20190426.pdf">CMR biztosítási feltételcsomag (2019.04.26-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20190101.pdf">CMR biztosítási feltételcsomag (2019.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20181105.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.11.05-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20180808.pdf">CMR biztosítási feltételcsomag (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20180701.pdf">CMR biztosítási feltételcsomag (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20180525.pdf">CMR biztosítási feltételcsomag (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20180223.pdf">CMR biztosítási feltételcsomag (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20180101.pdf">CMR biztosítási feltételcsomag (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20170601.pdf">CMR biztosítási feltételcsomag (2017.06.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20170401.pdf">CMR biztosítási feltételcsomag (2017.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20170101.pdf">CMR biztosítási feltételcsomag (2017.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20160901.pdf">CMR biztosítási feltételcsomag (2016.09.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20160701.pdf">CMR biztosítási feltételcsomag (2016.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20160101.pdf">CMR biztosítási feltételcsomag (2016.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20150101.pdf">CMR biztosítási feltételcsomag (2015.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20150101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2015.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20130129.pdf">CMR biztosítási feltételcsomag (2013.01.29-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_feltetelcsomag_20120921.pdf">CMR biztosítási feltételcsomag (2012.09.21-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_kozuti.pdf">Nemzetközi Közúti árufuvarozói felelősség biztosítás (CMR) különös feltételek és záradékok</a></li>
                </ul>
                <h2 class="subtitle">
                  Kárbejelentési dokumentumok
                </h2>

                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_karbejelento_20120921.pdf">CMR kárbejelentő lap</a></li>
                </ul>
              </div>
              <div id="A3">
                <hr class="fancy-line" />
                <p>&nbsp;</p>

                <h1>Belföldi közúti árutovábbítási felelősségbiztosítás</h1>
                <h2 class="subtitle">
                  Kárbejelentési dokumentumok
                </h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_kaf_karbejelento_20160101.pdf">BÁF/KÁF kárbejelentő lap</a></li>
                </ul>
                <p>&nbsp;</p>
                <h2 class="subtitle">Letölthető dokumentumok:</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_baf.pdf">Biztosítási termékismertető</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20240215.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2024.02.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a><br /></li>
                </ul>
                <hr class="fancy-line" />
                <h2 class="subtitle">
                  Archívum
                </h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20230615.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2023.06.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20211220.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20210501.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2021.05.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20200701.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2020.07.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20190426.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2019.04.26-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20190101.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2019.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20181105.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.11.05-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20180808.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20180701.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20180525.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20180223.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20180101.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20170601.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2017.06.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20170401.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2017.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20170101.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2017.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20160901.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2016.09.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20160801.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2016.08.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20160701.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2016.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_feltetelcsomag_20160101.pdf">BÁF Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók, Általános Kárbiztosítási Feltételek, Különös Feltételek és Záradékok (2016.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20150101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2015.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20131001.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2013.10.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130701.pdf">Ügyféltájékoztató 2013.07.01-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130301.pdf">Ügyféltájékoztató 2013.03.01-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_adatkez_taj_20120120.pdf">Ügyfél- és Adatkezelési tájékoztató 2012. január 20-tól</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_vagyonbizt.pdf">Általános vagyonbiztosítási feltételek</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_allomanyvaltozas.pdf">Állományváltozás bejelentő lap</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20120101.pdf">Ügyféltájékoztató 2012. január 1-jétől</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20110701.pdf">Ügyféltájékoztató 2011. július 1-jétől</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_ugyfeltajekoztato.pdf">Ügyféltájékoztató 2010. szeptember 10-jétől</a></li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
