import { Component, OnInit } from '@angular/core';
import { UtilityService } from '@granit/common-library';

@Component({
  selector: 'gyik',
  templateUrl: './gyik.component.html',
  styleUrls: ['./gyik.component.scss']
})
export class GyikComponent implements OnInit {

  panelOpenState = false;
  onlineKarbejelentoLink: string = "";

  constructor(private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.onlineKarbejelentoLinkLekerese();
  }

  async onlineKarbejelentoLinkLekerese() {
    let model = {
      key: "OnlineKarbejelentoLink"
    }
    this.onlineKarbejelentoLink = await this.utilityService.appsettingsKeyErtekLekerese(model);
  }
}
