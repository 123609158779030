<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">ONLINE PANASZBEJELENTÉS</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">

              <p>
                <span>Társaságunk felelős és tisztességes szolgáltatói magatartása ellenére előfordulhat, hogy tájékoztatási, szerződéskezelési, kárrendezési szolgáltatásunkkal nem teljes mértékben elégedett, Társaságunk tevékenységét reklámküldemény címzettjeként, adatkezelés érintettjeként vagy egyéb minőségében kifogásolja, vagy valamely mulasztásra hívja fel figyelmünket.</span>
              </p>
              <p>
                <span>Az esetleges panaszok telefonon, személyesen, vagy írásban való közlése mellett biztosított online bejelentő rendszerünk célja a gyorsabb és hatékonyabb panaszkezelés.</span>
              </p>
              <p>
                <span>Amennyiben a panaszához dokumentumokat kíván csatolni, kérjük, szíveskedjék azok másolatát a Biztosító székhelyére (1211 Budapest, Szállító utca 4.) központi levélcímére (1443 Budapest, Pf. 180.) megküldeni.</span>
              </p>
              <p>
                <span>Meghatalmazott (képviselő) útján eljáró ügyfél esetében kérjük a közokiratba vagy teljes bizonyító erejű magánokiratba foglalt meghatalmazást a Biztosító székhelyére (1211 Budapest, Szállító utca 4.) központi levélcímére (1443 Budapest, Pf. 180.) megküldeni.</span>
              </p>
              <p>
                <span>Mielőtt panaszát megtenné, kérjük, szíveskedjék elolvasni az online panaszbejelentő rendszerben való bejelentéssel összefüggésben a Biztosító által végzett adatkezelésére vonatkozó tájékoztatót, amely <a href="/docs/adatkezelesi_tajekoztato_online panaszbejelento.pdf" target="_blank">itt elérhető.</a></span>
              </p>
              <div class="wide">
                <div class="div_cim">
                  ÜGYFÉL / FOGYASZTÓ ADATAI
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">Név:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class=" me-2 w-50">
                      <input type="text" id="field4" name="field4" class="form-control" required>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">Levelezési cím:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class=" me-2 w-50">
                      <input type="text" id="field4" name="field4" class="form-control" required>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">Telefon:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class=" me-2 w-50">
                      <input type="text" id="field4" name="field4" class="form-control" required>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">E-mail:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class=" me-2 w-50">
                      <input type="text" id="field4" name="field4" class="form-control" required>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">E-mail ismét:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class=" me-2 w-50">
                      <input type="text" id="field4" name="field4" class="form-control" required>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">Minősége:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class=" me-2 w-50">
                      <input type="text" id="field4" name="field4" class="form-control" required>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">Képviselő neve:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class=" me-2 w-50">
                      <input type="text" id="field4" name="field4" class="form-control" required>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">Képviselő levelezési címe:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class=" me-2 w-50">
                      <input type="text" id="field4" name="field4" class="form-control" required>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>


                <br />
                <div class="div_cim">
                  PANASZ OKA
                </div>

                <p>
                  <span>Az alábbiakban megjelölt okok közül több is megjelölhető.</span>
                </p>
                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Hiányos tájékoztatás
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Téves tájékoztatás
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kiszolgálás körülményei
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Szerződéskötés körülményei
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Biztosítási ajánlat elutasítása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Díjeltérés az ajánlatban megjelölt összegtől
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Számla, csekk küldésének késedelme, elmaradása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kötvény küldésének késedelme, elmaradása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kedvezményezett záradékolásának hiánya
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Számla összegének értelmezése
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Fedezetlenségi díj jogalapja
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Fedezetlenségi díj összege
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Bónusz–Malusz besorolás
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Biztosítási díj módosítása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Biztosítási összeg módosítása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Egyéb szerződésmódosítás
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Szerződési feltételekre vonatkozó panasz
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Szerződés felmondása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Szerződés megszűnése, megszüntetése (törlése)
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Törlésértesítő késedelme, elmaradása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kárrendezés elhúzódása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kárbecslés, kárszakértés
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Biztosító szolgáltató partnerének eljárása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kártérítési (szolgáltatási) igény elutasítása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kártérítés (szolgáltatás) mértéke, összege
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kártérítés (szolgáltatás) késedelme
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Egyéb
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">A panasz oka és leírása, a panasz tárgyát képező esemény vagy tény megjelölése, a bejelentést tevő ügyfél álláspontja:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class="me-2">
                      <textarea cols="80" rows="10" class="tesxt-area form-control" required></textarea>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">Szerződési ajánlat száma, szerződés azonosító száma (kötvényszám), kárszám, egyéb:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class="me-2">
                      <textarea cols="80" rows="2" class="tesxt-area form-control" required></textarea>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="div_cim">
                  ÜGYFÉL IGÉNYE
                </div>
                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Tájékoztatás megadása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Számla, csekk küldése
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Szerződéskötés körülményeinek kivizsgálása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kötvény küldése
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kedvezményezett záradékolása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Szerződés megszüntetése
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Szerződés helyreállítása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Bónusz-Malusz fokozat helyreállítása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kártérítés összegének módosítása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Kártérítés fizetése, kárrendezés befejezése
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Késedelmi kamat és költség megfizetése
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>

                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Más szolgáltatás nyújtása
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-md-4 d-flex align-items-center">
                    <div class="me-2 form-label strong checkbox">
                      <input type="checkbox"> Egyéb
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-md-4">
                    <label for="field3" class="form-label strong">Megjegyzés, észrevétel:</label>
                  </div>
                  <div class="col-md-7 d-flex align-items-center">
                    <div class="me-2">
                      <textarea cols="80" rows="10" class="tesxt-area form-control" required></textarea>
                      <div class="invalid-feedback">
                        Kötelező mező
                      </div>
                    </div>
                    <div class="col-md-1">
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

