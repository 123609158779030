<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">KÁRRENDEZÉSI TÁJÉKOZTATÓK</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <h2 class="subtitle"><strong>Letölthető dokumentumok</strong></h2>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_karrendezesi_tajekoztato.pdf">BÁF kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}casco_gepjarmu_karrendezesi_tajekoztato.pdf">Casco gépjármű kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}cmr_karrendezesi_tajekoztato.pdf">CMR  kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}esely_daganatdiagnosztikai_egeszsegbiztositashoz_karrendezesi_tajekoztato.pdf">Esély Daganatdiagnosztikai Egészségbiztosításhoz kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}hazmester_karrendezesi_tajekoztato.pdf">Házmester kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_karrendezesi_tajekoztato.pdf">KÁF kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kamionos_felelossegbiztositasi_karrendezesi_tajekoztato.pdf">Kamionos felelősségbiztosítási kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_gepjarmu_karrendezesi_tajekoztato.pdf">KGFB gépjármű kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_karrendezesi_tajekoztato_szemelyi_serules.pdf">KGFB kárrendezési tájékoztató - személyi sérülés</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_karrendezesi_tajekoztato.pdf">KSZF kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}lakas_karrendezesi_tajekoztato.pdf">Lakás kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}mfo_karrendezesi_tajekoztato.pdf">MFO kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szakmai_felelossegbiztositasi_karrendezesi_tajekoztato.pdf">Szakmai felelősségbiztosítási kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}szallitmanybiztositas_karrendezesi_tajekoztato.pdf">Szállítmánybiztosítás kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}trafik_karrendezesi_tajekoztato.pdf">Trafik kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}vallalkozoi_vagyonbiztositas_karrendezesi_tajekoztato.pdf">Vállalkozói vagyonbiztosítás kárrendezési tájékoztató</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}utasbiztositasi_karrendezesi_tajekoztato.pdf">Utasbiztosítási kárrendezési tájékoztató</a></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
