<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">FOGYASZTÓI TÁJÉKOZTATÁS, PANASZKEZELÉS</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <p>
                Társaságunk hosszú távú, kölcsönös előnyökön nyugvó, stabil partnerkapcsolat kialakításában
                érdekelt, ezért felelős szolgáltatóként elkötelezett abban, hogy a fogyasztóvédelmi
                előírásoknak való megfelelést ne csak a belső üzletmenetében, hanem a fogyasztókkal
                kapcsolatos magatartásában is szem előtt tartsa.
              </p>
              <p>
                A felelős és tisztességes szolgáltatói magatartás elérése, valamint az általános
                fogyasztói megítélés javítása iránt érzett és felvállalt felelősség mellett nem
                elhanyagolható a fogyasztók részéről megvalósuló felelős és körültekintő eljárás.
              </p>
              <p>
                Társaságunk által gazdasági reklámmal megszólított, a biztosítási termékeink iránt
                érdeklődő, a már megkötött biztosítási szerződésével kapcsolatban felvilágosítást
                kérő, vagy biztosítási esemény bekövetkezése esetén biztosítási szolgáltatási (kártérítési)
                igényével fellépő fogyasztó jogosult kimerítő, közérthető, egyértelmű és pontos
                tájékoztatásra, továbbá az igényének határidőben történő érdemi elbírálására, illetőleg
                a megkötött szerződés feltételeiben foglaltak szerinti teljesítésére.
              </p>
              <p>
                Amennyiben munkatársaink segítő közreműködése ellenére sem sikerül problémáját
                vagy szolgáltatási igényét megnyugtatóan rendezni, a Társaságunk magatartására,
                tevékenységére vagy mulasztására vonatkozó panaszának – a fogyasztó azonosításra
                alkalmas tartalommal történő – előterjesztésére az alábbi módok állnak rendelkezésére:
              </p>
              <p>
                <b>Szóban személyesen</b> a Központi Ügyfélszolgálat nyitva tartási idejében jelezheti
                panaszát. Amennyiben a panasz azonnali kivizsgálása nem lehetséges, vagy a panaszkezeléssel
                nem ért egyet, munkatársunk panaszfelvételi jegyzőkönyvet vesz fel, amelynek egy
                példányát átadja Önnek.
              </p>
              <table class="table table-striped table-borderless">
                <tbody>
                  <tr>
                    <td colspan="2">
                      Központi Ügyfélszolgálat címe:
                    </td>
                    <td>
                      &nbsp;
                    </td>
                    <td>
                      1211 Budapest, Szállító u. 4.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Nyitva tartás:
                    </td>
                    <td>
                      Hétfő:
                    </td>
                    <td>
                      &nbsp;
                    </td>
                    <td>
                      7:00–19:00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                    </td>
                    <td>
                      Kedd–Csütörtök:
                    </td>
                    <td>
                      &nbsp;
                    </td>
                    <td>
                      8:00–16:00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                    </td>
                    <td>
                      Péntek:
                    </td>
                    <td>
                      &nbsp;
                    </td>
                    <td>
                      8:00–15:00
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <b>Szóban telefonon</b> a Call Center munkatársai fogadják hívását. Telefonos panaszkezelés
                esetén a beszélgetés hanganyaga rögzítésre kerül, amelyet kérésére visszahallgathat,
                vagy igény esetén térítésmentesen rendelkezésére bocsátjuk a hangfelvételről készített
                hitelesített jegyzőkönyvet.
              </p>
              <table class="table table-striped table-borderless">
                <tbody>
                  <tr>
                    <td colspan="3">
                      Telefonos panaszügyintézés ideje:
                    </td>
                    <td colspan="2">
                      Hívható telefonszámok:
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Hétfő:
                    </td>
                    <td>
                      7:00–19:00
                    </td>
                    <td>
                      &nbsp;
                    </td>
                    <td>
                      (+36-1) 999-9999
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kedd–Csütörtök:
                    </td>
                    <td>
                      8:00–16:00
                    </td>
                    <td>
                      &nbsp;
                    </td>
                    <td>
                      (+36-1) 666-6200
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Péntek:
                    </td>
                    <td>8:00–16:00</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Amennyiben a panasz azonnali kivizsgálása nem lehetséges, vagy a panaszkezeléssel
                nem ért egyet, munkatársunk a panaszról jegyzőkönyvet vesz fel, amelynek egy példányát
                a panaszra adott írásbeli válasszal egyidejűleg küldünk meg az Ön részére.
              </p>
              <p>
                <b>Írásban személyesen vagy más által átadott irat útján</b> közölt panaszát Központi
                Ügyfélszolgálatunk nyitva tartási idejében adhatja át munkatársainknak.
              </p>
              <p><b>Postai úton</b> a panasz a következő címekre küldhető:</p>
              <table class="table table-striped table-borderless">
                <tbody>
                  <tr>
                    <td>Székhely:</td>
                    <td>Gránit Biztosító Zrt. 1211 Budapest, Szállító u. 4.</td>
                  </tr>
                  <tr>
                    <td>Központi levélcím:</td>
                    <td>Gránit Biztosító Zrt. 1443 Budapest, Pf. 180</td>
                  </tr>
                </tbody>
              </table>
              <p>&nbsp;</p>              
              <p>
                <b>E-mailen</b> panaszát a
                <img src="../../../assets/images/email/panasz.png" class="lev" style="vertical-align: middle;" />
                elektronikus postafiók címen fogadjuk.
              </p>
              <p>
                Panaszát a Panaszbejelentő lap nyomtatványunk kitöltése útján is előterjesztheti.
              </p>
              <p>
                Társaságunk a beérkezett panaszokat a Panaszkezelési Szabályzata előírásainak megfelelően kezeli.
                Amennyiben a panaszának kivizsgálása eredményeként közölt álláspontunkkal nem ért egyet a Magyar Nemzeti Bankról szóló
                2013. évi CXXXIX. törvény szerinti fogyasztóvédelmi rendelkezések megsértése esetén a Magyar Nemzeti Banknál
                (Levelezési cím: Magyar Nemzeti Bank, 1850 Budapest Telefon: +36-80-203-776, Fax: +36-1-429-8000, E-mail cím: <a href="mailto:ugyfelszolgalat@mnb.hu">ugyfelszolgalat@mnb.hu</a>, Honlap: <a href="https://www.mnb.hu/fogyasztovedelem" target="_blank">https://www.mnb.hu/fogyasztovedelem</a>)
                fogyasztóvédelmi eljárást kezdeményezhet, vagy a szerződés létrejöttével, érvényességével, joghatásaival és megszunésével, továbbá a szerződésszegéssel és annak joghatásaival kapcsolatos
                jogvita esetén a bírósághoz fordulhat vagy a Pénzügyi Békéltető Testület (Levelezési cím: 1525 Budapest Pf. 172. Telefon: +36-80-203-776, Fax: +36-1-489-91-02, E-mail cím: <a href="mailto:ugyfelszolgalat@mnb.hu">ugyfelszolgalat@mnb.hu</a>, Honlap: <a href="https://www.mnb.hu/bekeltetes/" target="_blank">https://www.mnb.hu/bekeltetes/</a>)
                eljárását kezdeményezheti.<br />
                <br />
                Székhely cím: 1013 Budapest Krisztina krt. 55.<br />
                Ügyfélszolgálat: 1122 Budapest, Krisztina krt. 6.
              </p>

              <p>
                Felhívjuk ügyfeleink figyelmét felügyeleti szervünk, a Magyar Nemzeti Bank
                fogyasztóvédelmi honlapjára <a href="https://www.mnb.hu/fogyasztovedelem" target="_blank">https://www.mnb.hu/fogyasztovedelem</a>, ahol hasznos információkat olvashat.
              </p>
              <p>
                A Magyar Nemzeti Bank fogyasztóvédelmi oldalai és online alkalmazásai
                pontos és aktuális elérhetőségei az alábbiak:
              </p>
              <p><span>MNB Ügyfélszolgálat</span>: <a href="https://www.mnb.hu/fogyasztovedelem" target="_blank">https://www.mnb.hu/fogyasztovedelem</a></p>
              <p>Az MNB online alkalmazásai: <a href="https://www.mnb.hu/felugyelet" target="_blank">https://www.mnb.hu/felugyelet</a></p>
              <p>Bankszámlaválasztó program: <a href="http://alk.mnb.hu/fogyasztoknak/alkalmazasok/szvp" target="_blank">http://alk.mnb.hu/fogyasztoknak/alkalmazasok/szvp</a></p>
              <p>Betét és megtakarítás kereső program: <a href="https://betetvalaszto.mnb.hu/" target="_blank">https://betetvalaszto.mnb.hu/</a></p>
              <p>Hitel- és lízingtermék választó program: <a href="https://hitelvalaszto.mnb.hu/" target="_blank">https://hitelvalaszto.mnb.hu/</a></p>
              <p>MFO – Minősített Fogyasztóbarát Otthonbiztosítás összehasonlító link: <a href="https://www.mnb.hu/mfo" target="_blank">https://www.mnb.hu/mfo</a></p>
              <p>Terméktáblák: <a href="https://www.mnb.hu/felugyelet" target="_blank">https://www.mnb.hu/felugyelet</a></p>
              <p>Háztartási költségvetés számító: <a href="https://haztartasikoltsegveteskalkulator.mnb.hu/" target="_blank">https://haztartasikoltsegveteskalkulator.mnb.hu/</a></p>
              <p>
                A fogyasztói jogviták bírósági eljáráson kívüli, alternatív rendezési fórumaként
                eljáró Pénzügyi Békéltető Testület szervezetére, működésére, eljárására, hatáskörére, továbbá
                elérhetőségére vonatkozó információk, a <a href="https://www.mnb.hu/bekeltetes/" target="_blank">https://www.mnb.hu/bekeltetes/</a> honlapon érhetők el.
              </p>
              <h2 class="subtitle"><b>Letölthető dokumentumok</b></h2>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fogyasztoi-panasz-szolgaltatonak-2020.pdf">Pénzügyi szervezethez benyújtandó panasz</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}panaszkezelesi_szabalyzat_20240501.pdf">Panaszkezelési szabályzat</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}meghatalmazas_panaszugyben.pdf">Meghatalmazás panaszeljárásban történő képviselethez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}pbt_fogyasztoi_kerelem_nyomtatvany.pdf">Pénzügyi Békéltető Testület Fogyasztói Kérelem nyomtatvány</a></li>
              </ul>
              <h2 class="subtitle"><b>Kapcsolódó oldal</b></h2>
              <ul>
                <li><a [routerLink]="['/panaszbejelentes']" target="_blank">On-line panaszbejelentés</a></li>
              </ul>
              <h2 class="subtitle"><b>Online vitarendezési platform</b></h2>
              <p>&nbsp;</p>
              <p><strong>Tisztelt Ügyfeleink!</strong></p>
              <p>Az Európai Parlament és a Tanács fogyasztói jogviták online rendezéséről szóló, 2013. május 21-i 524/2013/EU rendelete (Rendelet) alapján az Európai Bizottság létrehozott egy online vitarendezési platformot.</p>
              <p>A Rendelet szerint az Európai Unióban tartózkodási hellyel rendelkező fogyasztók és az Európai Unióban letelepedett szolgáltatók közötti, online szolgáltatási szerződésekből eredő kötelezettségekkel kapcsolatban felmerülő jogviták, így az online megkötött szerződésekkel összefüggő pénzügyi fogyasztói jogviták bírósági eljáráson kívüli rendezésére irányuló kommunikációt ezen a platformon keresztül is kell a fogyasztóknak biztosítani.</p>
              <p>Az online vitarendezési platform 2016. február 15-től elérhető a fogyasztók számára.</p>
              <p>A Rendelet hatálya közvetlenül kiterjed a Magyarországon székhellyel rendelkező pénzügyi szolgáltatóra, így a Gránit Biztosító Zrt.-re is, amennyiben közte és a fogyasztó között létrejött online szolgáltatási szerződéssel kapcsolatban pénzügyi fogyasztói jogvita merül fel. A fogyasztó a Rendelet szerint online vitarendezési platformon keresztül online kezdeményezheti a jogvita bírósági eljáráson kívüli rendezését a közösen megválasztott vitarendezési fórumnál. Magyarországon a pénzügyi fogyasztói jogviták rendezésére a Pénzügyi Békéltető Testület jogosult.</p>
              <ul>
                <li><a href="http://ec.europa.eu/odr" target="_blank">Online vitarendezési platform</a></li>
                <li>
                  <a href="https://webgate.ec.europa.eu/odr/userguide/" target="_blank">Felhasználói útmutatók az online vitarendezési platform honlapján</a><br />
                </li>
              </ul>

              <h2 class="subtitle"><b>Egyéb letölthető dokumentumok</b></h2>

              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}2016_evi_eves_beszamolo.pdf">2016. évi éves beszámoló</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}2016_annual_financial_statements.pdf">2016. Annual Financial Statements</a></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
