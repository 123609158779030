<div class="main-container">
  <app-header></app-header>
  <mat-sidenav-container>    
    <mat-sidenav-content class="page-wrapper">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <app-footer></app-footer>
</div>
