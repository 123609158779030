import { Component, inject, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { HibaService, UtilityService } from '@granit/common-library';
import { WeboldalService } from '../../../services/weboldal.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';


@Component({
  selector: 'vodafone',
  templateUrl: './vodafone.component.html',
  styleUrls: ['./vodafone.component.scss']
})
export class VodafoneComponent implements OnInit {

  formGroup: FormGroup;
  hibaLista: any[] = [];
  ugyfelElegedettsegLista: any[] = [];
  aktEv: string = (new Date()).getFullYear().toString();
  aktEvPelda: string = "pl. " + this.aktEv;
  alapdij: string = "0";
  ajanlott_ingosag_bo: string = "0";
  ajanlott_ingatlan_bo: string = "0";
  hazmesterKalkLink: string = "";
  onlineKarbejelentoLink: string = "";

  constructor(private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    public weboldalService: WeboldalService,
    private recaptchaV3Service: ReCaptchaV3Service,
    public hibaService: HibaService) { }

  ngOnInit(): void {

    this.hibaLista = [];
    this.linkek();
    this.formGroupInit();
    this.scriptek();
    this.ugyfelElegedettseg();
  }

  async linkek() {

    let model = {
      key: "HazmesterKalkLink"
    }
    this.hazmesterKalkLink = await this.utilityService.appsettingsKeyErtekLekerese(model);

    model = {
      key: "OnlineKarbejelentoLink"
    }
    this.onlineKarbejelentoLink = await this.utilityService.appsettingsKeyErtekLekerese(model);
  }

  scrollToelement(element: string) {
    document.getElementById(element).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scriptek() {
    let script = this._renderer2.createElement('script');
    script.text = `var swiper = new Swiper(".TestimonialSlider", {
      slidesPerView: 1,
      spaceBetween: 16,
	  navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 16,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      },
    });`;

    this._renderer2.appendChild(this._document.body, script);

    script = this._renderer2.createElement('script');
    script.text = `(() => {
                'use strict'
                const forms = document.querySelectorAll('.needs-validation')
                Array.from(forms).forEach(form => {
                  form.addEventListener('submit', event => {
                    if (!form.checkValidity()) {
                      event.preventDefault()
                      event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                  }, false)
                })
              })()`;

    this._renderer2.appendChild(this._document.body, script);
  }

  formGroupInit() {

    this.formGroup = this.formBuilder.group({
      f_irsz: new FormControl(null, Validators.required),
      f_terulet: new FormControl(null, [Validators.required, validateMinMax(10, 600)]),
      f_jelleg: new FormControl("", Validators.required),
      f_lakoszam: new FormControl(null, [Validators.required, validateMinMax(0, 7)]),
      f_epitesev: new FormControl(null, [Validators.required, validateMinMax(1800, Number(this.aktEv))]),
      f_kuponkod: new FormControl(null, [Validators.required, validateKuponkod])
    });
  }

  validateInput(field: HTMLInputElement, control_nev: string) {

    const control = this.formGroup.controls[control_nev];

    if (control.errors) {
      for (let error in control.errors)
        field.setCustomValidity(
          control.errors[error]
        );
    } else {
      // No error
      field.setCustomValidity('');
    }
  }
  
  ugyfelElegedettseg() {

    this.ugyfelElegedettsegLista = [];

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('weblap_ugyfelElegedettseg').toPromise().then(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          this.weboldalService.ugyfelElegedettseg().subscribe(
            data => {
              if (data && data.length > 0) {

                this.ugyfelElegedettsegLista = data;
              }
              else {
                this.formGroup.controls["f_irsz"].setErrors({ nincsTelepules: true });
              }
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a települések lekérdezése közben",
                confirmButtonText: 'Rendben'
              });
            });
        });
      });
  }
  
  dijszamitas() {

    this.hibaLista = [];

    if (this.formGroup.valid) {
      this.recaptchaV3Service.execute('weblap_dijszamitas').toPromise().then(
        token => {
          let model = { encodedResponse: token }
          // Recaptcha szerver validálás
          this.utilityService.recaptchaValidalas(model).subscribe(result => {

            let model = {
              f_irsz: this.formGroup.controls["f_irsz"].value,
              f_terulet: this.formGroup.controls["f_terulet"].value,
              f_jelleg: this.formGroup.controls["f_jelleg"].value == "" ? null : this.formGroup.controls["f_jelleg"].value,
              f_lakoszam: this.formGroup.controls["f_lakoszam"].value,
              f_epitesev: this.formGroup.controls["f_epitesev"].value,
              f_kuponkod_tipus: 1, /*vodafone*/
              f_kuponkod: this.formGroup.controls["f_kuponkod"].value
            }

            this.weboldalService.dijszamitas(model).subscribe(
              data => {
                if (data) {
                  if (data["eredmenykod"] == 0) {
                    this.alapdij = this.ezresSzeparator(data["evesdij"])
                    this.ajanlott_ingosag_bo = this.ezresSzeparator(data["ajanlott_ingosag_bo"]);
                    this.ajanlott_ingatlan_bo = this.ezresSzeparator(data["ajanlott_ingatlan_bo"]);
                  }
                  else {
                    this.hibaLista = data["hibak"];
                  }
                  this.scrollToelement("A1")
                }
                else {

                }
              },
              errorResult => {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hiba a díjadatok lekérése közben",
                  confirmButtonText: 'Rendben'
                });
              });
          });
        });
    }
  }

  ezresSzeparator(adat: number): string {
    let str = String(adat);
    return str.split('').reduce(
      (a, b, i) => a + (i && !((str.length - i) % 3) ? '.' : '') + b,
      ''
    )
  }

  megkotom() {
    if (this.formGroup.valid) {
      this.recaptchaV3Service.execute('weblap_megkotom').toPromise().then(
        token => {
          let model = { encodedResponse: token }
          // Recaptcha szerver validálás
          this.utilityService.recaptchaValidalas(model).subscribe(result => {

            let model = {
              f_irsz: this.formGroup.controls["f_irsz"].value,
              f_terulet: this.formGroup.controls["f_terulet"].value,
              f_jelleg: this.formGroup.controls["f_jelleg"].value == "" ? null : this.formGroup.controls["f_jelleg"].value,
              f_lakoszam: this.formGroup.controls["f_lakoszam"].value,
              f_epitesev: this.formGroup.controls["f_epitesev"].value,
              f_kuponkod_tipus: 1, /*vodafone*/
              f_kuponkod: this.formGroup.controls["f_kuponkod"].value
            }

            this.weboldalService.megkotom(model).subscribe(
              data => {
                if (data) {
                  let link = this.hazmesterKalkLink + "?uid=" + data["f_uid"] + "&alkusz=0625";
                  window.location.href = link;

                  /*const browser = Bowser.getParser(window.navigator.userAgent);

                  let link = this.hazmesterKalkLink + "?uid=" + data["f_uid"] + "&alkusz=0625";;
                  if (browser.getBrowserName(true) == "safari") {
                    window.location.href = link;
                  }
                  else {
                    window.open(link, "_blank");
                  }*/

                }
                else {

                }
              },
              errorResult => {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hiba a díjadatok mentése közben",
                  confirmButtonText: 'Rendben'
                });
              });
          });
        });
    }
  }
}

export function validateMinMax(min: number, max: number): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    const value = formControl.value;
    if (value && value < min) {
      return { kissebb: true };
    }
    else if (value && value > max) {
      return { nagyobb: true };
    } return null;
  }
}

export function validateKuponkod(formControl: AbstractControl): { [key: string]: any } | null {

    const value = formControl.value;

    if (value && value.length == 9) {
      let string = value.replace("-", "").substring(0, 6);
      let alap = 0;
      for (let i = 0; i < string.length; i++) {
        alap += parseInt(string[i], 16);
      }
      if (alap.toString(16).toUpperCase() != value.substring(7)) {
        return { hibasKuponKod: true };
      }
    } return null;  

}

