
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UgyfeleinknekService {
  private url: string = "/api/ugyfeleinknek";

  constructor(private httpClient: HttpClient) {
  };



  // Ügyfél elégeddetség lista
  elegedettsegmeresFormBetoltes(model: any) {
    return this.httpClient.post<any>(this.url + "/ElegedettsegmeresFormBetoltes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Elégedettségmérés form mentés
  elegedettsegmeresFormMentes(model: any) {
    return this.httpClient.post<any>(this.url + "/ElegedettsegmeresFormMentes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
