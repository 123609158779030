import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kozzetetelek',
  templateUrl: './kozzetetelek.component.html',
  styleUrls: ['./kozzetetelek.component.scss']
})
export class KozzetetelekComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
