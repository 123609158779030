<div class="bg-primary kosd-grid-wrapper" style="">
  <section class="text-white mb-0 pt-5 kosd-vodafone-grid-header">
  </section>
  <section class="mb-4 pb-3  text-white kosd-grid-body" style="">
    <div class="container pt-5 pb-5">
      <div class="row pt-4">
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-4 py-4 px-lg-5 d-flex flex-column align-items-start justify-content-start">
              <h1 class="bold h1-extra px-lg-4">Kösd újra!</h1>
              <div class="px-lg-4">
                Kösd meg egyszerűen online,<br>
                Vodafone kedvezménnyel!
              </div>
            </div>
            <div class="col-lg-6">
              <form class="needs-validation position-relative px-lg-5" id="KosdForm" novalidate style="z-index:4;" [formGroup]="formGroup">
                <div class="bg-primary d-flex flex-column form-box rounded-4">
                  <div class="form-box-form bg-info p-5 text-dark rounded-4 rounded-bottom-0 shadow-lg">
                    <div class="row">
                      <div class="col-12 col-lg-6 pb-4">
                        <div class="bold text-start text-primary h3">
                          Lakásbiztosítás
                          kalkulátor
                        </div>
                      </div>

                    </div>
                    <div class="row">

                      <div class="col-lg-6 mb-3">
                        <label for="GranitFormINput01" class="form-label text-uppercase strong text-primary small">Irányítószám</label>
                        <input type="text" pattern="[1-9][0-9]{3}" class="form-control mb-0" id="GranitFormINput01" placeholder="pl. 1211" name="GranitFormINput01" required formControlName="f_irsz">
                        <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_irsz'].hasError('required')">
                          Kötelező mező
                        </div>
                        <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_irsz'].hasError('pattern')">
                          Hibás irányítószám
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <label for="GranitFormINput02" class="form-label text-uppercase strong text-primary small">Alapterület:</label>
                        <div class="input-group mb-3">
                          <input #GranitFormINput02 type="number" class="form-control mb-0" id="GranitFormINput02" placeholder="pl. 42" name="GranitFormINput02" required formControlName="f_terulet" (input)="validateInput(GranitFormINput02, 'f_terulet')"> <span class="input-group-text text-light" id="basic-addon2">m&#xb2;</span>
                          <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_terulet'].hasError('required')">
                            Kötelező mező
                          </div>
                          <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_terulet'].hasError('kissebb')">
                            A főépület alapterülete minimum 10 m2 lehet
                          </div>
                          <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_terulet'].hasError('nagyobb')">
                            A főépület alapterülete maximum 600 m2 lehet
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 mb-3 d-flex flex-column">
                        <label for="GranitFormINput04" class="form-label text-uppercase strong text-primary small">Életvitelszerűen ott lakók száma:</label>
                        <div class="input-group">
                          <input #GranitFormINput04 type="number" class="form-control mb-0" id="GranitFormINput04" placeholder="pl. 2" name="GranitFormINput04" required formControlName="f_lakoszam" (input)="validateInput(GranitFormINput04, 'f_lakoszam')"><span class="input-group-text text-light" id="basic-addon2">fő</span>
                          <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_lakoszam'].hasError('required')">
                            Kötelező mező
                          </div>
                          <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_lakoszam'].hasError('kissebb')">
                            A főépületben életvitelszerűen ott lakók száma nem lehet kevesebb 0 főnél.
                          </div>
                          <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_lakoszam'].hasError('nagyobb')">
                            A főépületben életvitelszerűen ott lakók száma nem haladhatja meg a 7 főt.
                          </div>
                        </div>
                      </div>
                      <div id="A1"></div>
                      <div class="col-lg-6 mb-3">
                        <label for="GranitFormINput05" class="form-label text-uppercase strong text-primary small">Főépület építési éve:</label>
                        <input #GranitFormINput05 type="number" pattern="[1-9][0-9]{3}" class="form-control mb-0" id="GranitFormINput05" name="GranitFormINput05" required formControlName="f_epitesev" [placeholder]="aktEvPelda" (input)="validateInput(GranitFormINput05, 'f_epitesev')">
                        <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_epitesev'].hasError('required')">
                          Kötelező mező
                        </div>
                        <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_epitesev'].hasError('kissebb')">
                          A főépület építési éve nem lehet korábbi mint 1800
                        </div>
                        <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_epitesev'].hasError('nagyobb')">
                          A főépület építési éve nem lehet ksőbbi mint {{aktEv}}
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <label for="GranitFormINput07" class="form-label text-uppercase strong text-primary small">Épület jellege:</label>
                        <div class="input-group mb-3">
                          <select id="GranitFormINput07" name="GranitFormINput07" class="form-select mb-0" required formControlName="f_jelleg">
                            <option value="">Kérjük, válasszon</option>
                            <option value="1">Társasházi lakás</option>
                            <option value="2">Családi ház</option>
                            <option value="3">Ikerház</option>
                            <option value="4">Sorház</option>
                          </select>
                          <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_jelleg'].hasError('required')">
                            Kötelező mező
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 mb-3">
                        <svg class="m-2" width="25" height="25" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_143_4122)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M64.0002 32C64.0002 49.6732 49.6732 64 32.0002 64C14.327 64 0 49.6732 0 32C0 14.3268 14.327 0 32.0002 0C49.6732 0 64.0002 14.3268 64.0002 32Z" fill="#E60000" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.2538 49.8438C23.4614 49.8729 14.3143 42.3689 14.2742 30.3195C14.2477 22.3511 18.5468 14.681 24.0419 10.1305C29.4019 5.69216 36.745 2.84394 43.4046 2.82177C44.2619 2.81892 45.1583 2.89036 45.7073 3.07605C39.8844 4.28371 35.25 9.70275 35.2703 15.8508C35.271 16.0541 35.2895 16.2711 35.3095 16.3723C45.0533 18.7455 49.4765 24.6247 49.5039 32.7599C49.5306 40.8946 43.1083 49.8076 32.2538 49.8438Z" fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_143_4122">
                              <rect width="64" height="64" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <label for="GranitFormINput06" class="form-label text-uppercase strong text-primary small">Vodafone kuponkód:</label>
                        <input #GranitFormINput06 type="text" maxlength="9" class="form-control mb-0 text-uppercase" pattern="[0-9A-F]{4}-[0-9A-F]{4}" id="GranitFormINput06" placeholder="pl. BA6D-063F" name="GranitFormINput06" required formControlName="f_kuponkod" (input)="validateInput(GranitFormINput06, 'f_kuponkod')">
                        <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_kuponkod'].hasError('required')">
                          Kötelező mező
                        </div>
                        <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_kuponkod'].hasError('pattern')">
                          Hibás adat
                        </div>
                        <div class="invalid-feedback w-100" *ngIf="formGroup.controls['f_kuponkod'].hasError('hibasKuponKod')">
                          Hibás kuponkód
                        </div>
                        <label class="form-label text-uppercase strong text-primary small">Kuponkódodat a <a href="https://www.vodafone.hu/lakasbiztositas" target="_blank">My Vodafone alkalmazásban</a> találod.</label>
                        <label class="form-label text-uppercase strong text-primary small">Ha érvényes kuponkód hiányában is szeretnél kalkulálni, akkor a 15% Vodafone kedvezmény nélkül <a [routerLink]="['/kosd-ujra']">ezt itt megteheted</a></label>
                      </div>

                      <div class="col-md-12 text-center pt-3">
                        <button type="submit" class="btn btn-lg rounded-pill btn-outlined-primary" (click)="dijszamitas()">Kalkulálok</button>
                      </div>

                    </div>
                  </div>                  
                  <div class="form-box-footer d-flex flex-column p-5 text-white">
                    <div class="row justify-content-start">
                      <div *ngIf="hibaLista.length == 0">
                        <div class="col-12 pb-3">
                          ÉVES DÍJ 15%-OS VODAFONE KEDVEZMÉNNYEL:<br>
                          <span id="dij" class="h1 bold text-secondary">
                            {{alapdij}} Ft
                          </span>
                        </div>
                        <div class="col-12 pb-4 text-uppercase">
                          Biztosítási összegek:
                          <ul>
                            <li>Ingatlan: {{ajanlott_ingatlan_bo}} Ft</li>
                            <li>Ingóság: {{ajanlott_ingosag_bo}} Ft</li>
                          </ul>
                        </div>
                      </div>
                      <div *ngIf="hibaLista.length > 0">
                        <div class="col-12 pb-3">
                          Hiba:
                          <ul *ngFor="let hiba of hibaLista">
                            <li>{{hiba.hibauzenet}}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-12 text-center pb-5">
                        <button type="submit" class="btn btn-lg rounded-pill btn-secondary" (click)="megkotom()">Megkötöm</button>
                      </div>
                      <div class="col-12 pb-3">
                        A biztosítás teljes körűen kiterjed a lakásbiztosításokban megszokott 20 féle alapkockázatra, sőt a részletes ajánlatnál további kiegészítő kockázatokkal teheti személyesebbé szerződését.
                      </div>
                      <div class="col-12 pb-2 text-minimal">
                        A kalkuláció az alábbi értékek figyelembe vételével készült, amit a részletes adatoknál módosíthat:
                        <ul>
                          <li>
                            ingatlan és ingóságra vonatkozik
                          </li>
                          <li>
                            saját tulajdon
                          </li>
                          <li>
                            saját használatban
                          </li>
                          <li>
                            állandóan lakott
                          </li>
                          <li>
                            belterületi
                          </li>
                          <li>
                            nem áll építés alatt
                          </li>
                          <li>
                            tégla falazat
                          </li>
                          <li>
                            cseréptető
                          </li>
                          <li>
                            az ingatlanra volt előzmény biztosítás
                          </li>
                          <li>
                            átutalás
                          </li>
                          <li>
                            éves díjas fizetés
                          </li>
                          <li>
                            e-kommunikáció elfogadás
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 pb-3 text-minimal">
                        Az értékek módosítása a díj változását is magával vonja.
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>


            <div class="col-md-12 h1 bold py-5 text-start mt-n5">
              Miért válassz<br>
              <span class="text-secondary">Minket?</span>
            </div>
            <div class="col-md-3 text-md-center d-flex flex-md-column align-items-md-center p-4 shadow-sm rounded-start-4">
              <div class="mb-2" style="width: 75px">
                <img src="../../../../assets/images/icon-kosd1.svg" class="img-fluid" alt="Gránit Biztosító">
              </div>
              <div class="pt-2 ps-3 ps-md-0">
                <span class="bold text-uppercase h5">Napi több ezer</span><br>
                <span>ajánlatkérés</span>
              </div>
            </div>
            <div class="col-md-3 text-md-center d-flex flex-md-column align-items-md-center p-4 shadow-sm border-dark">
              <div class="mb-2" style="width: 75px">
                <img src="../../../../assets/images/icon-kosd2.svg" class="img-fluid" alt="Gránit Biztosító">
              </div>
              <div class="pt-2 ps-3 ps-md-0">
                <span class="bold text-uppercase h5">350.000</span><br>
                <span>aktív ügyfél</span>
              </div>
            </div>
            <div class="col-md-3 text-md-center d-flex flex-md-column align-items-md-center p-4 shadow-sm border-dark">
              <div class="mb-2" style="width: 75px">
                <img src="../../../../assets/images/icon-kosd3.svg" class="img-fluid" alt="Gránit Biztosító">
              </div>
              <div class="pt-2 ps-3 ps-md-0">
                <span class="bold text-uppercase h5">4,6 / 5 ÉRTÉKELÉS</span><br>
                <span>több ezer ügyfélértékelés alapján</span>
              </div>
            </div>
            <div class="col-md-3 text-md-center d-flex flex-md-column align-items-md-center p-4 shadow-sm rounded-end-4">
              <div class="mb-2" style="width: 75px">
                <img src="../../../../assets/images/icon-kosd4.svg" class="img-fluid" alt="Gránit Biztosító">
              </div>
              <div class="pt-2 ps-3 ps-md-0">
                <span class="bold text-uppercase h5">15 ÉVE</span><br>
                <span>az Önök szolgálatában</span>
              </div>
            </div>
            <div class="col-md-12 h2 bold py-5 text-start">
              Gyakran ismételt<br>kérdések
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</div>
<section class="bg-info pb-5">
  <div class="container pb-5">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="accordion shadow-lg rounded-4" id="accordionGranit">

          <div class="accordion-item bg-light">
            <h2 class="accordion-header ">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse0" aria-expanded="false" aria-controls="collapse0">
                Hogyan tudom megszerezni Vodafone kuponomat a kedvezményes otthonbiztosításhoz?
              </button>
            </h2>
            <div id="collapse0" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  Töltsd le a My Vodafone alkalmazást, és keresd egyedi kuponodat, amivel 15% kedvezménnyel kötheted meg otthonbiztosításodat. Ráadásul részt vehetsz a Vodafone által szervezett nyereményjátékban is. További információkért <a href="https://www.vodafone.hu/lakasbiztositas" target="_blank">kattints ide</a>.
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item bg-light">
            <h2 class="accordion-header ">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                Hol tudom beszerezni a biztosításra vonatkozó legfontosabb szabályozásokat, ügyféltájékoztatókat?
              </button>
            </h2>
            <div id="collapse1" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  <ul>
                    <li>
                      Kötési folyamat során lehetőség van ezek megismerésére, letöltésére
                    </li>
                    <li>
                      A folyamat végén, ajánlat elfogadásakor minden ügyfelünknek emailben is kiküldjük ezeket
                    </li>
                    <li>
                      Weboldalunkon, a <a [routerLink]="['/hazmester']">www.granitbiztosito.hu/hazmester</a> oldalon az összes kapcsolódó dokumentum elérhető, letölthető
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">Meddig kell befizetni a lakásbiztosításom díját?</button>
            </h2>
            <div id="collapse2" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  <ul>
                    <li>
                      A szerződés első díja már a kötés után, a véglegesítéskor küldött válasz emailben szereplő link segítségével, bankkártyával befizethető
                    </li>
                    <li>
                      A biztosítás kezdete utáni 30.napig lehetőség van a díj megfizetésére átutalással, bankkártyával vagy csekkes fizetési mód választása esetén a Biztosító által levélben kiküldött készpénzátutalási megbízáson is. A kötés után a díjbekérő/számla is megküldésre kerül ügyfeleink részére.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">Mit kell tennem, amennyiben káresemény történt az ingatlanomra/ingóságaimra vonatkozóan?</button>
            </h2>
            <div id="collapse3" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  <ul>
                    <li>
                      Legfontosabb mindig a kárelhárítás, a kárenyhítés és a további károk megelőzése, hogy nagyobb baj már ne történjen! (ez a Feltételekben szereplő kötelezettsége is a Szerződőnek!)
                    </li>
                    <li>
                      Következő a kárbejelentés, amit a lehető leghamarabb érdemes megtenni. Legegyszerűbb ezt online, a <a href="{{onlineKarbejelentoLink}}">www.granitbiztosito.hu/karbejelento</a> , ahol a teljes folyamat alatt segítünk, ha elakadna.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">A biztosított ingatlanom hitelfedezetül szolgál. Mi a teendőm?</button>
            </h2>
            <div id="collapse4" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  <ul>
                    <li>
                      Szerződéskötés során jelezze, hogy az ingatlan jelzálog/hitel fedezetéül szolgál.
                    </li>
                    <li>
                      Adja meg a kitöltéskor szükséges adatokat. (szerz.szám; kezdet; lejárat; Pénzintézet adatai) Az adatok kitöltésével biztosítja, hogy a szerződés megküldésével együtt megküldjük a hitelfedezet igazolását szolgáló dokumentumot, amit a Bankja/ Pénzintézet felé tud továbbítani.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">Milyen kockázatokra terjed ki a biztosítás?</button>
            </h2>
            <div id="collapse5" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  20 alapfedezet és további kiegészítő fedezetek teszik rugalmassá és személyre szabhatóvá termékünket. Részletesen a Biztosítási Termékismeretőnkben találhatja meg ezeket.
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">Ki lehet a szerződő/biztosított/zálogjogosult?</button>
            </h2>
            <div id="collapse6" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  Szerződő lehet cég vagy magánszemély, aki a biztosított vagyontárgy megóvásában érdekelt és vállalja a biztosítási díj fizetését.
                  Biztosított csak magánszemély lehet, aki valamely vagyoni vagy személyhez fűződő jogviszony alapján a biztosítási esemény elkerülésében érdekelt
                  Zálogjogosult: Ha a felek harmadik személy részére teljesítendő szolgáltatásra kötöttek szerződést, a harmadik személy akkor követelheti közvetlenül a szolgáltatás teljesítését, ha ezt a jogát a felek kifejezetten kikötötték.
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">Milyen összegre biztosítsam az épületet?</button>
            </h2>
            <div id="collapse7" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  Fontos kérdés, mert a biztosítási összeg helytelen meghatározása alulbiztosításhoz és kár esetén aránylagos kártérítéshez vezethet. A épület biztosítási összeg megállapításánál az újjáépítési érték és nem a piaci, forgalmi értéket kell figyelemebe venni. Újjáépítés esetén a Biztosító a károsodottal azonos nagyságú, kivitelezésű és minőségű épület, építmény újjáépítési költségeit téríti meg a biztosítási összeg keretein belül.
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">Milyen összegre biztosítsam az ingóságaimat?</button>
            </h2>
            <div id="collapse8" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  Új értéken történő biztosítási összeg megadásával a Biztosító legfeljebb a biztosítási összeg mértékéig megtéríti az ingóságokban a káridőponti hazai javítással történő helyreállítás költségeit vagy új állapotban történő beszerzési értékét. Ezek egyike sem haladhatja meg a vagyontárgynak a káridőponti új értékét.
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">Mennyit térít meg a biztosító káreseménykor?</button>
            </h2>
            <div id="collapse9" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  A Biztosító mindig a tényleges kárt téríti az újjáépítési vagy újra beszerzési érték, illetve a javíttatás költségének figyelembevételével, maximum a biztosítási összegig.
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">Mit csináljak, ha változnak a szerződésemben megadott értékek (biztosítási összegek)?</button>
            </h2>
            <div id="collapse10" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  Gyarapodhat a vagyon, vagyis értékemelkedést jelenthet például a tető¬tér beépítése, korszerűsítés, felújítás stb. De változhat a biztosítási összeg,  ha pl. új, korszerűbb műszaki cikkek kerülnek a háztartásba.
                  Érdemes, hogy rendszeresen, de legalább a fenti változásokkor összehasonlítani a szerződésben szereplő biztosítási összegeket a meglévő vagyontárgyai értékével, és ha szükséges, akkor kezdeményezze a szerződés módosítását.
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">Nem állandóan lakott ingatlanra köthető-e biztosítás?</button>
            </h2>
            <div id="collapse11" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  Nem állandóan lakott épület azok az ingatlanok, amelyeket a biztosítottak szezonális jelleggel, illetve valamilyen rendszerességgel rövidebb-hosszabb időtartamig ideiglenesen használnak. Nem állandóan lakott épületnek minősül az az ingatlan is, amelyet 30 napon túl folyamatosan nem laknak. Köthető rá biztosítás pótdíj alkalmazásával.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
  </div>
</section>
<section class="bg-light py-5">
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <h2 class="text-primary strong mb-5">Ügyfeleink mondták</h2>
      </div>
      <div class="col-lg-12">
        <div class="swiper TestimonialSlider">
          <div class="swiper-wrapper">

            
              <div class="swiper-slide d-flex flex-column p-3 p-lg-5 border border-light rounded-3 justify-content-between" *ngFor="let adat of ugyfelElegedettsegLista">
                <div class="d-flex">
                  <div>
                    <img src="../../../../assets/images/quote.svg">
                  </div>
                  <div class="ps-3">
                    {{adat}}
                  </div>
                </div>                
              </div>
            
            <!--
            <div class="swiper-slide d-flex flex-column p-3 p-lg-5 border border-light rounded-3 justify-content-between">
              <div class="d-flex">
                <div>
                  <img src="../../../../assets/images/quote.svg">
                </div>
                <div class="ps-3">
                  Nagyon köszönöm a gyors ügyintézést. Másnap már az összeg is számlámon volt. Maximálisan elégedett vagyok a kárszakértő kollega hozzáállásával is.
                </div>
              </div>
              <div class="pt-4 d-flex flex-column">
                <div class="bold text-primary">Gabriella (78)</div>
                <div>Budapest</div>
              </div>
            </div>
            <div class="swiper-slide d-flex flex-column p-3 p-lg-5 border border-light rounded-3 justify-content-between">
              <div class="d-flex">
                <div>
                  <img src="../../../../assets/images/quote.svg">
                </div>
                <div class="ps-3">
                  Mindent precízen intéztek. Maximálisan tájékoztatják az ügyfeleket.
                </div>
              </div>
              <div class="pt-4 d-flex flex-column">
                <div class="bold text-primary">Jánosné (63)</div>
                <div>Pápa-Borsosgyőr</div>
              </div>
            </div>
            <div class="swiper-slide d-flex flex-column p-3 p-lg-5 border border-light rounded-3 justify-content-between">
              <div class="d-flex">
                <div>
                  <img src="../../../../assets/images/quote.svg">
                </div>
                <div class="ps-3">
                  A kárszakértő gyorsan felvette velem a kapcsolatot. Rugalmas és türelmes volt. Jól navigált a kamerás hibafelmerésben.
                </div>
              </div>
              <div class="pt-4 d-flex flex-column">
                <div class="bold text-primary">Krisztián (30)</div>
                <div>Miskolc</div>
              </div>
            </div>
            <div class="swiper-slide d-flex flex-column p-3 p-lg-5 border border-light rounded-3 justify-content-between">
              <div class="d-flex">
                <div>
                  <img src="../../../../assets/images/quote.svg">
                </div>
                <div class="ps-3">
                  Nagyon köszönöm a gyors ügyintézést. Másnap már az összeg is számlámon volt. Maximálisan elégedett vagyok a kárszakértő kollega hozzáállásával is.
                </div>
              </div>
              <div class="pt-4 d-flex flex-column">
                <div class="bold text-primary">Gabriella (78)</div>
                <div>Budapest</div>
              </div>
            </div>
            -->
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  </div>
</section>
