import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HibaService, UtilityService } from '@granit/common-library';
import { UgyfeleinknekService } from '../../../services/ugyfeleinknek.service';

@Component({
  selector: 'elegedettsegmeres',
  templateUrl: './elegedettsegmeres.component.html',
  styleUrls: ['./elegedettsegmeres.component.scss']
})
export class ElegedettsegmeresComponent implements OnInit {

  formGroup: FormGroup;
  azon: number;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private utilityService: UtilityService,
    public hibaService: HibaService,
    private ugyfeleinknekService: UgyfeleinknekService) { }

  ngOnInit(): void {
    this.formGroupLetrehozas();
    this.formBetoltes();
  }

  formGroupLetrehozas() {
    this.formGroup = this.formBuilder.group({
      ertekeles: new FormControl(null, [Validators.required]),
      szoveges_ertekeles: new FormControl(null, [Validators.maxLength(200)]),
      mail_azon: new FormControl(null)
    });
  }

  formBetoltes() {
    this.route.queryParams.subscribe(params => {
      if (params['azon'] && params['mac']) {
        let azon = ((params['azon']) as number);
        this.azon = azon;
        let mac = ((params['mac']) as string);
        // Azonosító és mac ellenőrzése
        this.ugyfeleinknekService.elegedettsegmeresFormBetoltes({ azon: azon, mac: mac }).subscribe(
          data => {
            console.log(data);
            // Ha hibás a mac
            if (data.hibas_url) {
              this.dialogMegnyitas('Bezárás', 'Hiba!', 'Hibás url.', true, false, false);
            }
            else if (!data.hataridon_belul) {
              this.dialogMegnyitas('Bezárás', 'Hiba!', 'Köszönjük, hogy a levelünkben küldött linkre kattintott.<br />Sajnos az értékelésre rendelkezésre álló idő lejárt.', false, true, false);
            }
            // Ha már értékelt
            else if (data.mar_ertekelt) {
              this.dialogMegnyitas('Bezárás', 'Hiba!', 'Köszönjük, Ön korábban már elküldte az értékelését.', false, true, false);
            }
            else {
              this.formGroup.controls['mail_azon'].setValue(data.mail_azon);
            }
          }
        );
      }
      else {
        this.dialogMegnyitas('Bezárás', 'Hiba!', 'Hibás, hiányos cím.', true, false, false);
      }
    });
  }


  onErtekValasztasClick(ertek: number) {
    this.formGroup.controls['ertekeles'].setValue(ertek);
  }

  insert() {
    /* A form group-ot letiltjuk, hogy megakadályozzuk az esetleges dupla elküldést */
    this.formGroup.disable();

    let model = {
      mail_azon: this.formGroup.controls['mail_azon'].value,
      azon: this.azon,
      ertekeles: this.formGroup.controls['ertekeles'].value,
      szoveges_ertekeles: this.formGroup.controls['szoveges_ertekeles'].value
    };
    this.ugyfeleinknekService.elegedettsegmeresFormMentes(model).subscribe(result => {

      console.log(result);

      this.dialogMegnyitas('Bezárás', 'Sikeres értékelés!', 'Köszönjük, hogy a levelünkben küldött linkre kattintott és leadta értékelését!', false, false, true);
    });

  }

  resetForm() {
    this.formGroup.reset();
  }

  dialogMegnyitas(confirmButtonText: string, title: string, message: string, error: boolean, info: boolean, success: boolean) {
    const dialogRef = this.utilityService.dialogMegnyitasa({
      error: error,
      info: info,
      success: success,
      confirmButtonText: confirmButtonText,
      title: title,
      message: message
    },
      '500px');
    dialogRef.afterClosed().subscribe(
      () => {
        this.router.navigate(['/home']);
      }
    );
  }
}
