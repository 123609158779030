import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UtilityService } from '@granit/common-library';

@Component({
  selector: 'kaf-kszf',
  templateUrl: './kaf-kszf.component.html',
  styleUrls: ['./kaf-kszf.component.scss']
})
export class KafKszfComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,
    private utilityService: UtilityService) { }

  weboldalDokumentumok: string = "";

  ngOnInit(): void {
    this.linkek();
  }

  async linkek() {

    Promise.all(
      [this.utilityService.appsettingsKeyErtekLekerese({ key: 'WeboldalDokumentumok' })]).then(
        data => {
          this.weboldalDokumentumok = data[0];
        }
      );
  }

  scrollToElement(elementId: string): void {
    const element = this.document.getElementById(elementId);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const top = absoluteElementTop - 100; // Offset by -100px
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      });
    }
  }

}
