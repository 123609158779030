<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">KÖZZÉTÉTELEK</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <ul>
                <li><a [routerLink]="['/bit108-a1']">BIT. 108/A. § (1) BEKEZDÉSE SZERINTI KÖZZÉTÉTELEK</a></li>
                <li></li>
                <li><a [routerLink]="['/fizetokepesseg']">FIZETŐKÉPESSÉGRŐL ÉS PÉNZÜGYI HELYZETRŐL SZÓLÓ JELENTÉSEK</a></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
