<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">KÁRRENDEZŐ PARTNEREK</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <ul>
                <li>
                  <span>1.</span>
                  <span>Autotal Biztosítási Szolgáltató Kft. 1107 Budapest, Hizlaló tér 1.</span>
                </li>
                <li>
                  <span>2.</span>
                  <span>Claim Controll Kárszakértő és Pénzügyi Szolgáltató Kft. 1133 Budapest, Árboc utca 6.</span>
                </li>
                <li>
                  <span>3.</span>
                  <span>Dekra-Expert Műszaki Szakértői Kft. 1134 Budapest, Róbert Károly krt. 70-74.</span>
                </li>
                <li>
                  <span>4.</span>
                  <span>Van Ameyde Hungary Kft. 1118 Budapest, Dayka G. u. 3.</span>
                </li>
                <li>
                  <span>5.</span>
                  <span>Ex-ID Zrt. 1133 Budapest, Árboc utca 6. 1. em.</span>
                </li>
                <li>
                  <span>6.</span>
                  <span>Cop-Group Security Kft. 1146 Budapest, Erzsébet királyné útja 1.</span>
                </li>
                <li>
                  <span>7.</span>
                  <span>Europ Assistance Magyarország Befektetési és Tanácsadó Kft. 1134 Budapest Dévai utca 26-28.</span>
                </li>
                <li>
                  <span>8.</span>
                  <span>Onkomplex Rákdiagnosztika és Asszisztencia Kft. 1164 Budapest Vízesés u. 9.</span>
                </li>
                <li>
                  <span>9.</span>
                  <span>IOS Hungary Kft. 1048 Budapest, Sárpatak u. 8.</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

