<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">HÍREK, AKTUALÍTÁSOK</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              
              <div class="hirDiv">
                <p><b>Tájékoztatás!</b></p>
                <p>
                  Február 15-től új névvel és arculattal, Gránit Biztosítóként folytatja a működését a Wáberer Hungária Biztosító, amely a Gránit Bankkal kötött stratégiai együttműködési megállapodásának köszönhetően a pénzintézet szakmai hátterére is építhet a jövőben, illetve a bank széles ügyfélköre számára válnak elérhetővé biztosítási termékei. A Gránit Biztosító megújult arculatának bevezetése, illetve az új honlapjának indítása február második felében valósul meg. A biztosítóval korábban megkötött szerződések változatlan feltételekkel és tartalommal élnek tovább, ugyanolyan professzionális ügyfélkiszolgálás és változatlan, erős szakmai háttér mellett.
                </p>
                <p>Üdvözlettel,</p>
                <p>
                  Gránit Biztosító Zrt.
                </p>
              </div>

              <div class="hirDiv">
                <p><b>Tisztelt Ügyfeleink!</b></p>
                <p>
                  <a href="https://www.holkossekbiztositast.hu/index.html" target="_blank">Holkössekbiztosítást</a> néven új, non-profit blog kezdte meg működését. Az online elérhető utas-, a CASCO és a kötelező- felelősség biztosítások mellett a lakásbiztosítások tekintetében ad hasznos információkat az oldal. Az oldalon a Gránit Biztosító Zrt. minden terméke megtalálható.<br />
                  Az egyes termékek vonatkozásában - a HKB blog termékkeresőjében - az alaptermékek és a kiegészítő termékek vonatkozásában is egyaránt lehet keresni. Az oldal külön érdekessége, hogy a biztosítási alkuszi oldalakat is listázza és megmutatja, hogy ha például lakásbiztosítást szeretnénk kötni, vagy csak díjat kalkulálni, akkor melyik alkusz árulja az adott terméket. Az oldal a biztosítók és így természetesen a Gránit Biztosító Zrt., szerződésekhez kapcsolódóan elérhető online szolgáltatásait is részletesen felsorolja. Mindez a 2024-től kezdődő márciusi lakáskampányokban hasznos segítője lehet az ügyfeleknek.<br />
                  <a href="https://www.holkossekbiztositast.hu/lakasbiztositas-es-kiegeszito-biztositasai/index.html" target="_blank">HKB Lakásbiztosítások <span style="font-family: Arial">>></span></a>
                </p>
                <p>Üdvözlettel,</p>
                <p>
                  Gránit Biztosító Zrt.
                </p>
              </div>

              <div class="hirDiv">
                <p><b>Tisztelt Ügyfeleink!</b></p>
                <p>
                  Önök előtt is ismert, hogy az európai szanálási hatóság végelszámolást indított a magyar Sberbank anyavállalatánál, az osztrák Sberbank Europe AG-nál, mely kedvezőtlenül érinti a Sberbank Magyarország Zrt-t is.
                  A Sberbank Magyarország Zrt.től, a bank likviditási és tőkehelyzete miatt az MNB visszavonta a hazai hitelintézet tevékenységi engedélyét és elrendelte annak végelszámolását.<br />
                  Az előbbiek miatt Társaságunknak nem áll módjában Sberbank Magyarország Zrt-nél vezetett bankszámlaszámára utalást indítani, ezért amennyiben Társaságunktól bármilyen okból forint utalást várt, legyen szíves megadni <a [routerLink]="['/kapcsolat']">elérhetőségeink valamelyikén</a> az új bankszámlaszámát, melyre kötelezettségünket teljesíteni tudjuk.
                </p>
                <p>Üdvözlettel,</p>
                <p>
                  Gránit Biztosító Zrt.
                </p>
              </div>

              <div class="hirDiv">
                <p><b>Tisztelt Ügyfeleink!</b></p>
                <p>
                  Felhívjuk a figyelmet arra, hogy a Külügyminisztérium Konzuli Szolgálata 2022. február 24-étől Ukrajna teljes területét a kialakult háborús helyzet miatt az utazásra nem javasolt biztonsági kategóriába sorolta.<br />
                  A szerződési feltételeink alább idézett rendelkezése értelmében a Gránit Biztosító Zrt. utasbiztosításainak területi hatálya 2022. február 24-i vagy későbbi beutazás esetén nem terjed ki Ukrajna területére.<br />
                  „A kockázatviselés nem terjed ki olyan térségekre, amelyek a kockázatviselés első napján, vagy a biztosított által az adott területre való beutazás napján a Külgazdasági és Külügyminisztérium által meghatározott utazásra nem javasolt utazási térségek között szerepelnek.”
                </p>
                <p>Üdvözlettel,</p>
                <p>
                  Gránit Biztosító Zrt.
                </p>
              </div>

            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
