import { Component, OnInit } from '@angular/core';
import { UtilityService } from '@granit/common-library';

@Component({
  selector: 'karrendezes',
  templateUrl: './karrendezes.component.html',
  styleUrls: ['./karrendezes.component.scss']
})
export class KarrendezesComponent implements OnInit {

  onlineKarbejelentoLink: string = "";
  weboldalDokumentumok: string = "";

  constructor(private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.linkek();
  }

  async linkek() {
    let model = {
      key: "OnlineKarbejelentoLink"
    }
    this.onlineKarbejelentoLink = await this.utilityService.appsettingsKeyErtekLekerese(model);

    model = {
      key: "WeboldalDokumentumok"
    }
    this.weboldalDokumentumok = await this.utilityService.appsettingsKeyErtekLekerese(model);
  }

  scrollToelement(element: string) {
    document.getElementById(element).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

}
